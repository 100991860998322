import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { DebriefElement, DebriefElementAdapter } from '@models/debrief-element';
import { map } from 'rxjs/operators';
import { DebriefComment } from '@models/debrief-comment';

@Injectable({
  providedIn: 'root'
})
export class DebriefService {

  constructor(
    private http: HttpClient,
    private debriefElementAdapter: DebriefElementAdapter
  ) { }

  getDebriefElements(idJeu: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/debrief/get-debrief-elements/${idJeu}/${tour}`;

    return this.http.get<DebriefElement[]>(url).pipe(
      map((data: any) => data.map(x => this.debriefElementAdapter.adapt(x)))
    );
  }

  triggerChecked(debriefElement: DebriefElement){
    const url = `${environment.apiUrl}/custom/api/debrief/trigger-checked`;

    let data = {
      'typeDebrief': debriefElement.typeDebrief,
      'idContent': debriefElement.content.id
    };

    return this.http.post(url, data);
  }

  addDebriefComment(debriefComment: DebriefComment){
    const url = `${environment.apiUrl}/custom/api/debrief/add-debrief-comment`;

    let data = {
      'jeu': debriefComment.jeu.id,
      'tour': debriefComment.tour,
      'texte': debriefComment.texte
    };

    return this.http.post(url, data);
  }
}
