import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Jeu } from './jeu';

export interface ActionJeu{
  id?: number,
  type?: string,
  nom?: string,
  slug?: string,
  duree?: number,
  ordre?: number,
  started?: boolean,
  finished?: boolean,
  dateLancement?: string,
  timePassed?: number,
  tour?: number,
  jeu?: Jeu,
  nextAction?: ActionJeu,
  parentAction?: ActionJeu
}

@Injectable({
  providedIn: 'root'
})
export class ActionJeuAdapter implements Adapter<ActionJeu>{
  adapt(data: any): ActionJeu{
    return {
      'id': data['id'],
      'nom': data['nom'],
      'type': data['type'],
      'slug': data['slug'],
      'duree': data['duree'],
      'ordre': data['ordre'],
      'started': data['started'],
      'finished': data['finished'],
      'dateLancement': data['dateLancement'],
      'timePassed': data['timePassed'],
      'nextAction': data['nextAction'] as ActionJeu,
      'parentAction': data['parentAction'] ? data['parentAction'] as ActionJeu : null
    } as ActionJeu;
  }
}
