import { Component, OnInit } from '@angular/core';
import { CustomDatasource } from '@datasources/custom-datasource';
import { Jeu, JeuAdapter } from '@models/jeu';
import { JeuService } from '@services/jeu.service';

@Component({
  selector: 'app-page-jeu-exemple',
  templateUrl: './page-jeu-exemple.component.html',
  styleUrls: ['./page-jeu-exemple.component.scss']
})
export class PageJeuExempleComponent implements OnInit {

  dataSource: CustomDatasource<Jeu>;
  allExempleJeu: Jeu[] = [];
  displayedColumns = ["nom_jeu", "action"];
  defaultPageSize = 5;
  isAdmin = true;

  constructor(private jeuService: JeuService, private adapter: JeuAdapter) { }

  ngOnInit(): void {
    this.dataSource = new CustomDatasource(this.jeuService, this.adapter);
    this.dataSource.load({"isExemple": true});
    // this.getAllJExempleJeu();
  }

  getAllJExempleJeu(){
    this.jeuService.getOnlyTemplates().subscribe((allJeus) => {

    })
  }

}
