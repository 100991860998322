import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Jeu } from '@models/jeu';
import { JeuService } from '@services/jeu.service';

@Component({
  selector: 'app-equipe-list',
  templateUrl: './equipe-list.component.html',
  styleUrls: ['./equipe-list.component.scss']
})
export class EquipeListComponent implements OnInit {
  idJeu: number;
  jeu: Jeu;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jeuService: JeuService
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.jeuService.getOne(this.idJeu).subscribe(
      (jeu) => {
        this.jeu = jeu;
      }
    );
  }

  showEquipe(id: number){
    this.router.navigate(['/equipe/' + id + '/show']);
  }
}
