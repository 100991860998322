<div>
  <div></div>
  <form (ngSubmit)="onSendMessage()">
    <ul>
      <li *ngFor="let message of messages$ | async ">{{ message }}</li>
    </ul>
    <input placeholder="Message..." class="form-control" [formControl]="messageText"/>
    <button type="submit" mat-raised-button color="primary">{{ 'envoyer' | translate }} <mat-icon>send</mat-icon></button>
  </form>
</div>

