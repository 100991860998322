import { Component, OnInit } from '@angular/core';
import { Jeu } from '@models/jeu';
import { ActivatedRoute, Router } from '@angular/router';
import { jeuSelectAction } from '@app/core/actions/jeu.actions';
import { Store, select } from '@ngrx/store';
import { JeuService } from '@services/jeu.service';
import { User } from '@models/user';
import { UserService } from '@services/user.service';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-jeu-animation',
  templateUrl: './jeu-animation.component.html',
  styleUrls: ['./jeu-animation.component.scss']
})
export class JeuAnimationComponent implements OnInit {
  jeu: Jeu;
  idJeu: number;
  tempsDepart;
  loadingAnimerJeu = false;
  animateurs: User[] = [];

  joueursComplets: boolean = false;
  animateurCanLaunch = false;
  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private jeuService: JeuService,
    private userService: UserService,
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.idJeu = this.route.snapshot.params.idJeu;
    this.store.dispatch(jeuSelectAction({ id: this.idJeu }));
    this.jeuService.getOne(this.idJeu).subscribe(
      (jeu) => {
        this.jeu = jeu;
        this.tempsDepart = jeu.debutReel;

        this.getAnimateurs();
        this.checkJoueursComplets();
      }
    )
  }

  getAnimateurs(){
    this.userService.getAnimateursJeu(this.jeu.id).subscribe(animateurs => {
      console.log('animateurs', animateurs);
      this.animateurs = animateurs;

      this.checkAnimateurCanLaunch();
    });
  }

  checkAnimateurCanLaunch(){
    this.animateurs.forEach(animateur => {
      console.log('anim id', animateur.id, 'current user id', this.authService.currentUserInfo.id);
      if(animateur.id == this.authService.currentUserInfo.id){
        this.animateurCanLaunch = true;
      }
    });
  }

  checkJoueursComplets(){
    this.jeuService.checkJoueursComplets(this.jeu.id).subscribe(data => {
      this.joueursComplets = data['joueursComplets'];
    });
  }

  edit(){
    this.router.navigate(['/jeux/edit/'+this.idJeu]);
  }

  startGame(){
    this.jeuService.startGame(this.idJeu).subscribe(
      (jeu) => {
        this.jeu = jeu;
        this.tempsDepart = jeu.debutReel;
      }
    )
  }

  nextRound(){
    var tours : number = this.jeu.tour + 1;
    if(tours <= this.jeu.nbTours)
    {
      this.jeuService.update({tour: tours } as Jeu, this.idJeu).subscribe(
        (jeu) => {
          this.jeu = jeu;
        }
      )
    }
  }

  animerJeu(){
    this.loadingAnimerJeu = true;
    this.jeuService.animerJeu(this.jeu.id).subscribe(() => {
      this.loadingAnimerJeu = false;
      this.router.navigate(['/layout/joueur/animateur']);
    });
  }
}
