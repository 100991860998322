import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JeuListComponent } from './jeu-list/jeu-list.component';
import { JeuEditComponent } from './jeu-edit/jeu-edit.component';
import { JeuCreateComponent } from './jeu-create/jeu-create.component';
import { GameDesignerComponent } from './designer/game-designer/game-designer.component';
import { PageCreationJeuComponent } from './designer/page-creation-jeu/page-creation-jeu.component';
import { PageEditionEquipeComponent } from './designer/page-edition-equipe/page-edition-equipe.component';
import { PageEditionJoueursComponent } from './designer/page-edition-joueurs/page-edition-joueurs.component';
import { PageEditionProduitsInnovationComponent } from './designer/page-edition-produits-innovation/page-edition-produits-innovation.component';
import { PageEditionProduitsCyberComponent } from './designer/page-edition-produits-cyber/page-edition-produits-cyber.component';
import { PageEditionAnnoncesComponent } from './designer/page-edition-annonces/page-edition-annonces.component';
import { PageEditionBreakingNewsComponent } from './designer/page-edition-breaking-news/page-edition-breaking-news.component';
import { EditionAnimationComponent } from './edition-animation/edition-animation.component';

const routes: Routes = [
  { path: 'list', component: JeuListComponent },
  { path: 'edit/:id', component: JeuEditComponent},
  { path: 'create', component: JeuCreateComponent},
  { path: 'edit-animation/:idJeu', component: EditionAnimationComponent},
  {
    path: 'designer',
    component: GameDesignerComponent,
    children: [
      {
        path: 'creation',
        redirectTo: 'creation/',
        pathMatch: 'full'
      },
      {
        path: 'creation/:idJeu',
        component: PageCreationJeuComponent
      },
      {
        path: 'edit-equipe/:idJeu',
        component: PageEditionEquipeComponent
      },
      {
        path: 'edit-joueurs/:idJeu',
        component: PageEditionJoueursComponent
      },
      {
        path: 'edit-produits-innovation/:idJeu',
        component: PageEditionProduitsInnovationComponent
      },
      {
        path: 'edit-produits-cyber/:idJeu',
        component: PageEditionProduitsCyberComponent
      },
      {
        path: 'edit-annonces/:idJeu',
        component: PageEditionAnnoncesComponent
      },
      {
        path: 'edit-breaking-news/:idJeu',
        component: PageEditionBreakingNewsComponent
      },
      {
        path: '',
        redirectTo: 'creation',
        pathMatch: 'full'
      }
    ]
  },
  { path: '', redirectTo: 'list', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JeuRoutingModule { }
