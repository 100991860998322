import { createReducer, on } from "@ngrx/store";
import { initialState, UserState } from "../states/user.state";
import { Action } from "@ngrx/store";

const userReducer = createReducer(
  initialState
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
