import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Produit } from '@models/produit';
import { Jeu } from '@models/jeu';
import { ProduitService } from '@services/produit.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-produit-edit-item',
  templateUrl: './list-produit-edit-item.component.html',
  styleUrls: ['./list-produit-edit-item.component.scss']
})
export class ListProduitEditItemComponent implements OnInit {
  @Input() produit: Produit;
  @Input() jeu: Jeu;
  @Input() tour: number;

  @Output() deletedProduit = new EventEmitter<Produit>();

  loadingDeleteProduit: boolean = false;

  constructor(
    private produitService: ProduitService,
    private router: Router
  ) { }

  ngOnInit(): void {

  }

  deleteProduitFromSalon(){
    this.loadingDeleteProduit = true;
    this.produitService.deleteProduitFromSalon(this.jeu.id, this.tour, this.produit.id).subscribe(() => {
      this.loadingDeleteProduit = false;
      this.deletedProduit.emit(this.produit);
    });
  }

  editProduit(){
    this.router.navigate(['/produits/edit/' + this.produit.id], { queryParams: { returnUrl: this.router.url}});
  }
}
