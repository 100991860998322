<app-action-jeu *ngFor="let actionJeu of allActionJeux" [actionJeu]="actionJeu"></app-action-jeu>

<div class="text-center">
    <button mat-mini-fab color="primary" (click)="triggerModal(createAction)">
        <mat-icon>add</mat-icon>
    </button>
</div>

<ng-template #createAction let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Creation d'une action jeu</h4>
        </div>
        <div class="modal-body">
            <form #actionJeuForm="ngForm">
                <div class="form-group">
                    <label>nom:</label>
                    <input type="text" class="form-control" [(ngModel)]="currentActionJeu.nom" name="nom">
                </div>
                <div class="form-group">
                    <label>slug:</label>
                    <input type="text" class="form-control" [(ngModel)]="currentActionJeu.slug" name="slug">
                </div>
                <div class="form-group">
                    <label>type:</label>
                    <input type="text" class="form-control" [(ngModel)]="currentActionJeu.type" name="type">
                </div>
                <div class="form-group">
                    <label>duree:</label>
                    <input type="number" class="form-control" [(ngModel)]="currentActionJeu.duree" name="duree">
                </div>
                <div class="form-group">
                    <label>tour de lancement</label>
                    <mat-select [(ngModel)]="currentActionJeu.tourLancement" class="form-control" name="tourLancement">
                        <mat-option *ngFor="let item of [].constructor(jeu.nbTours+1), let i=index" [value]="i">{{i}}</mat-option>
                    </mat-select>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">{{ 'annuler' | translate }}</button>
            <button type="button" mat-raised-button color="primary" (click)="addActionJeu()" [disabled]="false">Ajouter</button>
        </div>
    </div>

</ng-template>
