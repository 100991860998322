import { Adapter } from '@models/adapter';
import { MediaObject, MediaObjectAdapter } from './media-object';

import { Injectable } from '@angular/core';
import { Joueur } from '@models/joueur';

export interface User{
  id: number,
  iri: string,
  username: string,
  roles?: string[],
  token?: string,
  firstname?: string,
  lastname?: string,
  phone?: string,
  organisation?: string,
  description?: Text,
  email?: string,
  photo?: MediaObject,
  refresh_token?: string,
  jeuActuel?: any
}

@Injectable({
  providedIn: 'root'
})
export class UserAdapter implements Adapter<User>{
  constructor(private mediaObjectAdptor: MediaObjectAdapter){}
  adapt(data: any): User{
    return {
      'id': data.id,
      'iri': data["@id"],
      'username': data.username,
      'firstname': data.firstname,
      'lastname': data.lastname,
      'phone': data.phone,
      'organisation': data.organisation,
      'description': data.description,
      'email': data.email,
      'photo': (data.photo)?this.mediaObjectAdptor.adapt(data.photo):null,
      'roles': data.roles,
      'active': data.active,
      'joueur' : data.joueurs,
      'jeuActuel': data.jeuActuel
    } as User;
  }

  noPhotoAdapt(data: any): User{
    return {
      'id': data.id,
      'iri': data["@id"],
      'username': data.username,
      'firstname': data.firstname,
      'lastname': data.lastname,
      'phone': data.phone,
      'organisation': data.organisation,
      'description': data.description,
      'roles': data.roles,
      'email': data.email,
      'joueur' : data.joueurs,
      'jeuActuel': data.jeuActuel
    } as User;
  }
}
