import { Student, StudentAdapter } from '@models/student';
import { StudentService } from '@services/student.service';

import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { IListerService } from '@interfaces/lister.service.interface';

export class StudentsDataSource implements DataSource<Student>{
  private studentsSubject= new BehaviorSubject<Student[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private studentService: StudentService){}
  connect(collectionViewer: CollectionViewer): Observable<Student[]> {
    return this.studentsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.studentsSubject.complete();
    this.loadingSubject.complete();
  }

  loadStudents(){
    this.loadingSubject.next(true);

    this.studentService.getStudents().pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(students => this.studentsSubject.next(students));
  }

}
