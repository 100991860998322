import { Component, OnInit } from '@angular/core';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { JeuService } from '@services/jeu.service';
import { Jeu } from '@models/jeu';


@Component({
  selector: 'app-page-edition-equipe',
  templateUrl: './page-edition-equipe.component.html',
  styleUrls: ['./page-edition-equipe.component.scss']
})
export class PageEditionEquipeComponent implements OnInit {
  idJeu: number;
  jeu: Jeu;

  loadingInitConfig: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jeuService: JeuService
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.getJeu();
  }

  getJeu(){
    this.jeuService.getOne(this.idJeu).subscribe(jeu => {
      this.jeu = jeu;
    });
  }

  suivant(){
    this.loadingInitConfig = true;
    this.jeuService.initEquipeConfig(this.idJeu).subscribe(() => {
      this.loadingInitConfig = false;
      this.router.navigate(['/jeux/designer/edit-joueurs/' + this.jeu.id]);
    });
  }
}
