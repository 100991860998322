import { Lister } from '@services/listers/lister.service';
import { TypeJoueur, TypeJoueurAdapter } from '@models/type-joueur';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TypeJoueurLister extends Lister<TypeJoueur>{
  constructor(http: HttpClient, adapter: TypeJoueurAdapter){
    super(http, adapter);
    this.setListUri('api/type_joueurs');
  }
}
