import { createReducer, Action, on } from '@ngrx/store';
import * as MessageActions from '../actions/message.actions';
import { state } from '@angular/animations';
import {initialState, MessageState} from '../states/message.state';

const messageReducer = createReducer(
  initialState,
  on(MessageActions.addMessageAction, onAddMessageAction),
  on(MessageActions.initMessagesAction, onInitMessagesAction)
);

function onAddMessageAction(state: MessageState, action: Action){
  let message = action['message'];


  let room = action['room'];

  let newState = {
    'messages': {...state.messages}
  };

  if(newState['messages'][room.id]){
    newState['messages'][room.id] = [...newState['messages'][room.id], message];
  }
  else{
    newState['messages'][room.id] = [message];
  }

  return newState;
}

function onInitMessagesAction(state: MessageState, action: Action){
  let room = action['room'];
  let messages = action['messages'];

  let newState = {
    'messages': {...state.messages}
  };

  newState['messages'][room.id] = [...messages];

  return newState;
}

export function reducer(state: MessageState | undefined, action: Action) {
  return messageReducer(state, action);
}
