<div class="division-data" *ngIf="typeEquipe as typeEquipe">
  <div class="division-left">
    <div class="user-profil">
      <div class="user-photo">
        <img [src]="contentUrl(typeEquipe.logo)" alt="logo" width="100%" (click)="fileInput.click()">
            <input class="form-control" type="file" id="logo_type_equipe" name="logo" (change)="onFileChange($event)" style="opacity: 0;" #fileInput>
      </div>
    </div>
  </div>

  <div class="division-right">
    <div>
      <form [formGroup]="typeEquipeForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-12">
                <div class="form-group">
                  <label for="nom_type_equipe">Nom type d'équipe :</label>
                  <input type="text" formControlName="nom" name="nom" class="form-control" id="nom_type_equipe">
                </div>
                <div class="form-group">
                    <label for="description_type_equipe">Description:</label>
                    <textarea spellcheck="false" formControlName="description" name="description" id="description_type_equipe" class="form-control" rows=5></textarea>
                </div>

                <div class="form-group">
                  <label>Classification</label>
                  <mat-select formControlName="ecosysteme">
                    <mat-option [value]=""></mat-option>
                    <mat-option *ngFor="let ecosysteme of ecosystemes" [value]="ecosysteme.slug">{{ ecosysteme.nom }}</mat-option>
                  </mat-select>
                </div>

                <div class="form-group">
                    <label for="type_de_joueur_associe">Type de joueurs associés:</label><br>
                    <p-multiSelect [options]="allTypeJoueurs" defaultLabel="Types de joueurs associés" optionLabel="nom" optionValue="iri" display="chip" formControlName="typeJoueurs">
                    </p-multiSelect>
                  </div>
                  <div class="form-group">
                    <label for="number_min_joueur">Nombre de joueur min:</label>
                    <input type="number" class="form-control col-2" formControlName="nbJoueursMin" name="nbJoueursMin" id="number_min_joueur" min=0 value=2>
                  </div>
                  <div class="form-group">
                    <label for="number_max_joueur">Nombre de joueur max:</label>
                    <input type="number" class="form-control col-2" formControlName="nbJoueursMax" name="nbJoueursMax" id="number_max_joueur" min=0 value=6>
                </div>

                <button type="submit" mat-raised-button color="primary" [disabled]="!typeEquipeForm.valid">
                  <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
                  Enregistrer
                </button>
            </div>
          </div>
        </form>
    </div>
  </div>
</div>
