import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TypeJoueurRoutingModule } from './type-joueur-routing.module';

// Material Module imports
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';

import { TypeJoueurListComponent } from './type-joueur-list/type-joueur-list.component';
import { TypeJoueurCreateComponent } from './type-joueur-create/type-joueur-create.component';
import { TypeJoueurEditComponent } from './type-joueur-edit/type-joueur-edit.component';

@NgModule({
  declarations: [TypeJoueurListComponent, TypeJoueurCreateComponent, TypeJoueurEditComponent],
  imports: [
    CommonModule,
    TypeJoueurRoutingModule,

    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    ReactiveFormsModule
  ]
})
export class TypeJoueurModule { }
