import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppService } from '@services/app.service';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

  constructor(private appService: AppService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if(err.status == 0)
        {
          this.appService.setOffline();
        }
        return throwError(err);
      }),
      tap(() => {
        this.appService.setOnline();
      })
    );
  }
}
