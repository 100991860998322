<div class="player-layout">
  <div class="left-player">
    <!---debut header menu -->
    <div class="header player">
      <a href="" class="logo">
        <img src="/assets/images/logo-blanc.png" />
      </a>

      <input class="menu-btn" type="checkbox" id="menu-btn" />
      <label class="menu-icon" for="menu-btn"
        ><span class="navicon"></span
      ></label>
      <ul class="menu">
        <!--<li><a href="#work">Menu 1</a></li>
    <li><a href="#about">Menu 2</a></li>
    <li><a href="#careers">Menu 3</a></li>-->
        <li>
          <a class="logout-player" href=""
            ><img src="/assets/images/logout.svg" alt=""
          /></a>
        </li>
      </ul>
    </div>
    <!---fin header menu -->

    <div class="game-list">
      <h3>Liste des jeux</h3>
      <ul>
        <li
          *ngFor="let jeu of listJeux"
          (click)="playJeu(jeu.id)"
          class="one-game"
        >
          {{ jeu.nom }}
        </li>
      </ul>
      <p *ngIf="listJeux && listJeux.length == 0">Pas de jeu actuellement en cours</p>
    </div>
  </div>

  <div class="right-player game">
    <div class="profil">
      <div class="user-profil">
        <div class="profil-personnel d-game">
          <div class="user-photo">
            <ng-container *ngIf="!user.photo">
              <img src="/assets/images/hector.jpg" />
            </ng-container>
            <ng-container *ngIf="user.photo">
              <img [src]="user.photo.contentUrl | contentUrl" />
            </ng-container>
          </div>
          <div class="user-info selection">
            <h3>Bonjour {{ user.firstname }} {{ user.lastname }}!</h3>
            <span
              >Sélectionner un jeux sur la liste des jeux pour commencer une
              partie</span
            >
            <img src="./assets/images/list-animate.gif" class="animate" />

            <button mat-raised-button color="warn" (click)="logout()">
              <mat-icon>power_settings_new</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
