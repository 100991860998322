import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Jeu, JeuAdapter } from '@models/jeu';
import { CustomDatasource } from '@datasources/custom-datasource';
import { Lister } from '@services/listers/lister.service';
import { JeuLister } from '@services/listers/jeu-lister';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JeuService } from '@services/jeu.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { jeuSelectAction } from '@app/core/actions/jeu.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jeu-list',
  templateUrl: './jeu-list.component.html',
  styleUrls: ['./jeu-list.component.scss'],
  providers: [JeuLister]
})
export class JeuListComponent implements OnInit {
  @Input() isAnimateur: boolean = false;
  @Input() isAdmin: boolean = true;
  @Input() isAnimation: boolean = false;
  @Input() isTemplate: boolean = false;

  @Output('selectedJeu') selectedJeu = new EventEmitter<number>();

  dataSource : CustomDatasource<Jeu>;
  dataSourceFinishedState : CustomDatasource<Jeu>;

  displayedColumns = ["nom_jeu", "action"];
  defaultPageSize = 5;
  closeModal: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loadingReplay: number = 0; // will contain the jeu id if not 0

  constructor(
    private jeuLister: JeuLister,
    private adapter: JeuAdapter,
    private modalService : NgbModal,
    private jeuService : JeuService,
    private store: Store,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dataSource = new CustomDatasource<Jeu>(this.jeuLister, this.adapter);
    this.dataSource.load({finished: false},null,this.defaultPageSize);

    this.dataSourceFinishedState = new CustomDatasource<Jeu>(this.jeuLister, this.adapter);
    this.dataSourceFinishedState.load({finished: true},null,this.defaultPageSize);
  }

  /*****Sorting and paginator**********/
  ngAfterViewInit(): void {

    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    if(this.paginator){

      merge(this.paginator.page).pipe(
        debounceTime(500),
        tap(() => {
          this.load();
        })
      ).subscribe();
    }
  }

  load(){
    // let sortCol = this.sort.active;
    // let sortDirection = this.sort.direction;
    // let orders = {[sortCol]: sortDirection};

    let orders = {};
    let filterTrue = {finished: true};
    let filter = {finished: false};

    this.dataSource.load(filter, this.paginator.pageIndex, this.paginator.pageSize, orders);
    this.dataSourceFinishedState.load(filterTrue, this.paginator.pageIndex, this.paginator.pageSize, orders);
  }


  /************Modal setting*************/
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  onDelete(id: number, state: boolean){
    this.jeuService.delete(id).subscribe(
      () => {
        if(state==false){
          this.dataSource.load({finished: false},null,this.defaultPageSize)
        }else{
          this.dataSourceFinishedState.load({finished: true},null,this.defaultPageSize);
        }
      }
    )
  }

  onSelectJeu(id: number){
    this.selectedJeu.emit(id);
    this.store.dispatch(jeuSelectAction({id: id}));
  }

  replayGame(id: number){
    this.loadingReplay = id;
    this.jeuService.cloner(id).subscribe(jeu => {
      this.router.navigate([`/jeux/designer/creation/${jeu.id}`]);
      // this.loadingReplay = tr;
    });
  }
}
