<body>
  <div class="loading-layer" *ngIf="!(jeu)">
  <mat-spinner></mat-spinner>
  <div class="load-fix"><img src="/assets/images/load-fix.png" alt=""></div>
</div>
<div class="player-layout" *ngIf="(jeu)">

    <!---LEFT COL -->
    <div class="left-player">

        <!---debut header menu -->
        <div class="header player">
            <a href="" class="logo">
                <img src="/assets/images/logo-blanc.png">
            </a>

            <div class="game-round">
              <ul class="timeline">
                  <li [ngClass]="{'round-active': jeu.tour==0}">Intro</li>
                  <li [ngClass]="{'round-active': jeu.tour==1}">Round 1</li>
                  <li [ngClass]="{'round-active': jeu.tour==2}">Round 2</li>
                  <li [ngClass]="{'round-active': jeu.tour==3}">Round 3</li>
                  <li [ngClass]="{'round-active': jeu.tour==4}">Débrief</li>
                </ul>
            </div>

          <div>
            <ul class="menu">
              <!--<li><a href="#work">Menu 1</a></li>
              <li><a href="#about">Menu 2</a></li>
              <li><a href="#careers">Menu 3</a></li>-->
              <li><a class="logout-player" (click)="logout()"><img src="/assets/images/logout.svg" alt="" ></a></li>

            </ul>
          </div>
        </div>
          <!---fin header menu -->


          <!---debut game header -->
        <div class="game-header">
            <div class="game-name">
                <span class="material-icons-outlined">
                  sports_esports
              </span>
              <span>{{ jeu.nom }}</span>
          </div>



          <div class="phase">
              <ul>
                  <li>Phase 1</li>
                  <li>Phase 2</li>
                  <li class="active-phase">Phase 3</li>
                  <li>Phase 4</li>
                  <li>Phase 5</li>
              </ul>
          </div>



            <div class="game-time">
              <span class="material-icons-outlined">
                  timer
                  </span>
              <!-- <app-timer [departTime]="startedTime"></app-timer> -->
            </div>

        </div>
        <!---fin game header -->


        <div class="content-player">
          <div class="container-menu-onglets">
            <div class="onglets {{ activatedOnglet == 'news' ? 'active': ''}}" data-anim="1" (click)="activateOnglet('news')">News</div>
            <div class="onglets {{ activatedOnglet == 'resultats' ? 'active': ''}}" data-anim="5" (click)="activateOnglet('resultats')">Résultats</div>
            <div class="onglets {{ activatedOnglet == 'debrief' ? 'active': ''}}" data-anim="5" (click)="activateOnglet('debrief')">Débrief</div>
            <div class="onglets {{ activatedOnglet == 'breaking' ? 'active': ''}}" data-anim="5" (click)="activateOnglet('breaking')">Breaking News</div>
          </div>


            <div class="container-onglets">
              <div class="contenu {{ activatedOnglet == 'news' ? 'activeContenu': ''}}" data-anim="1">
                  <h3>Annonces</h3>

              </div>

              <div class="contenu {{ activatedOnglet == 'resultats' ? 'activeContenu': ''}}" data-anim="6">
                  <h3>{{ 'layout_resultat' | translate }}</h3>


              </div>

              <div class="contenu {{ activatedOnglet == 'debrief' ? 'activeContenu': ''}}" data-anim="5">
                  <h3>Débrief</h3>


              </div>

              <div class="contenu {{ activatedOnglet == 'breaking' ? 'activeContenu': ''}}" data-anim="5">
                <h3>Breaking News</h3>


              </div>
            </div>
          </div>


          <!---debut breaking news -->
        <div class="fil-breaking-news">
            <div class="break-logo"><img src="/assets/images/break-logo.png"></div>
            <div class="txt">LARGE CYBER ATTACKS AGAINST M.U.N.I. LUXURY BRAND - 900 million personal accounts leaked / heavy fines by euro regulators possible</div>
        </div>
          <!---fin breaking news -->
    </div>


    <!---RIGHT COL -->
    <div class="right-player">
        <div class="profil">
            <div class="user-profil">

                <div class="profil-personnel">
                    <div class="user-photo">
                        <img src="/assets/images/hector.jpg">
                    </div>
                    <div class="user-info">
                        <h3>Animateur</h3>


                    </div>
                  </div>

            </div>


        </div>


        <div class="titre-section">
            <h3><span class="material-icons-outlined">
              sms
              </span>
               Messagerie secrete</h3>
            <a href="">...</a>
        </div>



        <div class="tchat">

            <div class="tchat-item">
                <div class="tchat-photo"><img src="/assets/images/profil-hacker.jpg"></div>
                <div class="tchat-message">
                    <div class="tchat-user">
                        <span class="name">Les hackers</span>
                        <span class="timechat">20h24</span>
                    </div>
                    <div class="tchat-mp">On a 30 M de dossiers personnels venant de chez Raphaël Laurent. Avec des numéros de cb</div>
                </div>
            </div>

            <div class="tchat-item">
                <div class="tchat-photo"><img src="/assets/images/profil-pardi.jpg"></div>
                <div class="tchat-message">
                    <div class="tchat-user">
                        <span class="name">Pardi</span>
                        <span class="timechat">20h25</span>
                    </div>
                    <div class="tchat-mp">Top! ne faites rien pour le moment</div>
                </div>
            </div>

            <div class="tchat-item">
                <div class="tchat-photo"><img src="/assets/images/profil-hacker.jpg"></div>
                <div class="tchat-message">
                    <div class="tchat-user">
                        <span class="name">Les hackers</span>
                        <span class="timechat">20h25</span>
                    </div>
                    <div class="tchat-mp">C'est vous le patron</div>
                </div>
            </div>

        </div>

        <div class="titre-section">
            <h3><span class="material-icons-outlined">
              article
              </span>Journal d'évènements</h3>
            <a href="">...</a>
        </div>




        <div class="journal">

          <div class="journal-item">

              <div class="journal-content">
                  <div class="journal-author">Etat</div>
                  Nouveau plan d’action cyber « Sécurité 2025 »

              </div>
              <div class="journal-time">20h20</div>
          </div>


          <div class="journal-item">

              <div class="journal-content">
                  <div class="journal-author">Activiste</div>
                  Hashtag « #ToutTransparent »

              </div>
              <div class="journal-time">20h22</div>
          </div>

          <div class="journal-item">

              <div class="journal-content">
                  <div class="journal-author">Raphaël Laurent </div>
                  Annonce concernant des fausses rumeurs de Leak

              </div>
              <div class="journal-time">20h25</div>
          </div>

          <div class="journal-item">

              <div class="journal-content">
                  <div class="journal-author">Activiste </div>
                  Hashtag « #RaphaëlDisLaVerite ! »

              </div>
              <div class="journal-time">20h30</div>
          </div>

          <div class="journal-item">

              <div class="journal-content">
                  <div class="journal-author">Raphaël Laurent </div>
                  Annonce officielle d’excuse et d’explication du PDG

              </div>
              <div class="journal-time">20h32</div>
          </div>





      </div>
    </div>



</div>
</body>
