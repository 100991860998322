import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ActionJeu, ActionJeuAdapter } from '@interfaces/actionJeu';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lister } from './listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class ActionJeuService extends Lister<ActionJeu> {

  constructor(protected actionJeuAdapter: ActionJeuAdapter,
              protected http: HttpClient,
    ) {
    super(http, actionJeuAdapter);
    this.setListUri("api/action_jeus");
  }

  getByJeuAndTour(idJeu: number, tour: number): Observable<ActionJeu[]>{
    return this.getAll({"tourLancement": tour, "jeu": idJeu});
  }

  createActionService(data: any){
    return this.http.post(`${environment.apiUrl}/${this.listUri}`, data).pipe(
      map(data => {
        return this.actionJeuAdapter.adapt(data);
      })
    );
  }

  runActionJeu(actionJeu){
    const url = `${environment.apiUrl}/${this.listUri}/${actionJeu.id}/run`;
    return this.http.put(url, actionJeu).pipe(
      map(this.actionJeuAdapter.adapt),
    )
  }

  update(id: number, actionJeu: any){
    actionJeu.jeu = actionJeu.iriJeu;
    return this.http.put(`${environment.apiUrl}/${this.listUri}/${id}`, actionJeu).pipe(
      map(this.actionJeuAdapter.adapt),
    )
  }
}
