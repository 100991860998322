import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { JeuService } from '@services/jeu.service';
import { Observable } from 'rxjs';
import { Jeu } from '@models/jeu';
import { ActivatedRoute, Router } from '@angular/router';
import { Equipe } from '@models/equipe';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TypeEquipeService } from '@services/type-equipe.service';
import { TypeJeuService } from '@services/type-jeu.service';
import { UserService } from '@services/user.service';
import { User } from '@models/user';
import { TypeJeu } from '@models/type-jeu';
import { TypeEquipe } from '@models/type-equipe';
import { Joueur } from '@models/joueur';

@Component({
  selector: 'app-jeu-edit',
  templateUrl: './jeu-edit.component.html',
  styleUrls: ['./jeu-edit.component.scss']
})
export class JeuEditComponent implements OnInit {

  jeuForm = new FormGroup({
    nom: new FormControl('',[Validators.required]),
    description: new FormControl(''),
    type: new FormControl('',[Validators.required]),
    nbJoueurs: new FormControl('',[Validators.required]),
    debut_date: new FormControl('',[Validators.required]),
    debut_time: new FormControl('',[Validators.required]),
    finished : new FormControl(''),
    isExemple : new FormControl(''),
    animateurJeus : new FormControl(''),
    nbTours: new FormControl('',[Validators.required])
  });

  jeu$: Observable<Jeu>;
  jeu : any;
  idJeu: number;
  isSubmitting = false;


  allTypeJeux: TypeJeu[] = [];
  allTypeEquipes: TypeEquipe[] = [];
  allAnimateurs: User[] = [];
  allUsers = [];

  equipes : Equipe[];
  animateurs : any[];
  currentEquipe : Equipe = {
    'nom': '',
    'iriTypeEquipe': '',
    'typeEquipes': null,
    'joueurs': [],
  };

  currentJoueurInfo = {
    'index' : null,
    'equipeIndex' : null
  };

  currentJoueur : Joueur = {
    'type' : '',
    'isPrincipal' : true,
    'iriUser' : '',
    'user' : null,
    'equipe' : null,
  }

  createEquipeModalRef: NgbModalRef;
  selectUserModalRef: NgbModalRef;

  constructor(
    private route: ActivatedRoute,
    private jeuService: JeuService,
    private typeEquipeService : TypeEquipeService,
    private typeJeuService : TypeJeuService,
    private userService : UserService,
    private router : Router,
    private modalService : NgbModal,
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.id;
    this.jeu$ = this.jeuService.getOne(this.idJeu);

    this.jeu$.subscribe(data => {
      const datetime = data['debut'].split("T", 2);
      const time = datetime[1].slice(0,5);
      this.jeuForm.patchValue({
        nom: data['nom'],
        description: data['description'],
        type: data['type']['@id'],
        nbJoueurs: data['nbJoueurs'],
        nbTours: data['nbTours'],
        debut_date: datetime[0],
        debut_time: time,
        finished: data['finished'],
        isExemple: data['isExemple'],
        animateurJeus : data['animateurJeus'].map((animateur) => animateur.user['@id']),
        // animateurJeus : data['animateurJeus'] ? data['animateurJeus'].map((animateur) => animateur.user['@id']) : [],
      });
      this.animateurs = data['animateurJeus'];
      this.jeu = data;

      for(let i = 0; i < this.jeu.equipes.length; i++){
        const typeJoueur = this.jeu.equipes[i].joueurs.map((joueur) => {
          return joueur.type['@id'];
        });
        const allTypeJoueur = this.jeu.equipes[i].type.typeJoueurs;
        let joueurs = [];
        for(let j= 0; j < allTypeJoueur.length; j++){
          const indexJoueur = typeJoueur.indexOf(allTypeJoueur[j]['@id']);
          if(indexJoueur == -1){
            joueurs[j] = {
              'type': allTypeJoueur[j]['@id'],
              'isPrincipal': true,
              'iriUser': '',
              'user': null,
              'equipe': this.jeu.equipes[i]['@id']
            } as Joueur;
          }
          else {
            joueurs[j] = this.jeu.equipes[i].joueurs[indexJoueur];
            joueurs[j].type = joueurs[j].type['@id'];
          }
        }
        this.jeu.equipes[i].joueurs = [...joueurs];
        this.jeu.equipes[i].typeEquipes = this.jeu.equipes[i].type;
      }

      this.equipes = this.jeu.equipes;
      this.getTypeEquipes();
      this.getTypeJeux();
      this.getAllUsers();
      this.getAnimateur();
    });
  }

  getTypeJeux(){
    this.typeJeuService.getAll().subscribe(typeJeux => {
      this.allTypeJeux = typeJeux;
    });
  }

  getTypeEquipes(){
    this.typeEquipeService.getAll().subscribe(typeEquipes => {
      this.allTypeEquipes = typeEquipes;
    });
  }

  getAnimateur(){
    this.userService.getAnimateur().subscribe(animateurs => {
      this.allAnimateurs = animateurs;
    })
  }

  getAllUsers(){
    this.userService.getFreeUser().subscribe((users)=> {
      this.allUsers = users.map((user) => {
        return {
          'iri' : user.iri,
          'isSelected' : false,
          'username' : user.username,
          'email' : user.email
        };
      });
    });
  }

  notSelectedUser(listUsers){
    return listUsers.filter((user) => user.isSelected == false)
  }

  getJoueurWhoPlay(listJoueur: any[],typeJoueur){
    const result = listJoueur.find((joueur) => joueur.type['@id'] == typeJoueur['@id']);
    if(result != undefined){
      return result.user;
    }
    return null;
  }

  onSubmit(){
    const formValue = this.jeuForm.value;
    const debut = formValue['debut_date']+formValue['debut_time'];
    let jeuValue;
    this.jeu = formValue  as Jeu;
    this.jeu.debut = debut;
    this.jeu.finished = formValue['finished'];

    if(this.jeuForm.valid){
      this.isSubmitting = true;
      jeuValue = {
        'nom' : formValue.nom,
        'description' : formValue.description,
        'type' : formValue.type,
        'nbJoueurs' : formValue.nbJoueurs,
        'debut' : formValue.debut_date + formValue.debut_time,
        'nbTours' : formValue.nbTours,
        'equipes' : [],
        'finished' : formValue.finished,
        'isExemple' : formValue.isExemple,
        'animateurJeus': formValue.animateurJeus.map((iriUser) => {
          const animateur = this.animateurs.find((anim) => anim.user['@id'] == iriUser);
          if(animateur){
            return {
              "id" : animateur["@id"],
              "user" : iriUser,
            }
          }
          else {
            return {
              "user" : iriUser
            }
          }
        })
      };


      for(let i = 0; i < this.equipes.length; i++){
        let mappedjoueurs = this.equipes[i].joueurs.filter((joueur) => joueur.user != null);
        jeuValue.equipes[i] = {
          'id' : this.equipes[i].id ? '/api/equipes/' + this.equipes[i].id : null,
          'nom': this.equipes[i].nom,
          'type': this.equipes[i].typeEquipes['@id'],
          'isExemple': true,
          'jeu': '/api/jeus/' + this.idJeu,
          'joueurs': mappedjoueurs.map((joueur) => {
            if(joueur['@id']){
              return {
                'id': joueur['@id'],
                'user': joueur.iriUser ? joueur.iriUser : joueur.user['@id'],
                'isPrincipal': joueur.isPrincipal,
                'type': joueur.type
              }
            }
            else{
              return {
                'user': joueur.user['iri'],
                'isPrincipal': joueur.isPrincipal,
                'type': joueur.type
              }
            }
          })
        };
      }
      this.jeuService.update(jeuValue, this.idJeu).subscribe(
        (data)=>{
          this.isSubmitting = false;
          this.router.navigate(['/jeux']);
        }
      )
    }
  }


  triggerModal(content, type ){
    if(type == 'selectUser'){
      this.selectUserModalRef = this.modalService.open(content);
    }
    else if (type == 'newEquipe'){
      this.createEquipeModalRef = this.modalService.open(content);
    }
  }

  listUser(index: number, equipeIndex : number, content){
    this.currentJoueurInfo = {
      "index" : index,
      "equipeIndex" : equipeIndex
    };
    this.triggerModal(content, 'selectUser');
  }

  removeUser(index, equipeIndex){
    for(let i = 0; i < this.allUsers.length; i++){
      if(this.allUsers[i].iri == this.equipes[equipeIndex].joueurs[index].user.iri){
        this.allUsers[i].isSelected = false;
      }
    }
    this.equipes[equipeIndex].joueurs[index].user = null;
  }

  removeEquipe(equipeIndex){
    let arrayJoueur = this.equipes[equipeIndex].joueurs.filter((joueurs) => joueurs.user != null);
    let arrayIriUser = arrayJoueur.map((joueur) => joueur.user.iri);
    for(let i = 0; i < this.allUsers.length; i++){
      if(arrayIriUser.includes(this.allUsers[i].iri)){
        this.allUsers[i].isSelected = false;
      }
    }
    this.equipes.splice(equipeIndex, 1);
  }

  addUser(){
    const equipeIndex = this.currentJoueurInfo.equipeIndex;
    const index = this.currentJoueurInfo.index;
    const equipe = this.equipes[equipeIndex];
    this.equipes[equipeIndex].joueurs[index].iriUser = this.currentJoueur.iriUser;

    // Selectionne un joueur dans la liste des users
    for(let i = 0; i < this.allUsers.length; i++){
      if(this.allUsers[i].iri == this.currentJoueur.iriUser){
        this.equipes[equipeIndex].joueurs[index].user = {
          'iri' : this.currentJoueur.iriUser,
          'username' : this.allUsers[i].username
        } as User;
        this.allUsers[i].isSelected = true;
      }
    }
    this.currentJoueur.iriUser = null;
    this.selectUserModalRef.close();
  }


  addEquipe(){
    // On recupere le typeEquipe correspondant a l'iriTypeEquipe de currentEquipe
    if(this.currentEquipe.iriTypeEquipe != ""){
      this.currentEquipe.typeEquipes = this.allTypeEquipes.find(t => t.iri == this.currentEquipe.iriTypeEquipe);
      const totalTypeJoueur = this.currentEquipe.typeEquipes.typeJoueurs.length;
      let allJoueur = this.currentEquipe.typeEquipes.typeJoueurs.map((typeJoueur) => {
        return {
          'type': typeJoueur['@id'],
          'isPrincipal': true,
          'user': null,
          'iriUser': null,
        } as Joueur;
      })
      this.currentEquipe.joueurs = allJoueur;
      this.equipes.push({...this.currentEquipe});
      this.currentEquipe.nom = "";
      this.currentEquipe.iriTypeEquipe = "";
      this.currentEquipe.typeEquipes = null;
      this.createEquipeModalRef.close();
    }
  }

  /**
   * Redirige vers la page édition d'une Equipe
   */
  goToEditEquipe(idEquipe: number){
    this.router.navigate(['/equipe/' + idEquipe + '/edit'], { queryParams: { returnUrl: this.router.url}});
  }
}
