import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { User, UserAdapter } from '@models/user';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserService } from './user.service';
import { Role } from '@models/role';
import { Store } from '@ngrx/store';
import { setUserAction } from '@app/core/actions/user.actions';
import { playJeuAction, resetJeuAction } from '@app/core/actions/jeu.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentUser: Observable<User>;
  private userInfoSubject: BehaviorSubject<User>;
  private userInfo: Observable<User>;

  constructor(
    private http: HttpClient,
    private adapter: UserAdapter,
    private userService: UserService,
    private store: Store
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.userInfoSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUserInfo')));
    this.userInfo = this.userInfoSubject.asObservable();
  }

  parseJWTToken(){
    var base64Url = this.currentUserSubject.value.token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  };

  getRole(){
    return this.parseJWTToken().roles;
  }

  is_connected(){
    //TODO: Verifiing if the user's token is expired
    if(this.currentUserValue) return true;
  }
  isAdmin(): boolean {
    if (this.currentUserValue)
      return this.getRole().indexOf(Role.admin) !== -1;
    else
      return false;
  }
  isAnimateur(): boolean {
    if (this.currentUserValue){
      return this.getRole().indexOf(Role.animateur) !== -1;
    }
    else
      return false;
  }
  isOwnerOf(id: number): boolean | Observable<boolean> | Promise<boolean> {
    return this.currentUserInfo.id == id;
}
  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get currentUserInfo() {
    return this.userInfoSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/login_check`, { 'username': username, 'password': password })
      .pipe(
        map(user => {
          if (user && user.token) {
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            return this.http.get(`${environment.apiUrl}/api/users/check_name?username=${username}`)
              .pipe(
                map(data => data['hydra:member'][0]),
                map(data => this.adapter.adapt(data)),
                map(user => {
                  sessionStorage.setItem('currentUserInfo', JSON.stringify(user))
                  this.userInfoSubject.next(user);


                  var id = user.id;
                  var jeuActuel = user.jeuActuel;

                  // this.store.dispatch(setUserAction({id: id, jeuActuel: jeuActuel}));

                  if(jeuActuel != null)
                  {
                    this.store.dispatch(playJeuAction({id: jeuActuel.id}));
                  }
                  return user;
                })
              );
          }
        })
      );
  }

  logout() {
    sessionStorage.clear();
    this.store.dispatch(resetJeuAction());

    this.currentUserSubject.next(null);
    this.userInfoSubject.next(null);
  }

  refreshToken() {
    return of([]);
  }
  signup(user: any): Observable<User> {
    return this.http.post(`${environment.apiUrl}/api/users`, user)
      .pipe(map((data: any) => this.adapter.adapt(data))
      )
      ;
  }
}
