<div *ngIf="user$ | async as user">
  <div class="division-data">
    <div class="division-left">
      <div class="user-profil">
        <div class="user-photo">
          <img [src]="itemImageUrl" alt="logo" (click)="fileInput.click()">
          <input class="form-control" type="file" id="logo_type_equipe" name="logo" style="opacity: 0;" #fileInput formControlName="photo">
        </div>
      </div>
    </div>
    <div class="division-right">
      <div class="row">
        <div class="col-sm-3">
          <h4 class="mb-0">Email / {{ 'nom_utilisateur' | translate }}</h4>
        </div>
        <div class="col-sm-9 text-secondary">
          {{user.username}}
        </div>
      </div><hr>
      <div class="row">
        <div class="col-sm-3">
          <h4 class="mb-0">{{ 'roles' | translate }}</h4>
        </div>
        <div class="col-sm-9 text-secondary">
          {{user.roles}}
        </div>
      </div><hr>
      <div class="row">
        <div class="col-sm-3">
          <h4 class="mb-0">{{ 'nom' | translate }}</h4>
        </div>
        <div class="col-sm-9 text-secondary">
          {{user.lastname}}
        </div>
      </div><hr>
      <div class="row">
        <div class="col-sm-3">
          <h4 class="mb-0">{{ 'prenoms' | translate }}</h4>
        </div>
        <div class="col-sm-9 text-secondary">
          {{user.firstname}}
        </div>
      </div><hr>
      <div class="row">
        <div class="col-sm-3">
          <h4 class="mb-0">{{ 'email' | translate }}</h4>
        </div>
        <div class="col-sm-9 text-secondary">
          {{user.email}}
        </div>
      </div><hr>
      <div class="row">
        <div class="col-sm-3">
          <h4 class="mb-0">{{ 'telephone' | translate }}</h4>
        </div>
        <div class="col-sm-9 text-secondary">
          {{ user.phone }}
        </div>
      </div><hr>
      <div class="row">
        <div class="col-sm-3">
          <h4 class="mb-0">{{ 'organisation' | translate }}</h4>
        </div>
        <div class="col-sm-9 text-secondary">
          {{user.organisation}}
        </div>
      </div><hr>
      <div class="row">
        <div class="col-sm-3">
          <h4 class="mb-0">{{ 'layout_description' | translate }}</h4>
        </div>
        <div class="col-sm-9 text-secondary">
          {{user.description}}
        </div>
      </div><hr>
      <div class="row">
        <div class="col-sm-3">
          <button type="submit" mat-raised-button color="primary" [routerLink]="['/users/edit', user.id]">{{ 'modifier' | translate }}</button>
        </div>
      </div><hr>
    </div>
  </div>
</div>
