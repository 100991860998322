import { Jeu } from './jeu';
import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { MediaObject } from './media-object';

export interface Annonce{
  id?: number,
  type?: string,
  titre?: string,
  description?: string,
  jeu?: Jeu,
  tour?: number,
  ordre?: number,
  launched?: boolean,
  image?: MediaObject
}

@Injectable({
  providedIn: 'root'
})
export class AnnonceAdapter implements Adapter<Annonce>{
  adapt(data: any){
    return {
      'id': data['id'],
      'type': data['type'],
      'titre': data['titre'],
      'description': data['description'],
      'jeu': data['jeu'],
      'tour': data['tour'],
      'ordre': data['ordre'],
      'launched': data['launched'],
      'image': data['image'] as MediaObject
    } as Annonce;
  }
}
