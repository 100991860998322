<div *ngIf="(equipe$ | async) as equipe">
  <div class="row">
    <div class="col-12">
      <h2>Equipe {{ equipe.nom }}</h2>
    </div>
    <div class="col-12">
      <div class="ml-16">
        <div class="row">
          <div class="col-md-2">Description:</div>
          <div class="col-md-8 text-secondary"><em *ngIf="equipe.description == undefined">Pas de description!</em> {{ equipe.description }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2">Objectif public:</div>
          <div class="col-md-8 text-secondary"><em *ngIf="equipe.objectifPublic == undefined"> Pas d'objectif public pour le moment</em>{{ equipe.objectifPublic }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2">Moyens d'actions:</div>
          <div class="col-md-8 text-secondary"><em *ngIf="equipe.moyensActions == undefined"> Pas de moyens d'actions pour le moment</em>{{ equipe.moyensActions }}</div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <h2 class="mb-0">Liste des Joueurs associés</h2>
      <mat-list class="pt-0">
        <div *ngIf="equipe.joueurs.length == 0" class="ml-16">
          <em>Aucun joueur</em>
        </div>
        <div *ngIf="equipe.joueurs.length > 0">
          <mat-list-item *ngFor="let joueur of equipe.joueurs">
            <mat-icon mat-list-icon>person</mat-icon>
            {{ joueur.user.username }} : {{ joueur.type.nom }}
          </mat-list-item>
        </div>
      </mat-list>

    </div>
    <div class="col-12">
      <button mat-raised-button color="primary" (click)="updateEquipe(equipe.id)">Modifier</button>
    </div>
  </div>
</div>
