import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Message } from '@models/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private http: HttpClient
  ) { }

  sendMessage(message: Message){

    const messageData = {
      'text': message.text,
      'jeu': `/api/jeus/${message.jeu.id}`
    }

    const url = `${environment.apiUrl}/api/messages`;
    return this.http.post(url, messageData);
  }


}
