import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { HistoriqueAdapter } from '@models/historique';

@Injectable({
  providedIn: 'root'
})
export class HistoriqueService {

  constructor(
    private http: HttpClient,
    private historiqueAdapter: HistoriqueAdapter
  ) { }

  getPublished(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/historique/${idJeu}?published=true`;

    return this.http.get(url).pipe(
      map((data: any) => data.map(x => this.historiqueAdapter.adapt(x)))
    );
  }
}
