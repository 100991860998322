<div class="one-round-designer">
  <h4 class="underlined-title">Round {{ tour }}</h4>

  <mat-list>
    <app-list-produit-edit-item *ngFor="let produit of produits" [produit]="produit" [jeu]="jeu" [tour]="tour" (deletedProduit)="onDeletedProduit($event)"></app-list-produit-edit-item>
  </mat-list>

  <label class="mt-3">{{ 'produits' | translate }}</label>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <mat-select name="produitAjout" [formControl]="selectProduitId">
          <mat-option></mat-option>
          <mat-option *ngFor="let produit of allProduitsInnovation" [value]="produit.id">{{ produit.nom }}</mat-option>
        </mat-select>
      </div>

      <div class="col-6">
        <button mat-mini-fab color="primary" (click)="ajouterProduit()" [disabled]="loadingAddProduit || !selectProduitId.value">
          <!-- <span class="spinner-border spinner-border-sm" *ngIf="loadingAddProduit"></span> -->
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-6 pl-0">
        <button mat-raised-button color="primary" (click)="goToAjouterProduit()">
          {{ 'ajouter_produit' | translate }}
        </button>
      </div>
    </div>
  </div>

  <hr>
</div>
