
<div class="container" >
  <div class="card" *ngFor="let equipeAvailable of equipesAvailables; let j=index">
    <div class="card-header">
      <h4>{{ allEquipes[j].nom }} (Source)</h4>
    </div>
    <div class="card-body">
      <h5>Destinations</h5>
      <p-multiSelect [options]="equipeAvailable" [(ngModel)]="selectedEquipe[j]" defaultLabel="Les équipes associées" optionLabel="nom" display="chip">
      </p-multiSelect>
    </div>
    <div class="card-footer">
      <button type="button" class="button-save" (click)="onSave(j)">Ajouter</button>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h4>Messageries Configurées</h4>
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <thead>
          <th>
            Source
          </th>
          <th>
            Destination
          </th>
          <th>
            Action
          </th>
        </thead>
        <tbody>
          <tr *ngFor="let droitMessagerie of droitMessageries">
            <td>{{droitMessagerie.source.nom}}</td>
            <td>{{droitMessagerie.destination.nom}}</td>
            <td><button type="button" class="button-delete" (click)="onDelete(droitMessagerie)">Supprimer</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

  



