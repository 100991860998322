import { Jeu } from '@models/jeu';

/**
 * Retourne le tour qui compte pour le résultat
 * si on est dans le tour t, mais pas encore dans la phase  "resultats", on retourne t-1
 * sinon, si on est dans "resultats", on retourne t
 *
 * @param tour le tour actuel du Jeu
 * @param typePhase le slug de la phase de Jeu
 *
 * @return number
 */
export function tourResultat(tour: number, typePhase: string){
  if(typePhase == 'resultats'){
    return tour;
  }
  else{
    if(tour == 0){
      return 0;
    }
    else{
      return tour-1;
    }
  }
}

export function tourBudgetHacker(tour: number, typePhase: string){
  if(typePhase == 'pitchs' || typePhase == 'votes' || typePhase == 'resultats'){
    return tour;
  }else{
    if(tour == 0){
      return 0;
    }else{
      return tour-1;
    }
  }
}

/**
 * Retourne vrai si l'onglet "phase" doit être affiché pour la phase actuelle du Jeu
 */
export function phaseIs(phase: string, jeu: Jeu){
  if(!jeu.currentAction){
    return false;
  }

  switch (phase) {
    case 'decisions':
      return ['decisions', 'pitchs', 'votes', 'resultats'].includes(jeu.currentAction.type);
      break;
    case 'votes':
      return ['votes', 'resultats'].includes(jeu.currentAction.type);
      break;
    case 'resultats':
      return ['resultats'].includes(jeu.currentAction.type);
      break;
    default:
      break;
  }
}
