import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from '@services/message.service';
import { Message } from '@models/message';
import { User } from '@models/user';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectMessages } from '@app/core/selectors/message.selector';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit {
  @Input() idJeu;

  messageText = new FormControl('');
  messages$: Observable<string[]>;

  constructor(
    private messageService: MessageService,
    private store: Store
  ) { }

  ngOnInit(): void {
    // this.messages$ = this.store.pipe(select(selectMessages));
  }

  onSendMessage(){
    let message = {
      'text': this.messageText.value,
      'jeu': {
        'id': this.idJeu
      }
    } as Message;

    this.messageService.sendMessage(message).subscribe(data => {

    });
  }
}
