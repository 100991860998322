import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from '@environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);


    // Code verifiant si on upload un fichier ou si on fait de simples appels api
    if(request.headers.has('Content-Type') && '' == request.headers.get('Content-Type')){ // Si on fait un upload
      request = request.clone({ headers: request.headers.delete('Content-Type')}) // On doit supprimer le content-type
    } else { // sinon, on fait un simple appel api
      // On doit remettre le content-type
      request = request.clone({
        setHeaders: {
            'Content-Type': 'application/json'
        }
      })
    }
    if (isLoggedIn && isApiUrl) {
        request = request.clone({
            headers: request.headers.append('Authorization', `Bearer ${currentUser.token}`)
        });
    }

    return next.handle(request);
  }
}
