import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";
import { Produit } from "./produit";

export interface SalonNouveaute{
    id?: number,
    iri?: string,
    jeu: string,
    nom: string,
    description: Text,
    tour: number,
    started: boolean,
    dateLancement: string,
    produits?: Produit[]
}
@Injectable({
    providedIn: 'root'
  })
export class SalonNouveauteAdapter implements Adapter<SalonNouveaute>{
    adapt(data: any): SalonNouveaute{
        return {
            "id" : data['id'],
            "iri" : data['@id'],
            "jeu": data['jeu'],
            "nom": data['nom'],
            "description": data['description'] ,
            "tour": data['tour'] ,
            "started": data['started'] ,
            "dateLancement": data['dateLancement'],
            "produits": data['produits'] as Produit[]
        } as SalonNouveaute;
    }
}