import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CustomDatasource } from '@datasources/custom-datasource';
import { TypeJeu, TypeJeuAdapter } from "@models/type-jeu";
import { TypeJeuService } from 'src/app/services/type-jeu.service';
import { MatTableModule } from '@angular/material/table';
import { merge } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthenticationService } from '@services/authentication.service';
import { UserService } from '@services/user.service';
import { MediaObjectService } from '@services/media-object.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit , AfterViewInit {
  checked = false;
  loading:boolean = false;
  currentId: number = 0;
  errorForbidden: boolean = false;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['type_jeu_nom', 'action'];
  dataSource: CustomDatasource<TypeJeu>;

  default =3;
  closeModal : string;
  constructor(
    private typeJeuService : TypeJeuService,
    private typeJeuAdapter : TypeJeuAdapter,
    private modalService : NgbModal,
    private authService : AuthenticationService,
    private userService: UserService,
    private mediaObjectService : MediaObjectService,
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

/************Datasource setting*************/

  ngOnInit(): void{
    this.dataSource = new CustomDatasource<TypeJeu>(this.typeJeuService, this.typeJeuAdapter);
    this.dataSource.load("",null,this.default);
  }
  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      debounceTime(500),
      tap(() => {
        this.load();
      })
    ).subscribe();
  }

  load(){
    let sortCol = this.sort.active;
    let sortDirection = this.sort.direction;
    let orders = {[sortCol]: sortDirection};

    this.dataSource.load("", this.paginator.pageIndex, this.paginator.pageSize, orders);
  }

/************Modal setting*************/
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  onDelete(id: number){
    this.typeJeuService.deleteType(id).subscribe(
      () => this.dataSource.load()
    );
  }

  contentUrl(typeJeu: TypeJeu){
    return this.mediaObjectService.contentUrl(typeJeu.logo);
  }

}
