import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";
import { Equipe } from "./equipe";

export interface DroitMessagerieRequest{
  id: number,
  source: Equipe,
  destination: Equipe,
}
@Injectable({
  providedIn: 'root'
})
export class DroitMessagerieRequestAdapter implements Adapter<DroitMessagerieRequest>{
  adapt(data: any): DroitMessagerieRequest {
    return {
      "id": data['id'],
      "source": data['source'],
      "destination": data['destination'],
    } as DroitMessagerieRequest;
  }
}
