<div *ngIf="jeu">
  <h2>Liste des équipes du jeu:  {{ jeu.nom }}</h2>
  <div *ngFor="let equipe of jeu.equipes, let equipeIndex = index">
    <mat-list>
      <h3 class="d-flex" (click)="showEquipe(equipe.id)">
        <mat-icon class="mr-4">groups</mat-icon>{{ equipe.nom }}
      </h3>
    </mat-list>
    <mat-divider></mat-divider>
  </div>
</div>
