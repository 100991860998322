<h2>5. {{ 'produits_cyber' | translate }}</h2>

<div class="container">
  <div class="row" *ngIf="jeu">
    <!-- <div class="col-12">
      <form (ngSubmit)="submitCoutItForm()">
        <div class="form-group">
          <label for="coutProduit">Coût en % coûts IT</label>
          <input type="text" id="coutProduit" class="form-control" [formControl]="coutProduitCyber">
        </div>

        <button type="submit" mat-raised-button color="primary" [disabled]="loadingSubmitCout">
          <span class="spinner-border spinner-border-sm" *ngIf="loadingSubmitCout"></span>
          Valider
        </button>
      </form>
      <hr>
    </div> -->
  </div>
  <div class="row" *ngIf="jeu">
    <div class="col-12">
      <span class="spinner-border spinner-border-sm" *ngIf="loadingProduitsCyber"></span>
      <ng-container *ngIf="!loadingProduitsCyber">
        <mat-list>
          <app-list-produit-cyber-item [produit]="produit" *ngFor="let produit of allProduitsCyber; let i = index;" [index]="i"></app-list-produit-cyber-item>
        </mat-list>
      </ng-container>
    </div>
  </div>

    <div class="float-button">
      <button class="mt-3" mat-raised-button color="primary" (click)="suivant()">
        {{ 'suivant' | translate }}
      </button>
    </div>

</div>

