import { Injectable } from "@angular/core";
import { Adapter } from "@models/adapter";
import { Jeu } from "@models/jeu";

export interface ActionJeu {
    "id": number,
    "nom": string,
    "slug": string,
    "type": string,
    "duree": number,
    "started": boolean,
    "finished": boolean,
    "jeu": Jeu,
    "iriJeu": string,
    "tourLancement": number,
    "dateLancement": Date,
}

@Injectable({
    providedIn: "root"
})
export class ActionJeuAdapter implements Adapter<ActionJeu> {
    adapt(data: any): ActionJeu {
        return {
            "id" : data["id"],
            "nom" : data["nom"],
            "slug" : data["slug"],
            "type" : data["type"],
            "duree" : data["duree"],
            "started" : data["started"],
            "finished" : data["finished"],
            "jeu" : (typeof data["jeu"] === "string") ? null : data["jeu"],
            "iriJeu" : (typeof data["jeu"] === "string") ? data["jeu"] : data["jeu"]["@id"],
            "tourLancement" : data["tourLancement"],
            "dateLancement" : new Date(data["dateLancement"]),
        } as ActionJeu;
    }

}
