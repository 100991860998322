import { Component, OnInit, Input } from '@angular/core';
import { Joueur } from '@models/joueur';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-joueur-item',
  templateUrl: './list-joueur-item.component.html',
  styleUrls: ['./list-joueur-item.component.scss']
})
export class ListJoueurItemComponent implements OnInit {
  @Input() joueur: Joueur;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  editJoueur(){
    this.router.navigate([`/joueur/${this.joueur.id}/edit`], { queryParams: { returnUrl: this.router.url}})
  }
}
