import { Equipe, EquipeAdapter } from './equipe';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Jeu } from './jeu';
import { User } from './user';
import { Joueur } from './joueur';
import { Room } from './room';

export interface Message{
  id?: number,
  text: string,
  jeu?: Jeu,
  user?: User,
  joueur?: Joueur,
  room?: Room,
  messageSysteme?: boolean,
  createdAt?: Date
}

@Injectable({
  providedIn: 'root'
})
export class MessageAdapter implements Adapter<Message>{
  adapt(data: any) {
    return {
      'id': data['id'],
      'text': data['text'],
      'jeu': data['jeu'] as Jeu,
      'user': data['user'] as User,
      'joueur': data['joueur'] as Joueur,
      'room': data['room'] ? data['room'] as Room : null,
      'messageSysteme': data['messageSysteme'],
      'createdAt': new Date(data['createdAt'])
    } as Message;

  }


  adaptCustom(data: any){
    return {
      'id': data['id'],
      'text': data['text'],
      'joueur': data['joueur'] as Joueur,
      'room': data['room'] ? data['room'] as Room : null,
      'messageSysteme': data['messageSysteme'],
      'createdAt': new Date(data['createdAt'])
    } as Message;
  }


}
