import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProduitListComponent } from './produit-list/produit-list.component';
import { ProduitRouting } from './produit-routing.module'
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ProduitCreateComponent } from './produit-create/produit-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProduitModificationComponent } from './produit-modification/produit-modification.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@app/app.module';
import { HttpClient } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    ProduitListComponent,
    ProduitCreateComponent,
    ProduitModificationComponent
  ],
  imports: [
    CommonModule,
    ProduitRouting,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      defaultLanguage: 'fr',
    })
  ]
})
export class ProduitModule { }
