<ng-container *ngIf="jeu && jeu.started">
  {{ 'cannot_update_jeu_anymore' | translate }}
</ng-container>
<ng-container *ngIf="jeu && !jeu.started">

  <h2>1. {{ 'creation_titre' | translate }}</h2>

  <div>
    <form (ngSubmit)="onSubmit()" [formGroup]="jeuForm">
      <div class="row">
        <div class="col-md-2">
        </div>
        <div class="col-md-8">
          <div class="form-group" *ngIf="!this.idJeu">
          <label>{{ 'creation_cloner_jeu' | translate }}</label>
          <span *ngIf="loadingAnciensJeux" class="spinner-border spinner-border-sm"></span>
          <ng-container *ngIf="!loadingAnciensJeux">
            <mat-select [formControl]="jeuACloner">
              <mat-option></mat-option>
              <mat-option [value]="jeu.id" *ngFor="let jeu of anciensJeux">{{ jeu.nom }}</mat-option>
            </mat-select>
          </ng-container>
          <button mat-raised-button color="primary" (click)="clonerJeu()" type="button" [disabled]="loadingCloner">
            <span class="spinner-border spinner-border-sm" *ngIf="loadingCloner"></span>
            {{ 'creation_cloner' | translate }}
          </button>
        </div>
        <div class="form-group">
          <label>{{ 'creation_nom_jeu' | translate }}:</label>
          <input type="text" class="form-control" formControlName="nom">
        </div>
        <div class="form-group">
          <label>{{ 'creation_description' | translate }}:</label>
          <textarea spellcheck="false" class="form-control" rows=5 formControlName="description"></textarea>
        </div>

        <!-- <div class="form-group">
          <label>Type de jeu associé</label><br>
          <mat-form-field appearance="fill">
            <mat-label>Type de jeu</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let typeJeu of allTypeJeux" [value]="typeJeu.id">
                {{ typeJeu.nom }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <input type="hidden" [value]="allTypeJeux[0].id" formControlName="type"/> -->

        <div class="form-group">
          <label>{{ 'creation_nombre_tours' | translate }}</label>
          <input type="number" class="form-control" formControlName="nbTours">
        </div>

        <div class="form-group">
          <label>{{ 'creation_equivalent_periode' | translate }} </label>
          <input type="text" class="form-control" formControlName="equivalentPeriode">
        </div>

        <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'creation_langue' | translate }}</mat-label>
            <mat-select formControlName="langue">
              <mat-option value="fr">{{ 'francais' | translate }}</mat-option>
              <mat-option value="en">{{ 'anglais' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <h3>{{ 'minutage_phase' | translate }}</h3>
        <div class="row" formGroupName="minutage">
          <div class="col-4">
            <div class="form-group">
              <label>Introduction</label>
              <input type="number" formControlName="introduction">
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>{{ 'phase_annonces' | translate }}</label>
              <input type="number" formControlName="annonces">
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>{{ 'phase_decisions' | translate }}</label>
              <input type="number" formControlName="decisions">
            </div>
          </div><div class="col-4">
            <div class="form-group">
              <label>{{ 'pitch_entreprise' | translate }}</label>
              <input type="number" formControlName="pitchEntreprise">
            </div>
          </div><div class="col-4">
            <div class="form-group">
              <label>{{ 'pitch_client' | translate }}</label>
              <input type="number" formControlName="pitchClient">
            </div>
          </div><div class="col-4">
            <div class="form-group">
              <label>{{ 'pitch_etat' | translate }}</label>
              <input type="number" formControlName="pitchEtat">
            </div>
          </div><div class="col-4">
            <div class="form-group">
              <label>Vote</label>
              <input type="number" formControlName="votes">
            </div>
          </div><div class="col-4">
            <div class="form-group">
              <label>{{ 'layout_resultat' | translate }}</label>
              <input type="number" formControlName="resultats">
            </div>
          </div>
        </div>

        <div class="float-button">
        <button type="submit" mat-raised-button color="primary" [disabled]="jeuForm.invalid || isSubmitting">
          <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
          {{ 'enregistrer' | translate }}
        </button>
      </div>

      </div>
    </div>
  </form>
</div>

</ng-container>
