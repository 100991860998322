import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeEquipe } from '@models/type-equipe';
import { TypeJeu } from '@models/type-jeu';
import { TypeEquipeService } from '@services/type-equipe.service';
import { MediaObjectService } from '@services/media-object.service';
import { TypeJeuService } from '@services/type-jeu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-form',
  templateUrl: './update-form.component.html',
  styleUrls: ['./update-form.component.scss']
})
export class UpdateFormComponent implements OnInit, AfterViewInit {
  public typeJeuForm : FormGroup;

  typeJeu$ : Observable<TypeJeu>;
  typeEquipeAll$: Observable<TypeEquipe[]>;
  typeEquipeListChecked : any;
  public typeJeu : TypeJeu;
  typeEquipeAll: TypeEquipe[];
  id :number;
  photoIsUploaded : boolean = false;
  photoTempUrl: SafeResourceUrl;
  values : TypeJeu;
  isSubmitting = false;

  constructor(
    private formBuilder: FormBuilder,
    private typeJeuService : TypeJeuService,
    private router: Router,
    private route : ActivatedRoute ,
    private typeEquipeService: TypeEquipeService,
    private mediaObjectService : MediaObjectService,
    private sanitizer : DomSanitizer,
  ) { }



  ngOnInit(): void {
    this.id =  +this.route.snapshot.paramMap.get('id');
    this.typeJeu$ = this.typeJeuService.getTypeJeu(this.id);
    this.typeEquipeAll$ = this.typeEquipeService.getAll();

    this.typeEquipeAll$.subscribe((typeequipe)=>{
      this.typeEquipeAll = typeequipe;
      this.typeJeu$.subscribe((typejeu)=>{
        this.typeJeu = typejeu;

        const value = this.typeJeu.typeEquipes

        .map((typeEquipe)=>
        {
          return typeEquipe["@id"];
        });
        this.typeJeuForm.patchValue({
          selectedEquipes : value
        });
      })
    });
    this.initForm();
  }
  ngAfterViewInit(): void {
  }

 initForm(){
    this.typeJeuForm = this.formBuilder.group(
      {
        nom : ['', Validators.required],
        description : [''],
        nbTours : ['', Validators.required],
        duree : ['', Validators.required],
        selectedEquipes : [''],
        logoTypeJeu : [''],
        logoSource : null,
      }
    );
 }

  transformHourToMin(hour :number): number{
    return  hour * 60;
  }

  onLogoSelect(event){
    const files = event.target.files;
    if (files.length>0){
      this.photoIsUploaded = true;
      this.photoTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
      this.typeJeuForm.patchValue({
        logoSource : files[0]
      });

    }
  }
 onSaveTypeJeu(){
   const formValue = this.typeJeuForm.value;
   const id = this.typeJeu.id;
   this.values = formValue as TypeJeu;
   this.values.duree = this.transformHourToMin(formValue['duree']);
   this.values.typeEquipes = formValue['selectedEquipes'];
  this.isSubmitting = true;

   if(this.typeJeuForm.get('logoSource').value==null){
    this.typeJeuService.update(this.values, id).subscribe(
      (data)=>{
         this.router.navigate(['/type-jeu'])
      },
      (error)=>null,
      ()=>{
        this.isSubmitting = false;
      }
     );
   }else{
     const formdata = new FormData();
     formdata.append('file', this.typeJeuForm.get('logoSource').value);
     this.typeJeuService.uploadFormDataFile(formdata).subscribe(
       (resultat)=>{
        this.values.logo = resultat['@id'];
         this.typeJeuService.update(this.values, id).subscribe(
            (data)=>{
              this.router.navigate(['/type-jeu'])
            },
            (error)=>null,
            ()=>{
              this.isSubmitting = false;
            }
         );
       }
     );
   }

 }

 contentUrl(logo){
   if (this.photoIsUploaded) return this.photoTempUrl;
  else return this.mediaObjectService.contentUrl(logo);
}

}
