import { Component, OnInit, Input } from '@angular/core';
import { Equipe } from '@models/equipe';
import { JeuService } from '@services/jeu.service';
import { Jeu } from '@models/jeu';
import { TypeEquipeService } from '@services/type-equipe.service';
import { TypeEquipe } from '@models/type-equipe';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EquipeService } from '@services/equipe.service';

@Component({
  selector: 'app-list-equipe',
  templateUrl: './list-equipe.component.html',
  styleUrls: ['./list-equipe.component.scss']
})
export class ListEquipeComponent implements OnInit {
  equipes: Equipe[] = [];
  @Input() jeu: Jeu;

  currentEquipe: Equipe = {
    'nom': '',
    'id': null,
    'type': null,
    'typeEquipes': null,
    'joueurs': [],
  };

  allTypeEquipes: TypeEquipe[] = [];
  createEquipeModalRef: NgbModalRef;

  constructor(
    private jeuService: JeuService,
    private typeEquipeService: TypeEquipeService,
    private equipeService: EquipeService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getTypeEquipes();
    this.equipes = this.jeu.equipes;
  }


  getTypeEquipes(){
    this.typeEquipeService.getAll().subscribe(typeEquipes => {
      this.allTypeEquipes = typeEquipes;
    });
  }

  triggerModal(content){
    this.createEquipeModalRef = this.modalService.open(content);
  }

  addEquipe(){
    // On recupere le typeEquipe correspondant a l'iriTypeEquipe de currentEquipe
    if(this.currentEquipe.id != 0){
      console.log('currentEquipe', this.currentEquipe);
      this.currentEquipe.typeEquipes = this.allTypeEquipes.find(t => t.id == this.currentEquipe.id);

      this.equipeService.addEquipe(this.currentEquipe, this.jeu.id).subscribe(equipe => {
        this.createEquipeModalRef.close();
        this.equipes.push(equipe);
      });

      this.currentEquipe.nom = "";
      this.currentEquipe.iriTypeEquipe = "";
      this.currentEquipe.typeEquipes = null;
    }
  }

  onRemoveEquipe(idEquipe: number){
    this.equipes = this.equipes.filter(equipe => {
      return equipe.id != idEquipe;
    });
  }
}
