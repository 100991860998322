import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from '@app/type-jeu/list/list.component';
import { CreateComponent } from './create/create.component';
import { UpdateFormComponent } from './update-form/update-form.component';

const routes: Routes = [
  {path:'list', component : ListComponent},
  {path:'create', component : CreateComponent},
  {path:'update/:id', component : UpdateFormComponent},
  { path: '', redirectTo: 'list', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TypeJeuRoutingModule { }
