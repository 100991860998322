import { Injectable } from '@angular/core';
import { IListerService } from '@interfaces/lister.service.interface';
import { User, UserAdapter } from '@models/user';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { Lister } from '@services/listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends Lister<User>{

  constructor(http: HttpClient, adapter: UserAdapter) {
    super(http, adapter);
    this.setListUri('api/users');
  }

  toggleActive(bool: boolean, id: number): Observable<User> {
    return this.http.post<any>(`${environment.apiUrl}/custom/api/user/toggle-active/${id}`, {
      "active": bool
    }).pipe(
      map((data: any) => this.adapter.adapt(data)),
    );
  }

  getUser(id: string): Observable<User> {
    const url = `${environment.apiUrl}/api/users/${id}`;
    return this.http.get(url).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      map((data: any) => this.adapter.adapt(data)),
    );
  }

  getFreeUser(): Observable<User[]> {
    const url = `${environment.apiUrl}/api/users/free-user`;
    return this.http.get(url).pipe(
      map((data: any) => data['hydra:member']),
      map((users: any[]) => users.map((user) => this.adapter.adapt(user)))
    );
  }

  deleteUser(id: number) {
    const url = `${environment.apiUrl}/api/users/` + id;
    return this.http.delete<User>(url);
  }

  updateUser(user: User): Observable<User> {
    const url = `${environment.apiUrl}/api/users/` + user.id;
    return this.http.put<User>(url, user);
  }

  log(mess: string) {
  }

  findUsername(name: string): Observable<User[]> {
    //if the username doesn't typed yet
    if (!name.trim()) {
      return of([]);
    }
    return this.http.get<User[]>(`${environment.apiUrl}/api/users/check_name?username=${name}`).pipe(
      map(data => data['hydra:member']),

    );
  }

  getAnimateur(): Observable<User[]> {
    return this.http.get(`${environment.apiUrl}/api/users/animateurs`).pipe(
      map((data: any) => { return data["hydra:member"]}),
      map((users: any[]) => users.map( user => this.adapter.adapt(user)))
    );
  }

  getAnimateurList (): Observable<User[]> {
    const url = `${environment.apiUrl}/api/users/animateur_list`;
    return this.http.get<User[]>(url).pipe(
      tap((data: any[]) => {
        for(var i=0; i<data.length; i++){
          this.adapter.adapt(data[i]);
        }
      }),
    );
  }

  getAnimateursJeu(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/user/animateurs-jeu/${idJeu}`;

    return this.http.get<User[]>(url).pipe(
      map((data: any) => data.map(x => this.adapter.adapt(x)))
    );
  }

  playJeu(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/user/playJeu`;
    const data = JSON.stringify({
      'jeu': idJeu
    });
    return this.http.post(url, data);
  }

  /**
   * Request for resetting the password of idUser to password.
   * The token is a string to verify that it is the user which is really updating the password. It is sent by email
   */
  resetPassword(idUser: number, password: string, token: string){
    const url = `${environment.apiUrl}/reset-password/submitReset/${idUser}`;

    return this.http.post(url, {
      'token': token,
      'password': password
    });
  }

  sendResetPasswordRequest(username: string){
    const url = `${environment.apiUrl}/reset-password/send-request`;

    return this.http.post(url, {
      'username': username
    });
  }

  logout(){
    const url = `${environment.apiUrl}/custom/api/user/logout`;

    return this.http.get(url);
  }
}
