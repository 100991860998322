import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentComponent } from './student/student.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignupComponent } from './signup/signup.component';
import { AnimOrAdmin, AuthGuard } from '@services/auth-guard.service';
import { ProduitListComponent } from './produit/produit-list/produit-list.component';
import { AnimationsComponent } from './pages/animations/animations.component';
import { JeuAnimationComponent } from './pages/jeu-animation/jeu-animation.component';
import { BreakingNewComponent } from './pages/jeu-animation/breaking-new/breaking-new.component';
import { EquipeListComponent } from '@app/pages/jeu-animation/equipe/equipe-list/equipe-list.component';
import { EquipeShowComponent } from './pages/jeu-animation/equipe/equipe-show/equipe-show.component';
import { EquipeEditComponent } from './pages/jeu-animation/equipe/equipe-edit/equipe-edit.component';
import { SalonNouveauteComponent } from './pages/jeu-animation/salon-nouveaute/salon-nouveaute.component';
import { JoueurListComponent } from './pages/jeu-animation/joueur/joueur-list/joueur-list.component';
import { JoueurShowComponent } from './pages/jeu-animation/joueur/joueur-show/joueur-show.component';
import { JoueurEditComponent } from './pages/jeu-animation/joueur/joueur-edit/joueur-edit.component';
import { LayoutJoueurComponent } from './joueur/layout-joueur/layout-joueur.component';
import { TimelinePageComponent } from './pages/timeline-page/timeline-page.component';
import { PageListJeuUserComponent } from './joueur/page-list-jeu-user/page-list-jeu-user.component';
import { DashboardAnimateurComponent } from './pages/animateur/dashboard-animateur/dashboard-animateur.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  // { path: 'students', component: StudentComponent},
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard,AnimOrAdmin],
    children: [
      {
        path: 'users',
        loadChildren: () => import('@app/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'ressource',
        loadChildren: () => import('@app/ressource/ressource.module').then(m => m.RessourceModule)
      },
      {
        path: 'type-equipes',
        loadChildren: () => import('@app/type-equipe/type-equipe.module').then(m => m.TypeEquipeModule)
      },
      {
        path: 'students',
        component: StudentComponent
      },
      {
        path: 'messagerie',
        loadChildren: () => import('@app/messagerie/messagerie.module').then(m => m.MessagerieModule)
      },
      {
        path: 'type-jeu',
        loadChildren: () => import('@app/type-jeu/type-jeu.module').then(m => m.TypeJeuModule)
      },
      {
        path: 'jeux',
        loadChildren: () => import('@app/jeu/jeu.module').then(m => m.JeuModule)
      },
      {
        path: 'type-joueur',
        loadChildren: () => import('@app/type-joueur/type-joueur.module').then(m => m.TypeJoueurModule)
      },
      {
        path: 'produits',
        loadChildren: () => import('@app/produit/produit.module').then(m => m.ProduitModule)
      },
      // {
      //   path: 'joueur',
      //   loadChildren: () => import('@app/joueur/joueur.module').then(m => m.JoueurModule)
      // },
      {
        path: 'animations',
        component: AnimationsComponent ,
        canActivate: [AnimOrAdmin]
      },
      {
        path: 'breaking-new/:idJeu',
        component: BreakingNewComponent
      },
      {
        path: 'animation/:idJeu',
        component: JeuAnimationComponent
      },
      {
        path: 'equipe/:idJeu/list',
        component: EquipeListComponent
      },
      {
        path: 'equipe/:idEquipe/show',
        component: EquipeShowComponent
      },
      {
        path: 'equipe/:idEquipe/edit',
        component: EquipeEditComponent
      },
      {
        path: 'joueur/:idJeu/list',
        component: JoueurListComponent
      },
      {
        path: 'joueur/:idJoueur/show',
        component: JoueurShowComponent
      },
      {
        path: 'joueur/:idJoueur/edit',
        component: JoueurEditComponent
      },
      {
        path: '',
        redirectTo: 'animations', pathMatch: 'full'
      },
      {
        path: 'salon-nouveaute/:idJeu',
        component: SalonNouveauteComponent
      },
      {
        path: 'timeline/:idJeu',
        component: TimelinePageComponent
      }
    ]
  },
  {
    path: 'layout',
    component: LayoutJoueurComponent,
    children: [
      {
        path: 'joueur',
        loadChildren: () => import('@app/joueur/joueur.module').then(m => m.JoueurModule)
      },
      {
        path:'',
        redirectTo: 'joueur', pathMatch: 'full'
      }
    ]
  },
  {
    path: 'list-jeux-user',
    component: PageListJeuUserComponent,
  },
  {
    path: 'dashboard-animateur/:idJeu',
    component: DashboardAnimateurComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
