import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environments/environment';

@Pipe({
  name: 'contentUrl'
})
export class ContentUrlPipe implements PipeTransform {

  transform(value: string): string {
    return `${environment.apiUrl}/${value}`;
  }

}
