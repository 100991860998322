import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SituationFinanciereAdapter } from '@models/situation-financiere';
import { Joueur } from '@models/joueur';

@Injectable({
  providedIn: 'root'
})
export class EntrepriseService {

  constructor(
    private httpClient : HttpClient,
    private situationFinanciereAdapter: SituationFinanciereAdapter
  ) { }

  getVoteEntrepriseEtat(idEntreprise: number, tour: number, idJoueur: number = null): Observable<any>{
    const url = `${environment.apiUrl}/custom/api/entreprise/getVoteEtat/?idEntreprise=${idEntreprise}&tour=${tour}&idJoueur=${idJoueur}`;
    return this.httpClient.get(url);
  }

  /**
   *
   * @param data de la forme
   * {
   *    "entreprise" : number (id_entreprise),
   *    "tour" : number (tour du jeu),
   *    "note" : number (note du vote(entre 0 et 10))
   * }
   */
   addVoteEntrepriseEtat(data: any): Observable<any> {
    const url = `${environment.apiUrl}/custom/api/entreprise/addVoteEtat`;
    return this.httpClient.post(url, data);
  }

  getCashFlowStatement(idEntreprise: number): Observable<any[]> {
    const url = `${environment.apiUrl}/custom/api/resultat/main-cashflow-statement-entreprise/${idEntreprise}`;
    return this.httpClient.get<any[]>(url);
  }

  getStockPriceEntreprise(idJeu: number) {
    const url = `${environment.apiUrl}/custom/api/resultat/stock-price-entreprise/${idJeu}`;
    return this.httpClient.get(url);
  }

  getAdoptionProduit(idEntreprise: number){
    const url = `${environment.apiUrl}/custom/api/resultat/adoption-produit/${idEntreprise}`;
    return this.httpClient.get(url);
  }

  getResultatSatisfactionClient(idEntreprise: number){
    const url = `${environment.apiUrl}/custom/api/resultat/satisfaction/${idEntreprise}`;
    return this.httpClient.get(url);
  }

  getRiskManagement(idEntreprise: number){
    const url = `${environment.apiUrl}/custom/api/resultat/risk-management/${idEntreprise}`;
    return this.httpClient.get(url);
  }

  getSituationFinanciere(idEntreprise: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/entreprise/get-situation/${idEntreprise}/${tour}`;
    return this.httpClient.get(url).pipe(
      map((data: any) => this.situationFinanciereAdapter.adapt(data))
    );
  }

  getDataLayoutEntreprise(idEntreprise: number){
    const url = `${environment.apiUrl}/custom/api/entreprise/get-data-layout-entreprise/${idEntreprise}`;
    return this.httpClient.get(url);
  }

  sendVirementBatch(virements: any[]){
    const url = `${environment.apiUrl}/custom/api/equipe/send-virement-batch`;

    console.log('virements', virements);
    // Ne pas considérer les champs vides
    virements = virements.filter(virement => {
      return virement.montant;
    });

    console.log('virements just before send', virements);
    return this.httpClient.post(url, virements);
  }

  getInfoEntreprise(idEntreprise: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/entreprise/get-info-entreprise/${idEntreprise}/${tour}`;

    return this.httpClient.get(url);
  }
}
