<h2> <label for=""> Fiche de Joueur </label></h2>
<div *ngIf="joueur">
    <div class="form-group">
        <h3> <label>Type Joueur :</label>  {{joueur.type['nom']}}</h3>
    </div>
    <div class="form-group">
        <h3> <label>Nom Joueur :</label>   <span *ngIf="joueur.user">{{joueur.user.username}}</span></h3>
    </div>
    <div class="form-group">
        <h3> <label>Nom Equipe :</label>  {{joueur.equipe.nom}}</h3>
    </div>
    <div class="form-group">
        <h3> <label>Objectif Prive :</label> {{joueur.objectifPrive}}</h3>
    </div>
    <div class="form-group">
        <h3> <label>Objectif Public :</label>{{joueur.objectifPublic}}</h3>
    </div>
    <button class="button_modify"  mat-raised-button color="primary"><a [routerLink]="goToUpdate(joueur.id)">Modifier</a></button>

</div>
