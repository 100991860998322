import { MediaObject, MediaObjectAdapter } from './../models/media-object';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MediaObjectService {
  //
  constructor(private httpClient: HttpClient, private adapter: MediaObjectAdapter) { }

  createMediaObject(formData: FormData) {
    console.log(formData.get('file'));
    return this.httpClient.post<any>(
      `${environment.apiUrl}/api/media_objects`,
      formData,
      { headers: { 'Content-Type': '' } }
    );
  }

  getContentUrl(id: string): Observable<MediaObject>{
    const url = `${environment.apiUrl}/api/media_objects/${id}`;
    return this.httpClient.get(url).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }

  contentUrl(mediaObject) {
    var contentUri = '/media/undraw_profile_pic_ic5t.png';
    if (mediaObject && mediaObject.contentUrl) {
      contentUri = mediaObject.contentUrl;
    }

    return `${environment.apiUrl}${contentUri}`;
  }

  
}
