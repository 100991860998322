import { Component, OnInit, Sanitizer } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeEquipe } from '@models/type-equipe';
import { TypeJeu } from '@models/type-jeu';
import { TypeEquipeService } from '@services/type-equipe.service';
import { MediaObjectService } from '@services/media-object.service';
import { TypeJeuService } from '@services/type-jeu.service';
import { Observable } from 'rxjs';
import { map, min } from 'rxjs/operators';
import { SelectItem, PrimeNGConfig } from "primeng/api";
import {MultiSelectModule} from 'primeng/multiselect';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

    typeJeuForm : FormGroup;
    typeEquipeAll$: Observable<TypeEquipe[]>;
    public typeEquipeAll: TypeEquipe[];
    typeJeu : TypeJeu;
    photoIsUploaded : boolean = false;
    photoTempUrl : SafeResourceUrl;
    isSubmitting = false;

    constructor(
      private formBuilder: FormBuilder,
      private typeJeuService : TypeJeuService,
      private typeEquipeService: TypeEquipeService,
      private router: Router,
      private route : ActivatedRoute ,
      private mediaObjectService : MediaObjectService,
      private sanitizer : DomSanitizer,
    ) { }

    ngOnInit(): void {
        this.typeEquipeAll$ = this.typeEquipeService.getAll();
        this.typeEquipeAll$.subscribe((typeEquipeValue)=>{
            this.typeEquipeAll = typeEquipeValue;
        })
        this.initForm();
    }

    initForm(){
      this.typeJeuForm = this.formBuilder.group(
        {
          nom : ['', Validators.required],
          description : [''],
          nbTours : ['', Validators.required],
          duree : ['', Validators.required],
          selectedEquipes : [''],
          logoTypeJeu : [''],
          logoSource: null
        }
      );
   }

   onLogoSelect(event){
      const files = event.target.files;
      if(files.length>0){
       this.photoIsUploaded = true;
       this.photoTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
       this.typeJeuForm.patchValue({
          logoSource : files[0]
       });
      }
  }

   transformHourToMin(hour :number): number{
     return  hour * 60;
   }

   onCreateTypeJeu(){
    this.isSubmitting = true;
     const formValue = this.typeJeuForm.value;
     this.typeJeu = formValue as TypeJeu;
     this.typeJeu.duree = this.transformHourToMin(formValue['duree']);
/*************si il n'a pas choisi de logo*********************/
      if(this.typeJeuForm.get('logoSource').value ===null){
        this.typeJeuService.create(this.typeJeu).subscribe(
          (data)=>{
             this.router.navigate(['/type-jeu'])
          },
          (error)=>null,
          ()=>{
            this.isSubmitting = false;
          }
         );
      }
/**************S'il a importer un logo  ************/
      else{
        const formData = new FormData();
        formData.append('file', this.typeJeuForm.get('logoSource').value);
        //enregistrer le formData dans mediaObject
        this.typeJeuService.uploadFormDataFile(formData).subscribe(
          (res)=>{
            this.typeJeu.logo = res['@id'];
        //enregistrer le nouveau typeJeu avec un logo
            this.typeJeuService.create(this.typeJeu).subscribe(
              (data)=>{
                this.router.navigate(['/type-jeu'])
             },
             (error)=>null,
             ()=>{
               this.isSubmitting = false;
             }
            )
          }
        );
      }
   }
   contentUrl(){
     if (this.photoIsUploaded) return this.photoTempUrl;
     else return this.sanitizer.bypassSecurityTrustUrl(`${environment.apiUrl}/media/undraw_profile_pic_ic5t.png`);
   }

}
