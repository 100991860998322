<div>

  <form [formGroup]="debriefCommentForm" (ngSubmit)="addDebriefComment()">
    <div class="form-group">
      <textarea spellcheck="false" formControlName="debriefComment" class="form-control"></textarea>
    </div>
    <button type="submit" mat-raised-button color="primary" [disabled]="loadingAddComment">
      <app-button-spinner [loading]="loadingAddComment"></app-button-spinner>
      {{ 'layout_commenter' | translate }}
    </button>
  </form>
</div>
