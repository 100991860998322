
<div *ngFor="let breakingNew of breakingNews">
  <div class="card">
    <div class="card-header">
      <div class="d-flex">
        <div class="p-2"><h4>{{breakingNew.nom}}</h4></div>
        <div class="ml-auto p-2">
          <button class="button-fichier" (click)="triggerModal(modalData2)">Modifier</button>
          <button class="button-fichier" (click)="onStart(breakingNew)">Lancer</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <p class="card-text">{{breakingNew.description}}</p>
      <div *ngIf="breakingNew.fichier">
        <a href="{{environmentToLink + breakingNew.fichier.contentUrl}}" class="button-fichier" [download]>
          Télécharger fichier
        </a>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex">
        <div class="p-2"><h5 *ngIf="breakingNew.started" >Date de lancement: {{breakingNew.dateLancement}}</h5></div>
        <div class="ml-auto p-2"><h5>Round d'apparition: {{breakingNew.tourApparition}}</h5></div>
      </div>
    </div>
  </div>

  <ng-template #modalData2 let-modal>
    <form [formGroup] = "editForm" (ngSubmit)="onUpdate()">
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">Modification breaking news</h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <input type="text" class="form-control" id="inputID" formControlName="id" [ngModel]="breakingNew.id" hidden>
        <div class="form-group">
          <label for="inputNom">Nom:</label>
          <input type="text" class="form-control" id="inputNom" formControlName="nom" [ngModel]="breakingNew.nom" [required]>
        </div>
        <div class="form-group">
          <label for="inputDescription">Description:</label>
          <textarea spellcheck="false" id="inputDescription" class="form-control" [ngModel]="breakingNew.description" formControlName="description"></textarea>
        </div>
        <div class="form-group">
          <label for="inputTourApparition">Tour d'apparition:</label>
          <input type="number" class="form-control" id="inputTourApparition" formControlName="tourApparition" [ngModel]="breakingNew.tourApparition" [required]>
        </div>
        <div class="form-group">
          <label for="inputFile">Fichier:</label>
          <input type="file" id="inputFile" formControlName="fichier" (change)=onFileSelect1($event)>
        </div>

    </div>
    <div class="modal-footer">
      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">{{ 'fermer' | translate }}</button>
      <button type="submit" mat-raised-button color="primary">
        <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
            Modifier breaking new
      </button>
    </div>
  </form>
  </ng-template>
</div>

<button type="button" mat-raised-button color="primary" (click)="triggerModal(modalData)" id="Ajouter">Ajouter breaking news</button>

<ng-template #modalData let-modal>
  <form [formGroup] = "addForm" (ngSubmit)="onAdd()">
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">Ajout breaking news</h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
          <label for="inputNom">Nom:</label>
          <input type="text" class="form-control" id="inputNom" formControlName="nom" [required]>
        </div>
        <div class="form-group">
          <label for="inputDescription">Description:</label>
          <textarea spellcheck="false" id="inputDescription" class="form-control" formControlName="description"></textarea>
        </div>
        <div class="form-group">
          <label for="inputTourApparition">Tour d'apparition prévu:</label>
          <input type="number" class="form-control" id="inputTourApparition" formControlName="tourApparition" [required]>
        </div>
        <div class="form-group">
          <label for="inputFile">Fichier:</label>
          <input type="file" id="inputFile" formControlName="fichier" (change)=onFileSelect2($event)>
        </div>

    </div>
    <div class="modal-footer">
      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">{{ 'fermer' | translate }}</button>
      <button type="submit" mat-raised-button color="primary">
        <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
            Ajouter
      </button>
    </div>
  </form>
</ng-template>
