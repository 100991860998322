import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ActionJeu } from '@models/action-jeu';

@Component({
  selector: 'app-timer-phase',
  templateUrl: './timer-phase.component.html',
  styleUrls: ['./timer-phase.component.scss']
})
export class TimerPhaseComponent implements OnInit, OnChanges {
  @Input() phase: ActionJeu;
  @ViewChild('timer') timer;

  @Output('endedTime') endedTime = new EventEmitter<any>();


  timerSeconds = 0;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.initTimer();
  }

  ngOnInit(): void {
    this.initTimer();
  }

  initTimer(){
    console.log('phase', this.phase);
    console.log('phase started', this.phase?.started);


    if(this.phase && this.phase.started){

      let dureeSeconds = this.phase.duree * 60;
      console.log('d', dureeSeconds);
      let leftSeconds = Math.max(0, dureeSeconds - this.phase.timePassed);
      console.log('timepassed', this.phase.timePassed);
      console.log(leftSeconds);
      this.timerSeconds = leftSeconds;
    }
  }

  onPhaseFinished(){
    this.endedTime.emit();
  }

}
