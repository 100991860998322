import { Component, OnInit, Input } from '@angular/core';
import { Produit } from '@models/produit';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-produit-cyber-item',
  templateUrl: './list-produit-cyber-item.component.html',
  styleUrls: ['./list-produit-cyber-item.component.scss']
})
export class ListProduitCyberItemComponent implements OnInit {
  @Input() produit: Produit;
  @Input() index: number;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToProduitEdit(id: number){
    this.router.navigate([`/produits/edit/${id}`], { queryParams: { returnUrl: this.router.url}});
  }
}
