import { DroitMessagerieService } from './../../services/droit-messagerie.service';
import { EquipeService } from './../../services/equipe.service';
import { Jeu } from '@models/jeu';
import { JeuService } from '@services/jeu.service';
import { Component, OnInit} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TypeEquipe } from '@models/type-equipe';
import { TypeJeu } from '@models/type-jeu';
import { TypeEquipeService } from '@services/type-equipe.service';
import { TypeJeuService } from '@services/type-jeu.service';
import { Observable } from 'rxjs';
import { Equipe } from '@models/equipe';

@Component({
  selector: 'app-messagerie-config',
  templateUrl: './messagerie-config.component.html',
  styleUrls: ['./messagerie-config.component.scss']
})
export class MessagerieConfigComponent implements OnInit {

    equipesAvailables = [];

    allEquipes = []

    id;
    jeu$: Observable<Jeu>;

    selectedEquipe =  []

    droitMessageries;


    constructor(
      private route:ActivatedRoute,
      private jeuService: JeuService,
      private droitMessagerieService: DroitMessagerieService,
    ) { }

    ngOnInit(){
      this.droitMessagerieService.getAll().subscribe(
        (res)=>{
          this.droitMessageries = res;
        }
      );
      this.id = this.route.snapshot.paramMap.get('idJeu');
      this.jeu$ = this.jeuService.getOne(this.id);
      this.jeu$.subscribe(
        (res)=>{
          this.allEquipes = res.equipes;
          for(let i=0; i<this.allEquipes.length; i++){
            this.equipesAvailables.push([]);
            this.selectedEquipe.push([]);
            for(let j=0; j<this.allEquipes.length; j++){
              if(this.allEquipes[i] != this.allEquipes[j]){
                this.equipesAvailables[i].push(this.allEquipes[j]);
              }
            }
          }
        }
      );
    }

    onSave(index){
      for(let j=0; j<this.selectedEquipe[index].length; j++){
        this.droitMessagerieService.addDroitMessagerie(this.allEquipes[index].id, this.selectedEquipe[index][j].id);
      }
      this.droitMessagerieService.getAll().subscribe(
        (res)=>{
          this.droitMessageries = res;
        }
      );
    }

    onDelete(d){
      this.droitMessagerieService.delete(d.id).subscribe(
        ()=>{
          this.droitMessagerieService.getAll().subscribe(
            (res)=>{
              this.droitMessageries = res;
            }
          );
        }
      );

    }

}
