import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { Produit } from '@models/produit';
import { TypeProduit } from '@models/typeProduit';
import { MediaObjectService } from '@services/media-object.service';
import { ProduitService } from '@services/produit.service';
import { TypeProduitService } from '@services/type-produit.service';

@Component({
  selector: 'app-produit-create',
  templateUrl: './produit-create.component.html',
  styleUrls: ['./produit-create.component.scss']
})
export class ProduitCreateComponent implements OnInit {

  private uploadedPhoto = false;
  private photoTempUrl;
  public condition1: boolean;
  public condition2: boolean;
  public types: TypeProduit[];
  produitForm: FormGroup = this.fb.group({
    "nom": [null, Validators.required],
    "description": [null, Validators.required],
    "cout": [null],
    "espoirGain": [0],
    "niveauRisque": [0],
    "photo": [null],
    "photoProduit": [null],
    "type":[null, Validators.required],
    "photoSource": [null]
  });
  isSubmitting = false;

  returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private produitService: ProduitService,
    private mediaObjectService: MediaObjectService,
    private typeProduitService: TypeProduitService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    this.typeProduitService.getAll().subscribe(
      (allTypeProduits) => {
        this.types = allTypeProduits;
      }
    )
  }

  contentUrl() {
    if (this.uploadedPhoto) return this.photoTempUrl;
    else return this.sanitizer.bypassSecurityTrustUrl(`${environment.apiUrl}/media/anonyme.png`);
  }

  onFileChange(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.photoTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]))
      this.uploadedPhoto = true;
      this.produitForm.patchValue({
        "photoSource": files[0]
      })
    }
  }

  onChangeType(event){
    let selected = event.target.value;
    selected = selected.split(" ", 2);
    if(selected[1]==this.types[0].iri){
      this.condition1=false;
      this.condition2=true;
    }
    else{
      this.condition2=false;
      this.condition1=true;
    }

  }

  onSubmit() {
    if (this.produitForm.valid) {
      this.isSubmitting = true;
      if (this.uploadedPhoto) {
        var formData = new FormData();
        formData.append("file", this.produitForm.get("photoSource").value);
        this.mediaObjectService.createMediaObject(formData).subscribe(
          (mediaOjbect) => {
            this.produitForm.patchValue({"photo": mediaOjbect["@id"]});
            this.produitService.saveProduit(this.produitForm.value).subscribe(
              (produit) => {
                this.navigateToNext();
              },
              (error) => null,
              () => {
                this.isSubmitting = false;
              }
            )
          }
        )
      } else {
        this.produitService.saveProduit(this.produitForm.value as Produit).subscribe(
          (produit) => {
            this.navigateToNext();
          }
        )
      }
    }
  }

  navigateToNext(){
    if(this.returnUrl){
      this.router.navigate([this.returnUrl]);
    }else{
      this.router.navigate(['/produits']);
    }
  }

  annuler(){
    this.navigateToNext();
  }
}
