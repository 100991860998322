import { TypeRessource } from '@models/type-ressource';
import { MediaObjectService } from './../../services/media-object.service';
import { RessourceService } from './../../services/ressource.service';
import { Ressource, RessourceAdapter } from './../../models/ressource';
import { TypeRessourceService } from './../../services/type-ressource.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ressource-edit',
  templateUrl: './ressource-edit.component.html',
  styleUrls: ['./ressource-edit.component.scss']
})
export class RessourceEditComponent implements OnInit {

  typeRessouceAll$: Observable<TypeRessource[]>;
  public typeRessourceAll: TypeRessource[];
  ressourceForm : FormGroup;
  fichierIsUploaded : boolean = false;
  fichierTempUrl : SafeResourceUrl;
  ressource: Ressource;
  isSubmitting = false;
  typeRessource: TypeRessource;
  id : string;
  ressource$: Observable<Ressource>;

  constructor(
    private adapter: RessourceAdapter,
    private formBuilder: FormBuilder,
    private typeRessourceService: TypeRessourceService,
    private ressourceService: RessourceService,
    private sanitizer : DomSanitizer,
    private router: Router,
    private mediaObjectService: MediaObjectService,
    private route:ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.ressource$ = this.ressourceService.getOne(this.id);
    this.typeRessouceAll$ = this.typeRessourceService.getAll();
    this.typeRessouceAll$.subscribe((typeEquipeValue)=>{
      this.typeRessourceAll = typeEquipeValue;
    })
    this.initForm();
  }

  initForm(){
      this.ressourceForm = this.formBuilder.group(
        {
          nom : [null, Validators.required],
          type: [null, Validators.required],
          fichier : [null],
          fichierSource: [null],
        }
      );
   }

  contentUrl(){
    if (this.fichierIsUploaded) return this.fichierTempUrl;
    else return this.sanitizer.bypassSecurityTrustUrl(`${environment.apiUrl}/media/undraw_profile_pic_ic5t.png`);
  }

  onFileSelect(event){
    const files = event.target.files;
    if(files.length>0){
     this.fichierIsUploaded = true;
     this.fichierTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
     this.ressourceForm.patchValue({
      fichierSource : files[0]
     });
    }
  }

  onUpdate(){
    if (this.ressourceForm.valid) {
      this.isSubmitting = true;
      this.ressource = this.ressourceForm.value as Ressource;
      this.ressource.id = this.id;
      
      if (this.ressourceForm.get('fichierSource').value == null) {
        const ressourceNoFile = {
          id:this.id,
          nom:this.ressource.nom,
          type:this.ressource.type.iri
        }
        this.ressourceService.update(ressourceNoFile).subscribe(
          (data: any) => {
            this.router.navigate(['/ressource']);
          },
          (error) => null,
          () => {
            this.isSubmitting = false;
          }
        );
      } else {
        const formData = new FormData();
        formData.append("file", this.ressourceForm.get('fichierSource').value);
        this.mediaObjectService.createMediaObject(formData).subscribe(
          (res: any) => {
            this.ressource.fichier = res["@id"];
            this.ressourceService.update(this.ressource).subscribe(
              (data: any) => {
                this.router.navigate(['/ressource']);
              },
              (error) => null,
              () => {
                this.isSubmitting = false;
              }
            );
          }
        );
      }
    }
  }

}

