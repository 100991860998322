import { createReducer, on } from "@ngrx/store";
import { initialState, AppState } from "../states/app.state";
import { Action } from "@ngrx/store";
import * as AppActions from '../actions/app.actions';

const appReducer = createReducer(
  initialState,
  on(AppActions.selectAppJeuAction, onSelectAppJeuAction),
  on(AppActions.selectAppJoueurAction, onSelectAppJoueurAction),
  on(AppActions.initAppHistoriquesAction, onInitHistoriquesAction),
  on(AppActions.addAppHistoriqueAction, onAddAppHistoriqueAction),
  on(AppActions.initAppDebriefElementAction, onInitAppDebriefElementAction),
  on(AppActions.addAppDebriefElementAction, onAddAppDebriefElementAction),
  on(AppActions.initAppBudgetAction, onInitAppBudgetAction),
  on(AppActions.appAddDepensesAction, onAppAddDepensesAction),
  on(AppActions.appRemoveDepensesAction, onAppRemoveDepensesAction),
  on(AppActions.appSetLigneDepenseAction, onAppSetLigneDepenseAction),
  on(AppActions.loadResultatAction, onLoadResultatAction),
  on(AppActions.loadDecisionsAction, onLoadDecisionsAction),
  on(AppActions.startPitchAction, onStartPitchAction),
  on(AppActions.loadVoteAction, onLoadVoteAction),
  on(AppActions.loadSondageAction, onLoadSondageAction),
  on(AppActions.changementVoteAction, onChangementVoteAction),
  on(AppActions.changementNouveauxProduitsAction, onChangementNouveauxProduitsAction),
  on(AppActions.virementAction, onVirement),
  on(AppActions.etapeSuivanteAction, onEtapeSuivanteAction),
  on(AppActions.setUnseenAction, onSetUnseenMessage),
  on(AppActions.setSeenRoomAction, onSetSeenMessage),
);

function onLoadResultatAction(state: AppState, action: Action){
  console.log('load resultat reducer');

  return {...state, loadResultat: state.loadResultat + 1 };
}

function onLoadDecisionsAction(state: AppState, action: Action){
  console.log('load decisions reducer');

  return {...state, loadDecisions: state.loadDecisions + 1 };
}

function onStartPitchAction(state: AppState, action: Action){
  console.log('start pitch reducer');

  return {...state, startPitch: state.startPitch + 1 };
}

function onLoadVoteAction(state: AppState, action: Action){
  console.log('load vote reducer');

  return {...state, loadVote: state.loadVote + 1 };
}

function onLoadSondageAction(state: AppState, action: Action){
  console.log('load sondage action reducer');
  return {...state, loadSondage: state.loadSondage + 1 };
}

function onChangementVoteAction(state: AppState, action: Action){
  console.log('changement vote action reducer');
  return {...state, changementVote: state.changementVote + 1 };
}

function onEtapeSuivanteAction(state: AppState, action: Action){
  console.log('etape suivante action reducer');
  // state.etapeSuivante = state.etapeSuivante + 1;
  // return state;
  return {...state, etapeSuivante: state.etapeSuivante + 1 };
}

function onSetUnseenMessage(state: AppState, action: Action){
  console.log('set unseen message action reducer');
  let unseenRoom = [...state.unseenRooms];
  const roomId = action['roomId'];
  const inRoom = unseenRoom.includes(roomId);
  if(!inRoom){
    unseenRoom.push(roomId);
  }
  console.log(unseenRoom);
  return {...state, unseenRooms: unseenRoom };
}

function onSetSeenMessage(state: AppState, action: Action){
  let unseenRoom = [...state.unseenRooms];
  const roomId = action['roomId'];
  unseenRoom = unseenRoom.filter((room) => {
    return room != roomId;
  });
  return {...state, unseenRooms: unseenRoom };
}

function onChangementNouveauxProduitsAction(state: AppState, action: Action){
  console.log('changement nouveaux produits action reducer');
  return {...state, changementNouveauxProduits: state.changementNouveauxProduits + 1 };
}

function onVirement(state: AppState, action: Action){
  console.log('virement action reducer');
  return {...state, virement: state.virement + 1 };
}
function onSelectAppJeuAction(state: AppState, action: Action){
  // state.jeu = action['jeu'];
  // return state;
  console.log('select app jeu action reducer');
  return {...state, jeu: action['jeu']};
}

function onSelectAppJoueurAction(state: AppState, action: Action){
  // state.joueur = action['joueur'];
  // return state;
  console.log('select app joueur action reducer');
  return {...state, joueur: action['joueur']};
}

function onInitHistoriquesAction(state: AppState, action: Action){
  console.log('init historiques action reducer');
  // state.historiques = action['historiques'];
  // return state;
  return {...state, historiques: action['historiques']};
}

function onAddAppHistoriqueAction(state: AppState, action: Action){
  console.log('app add historique action reducer');
  console.log(action['historique']);
  var a = {
    ...action['historique'],
    'messageData': JSON.parse(action['historique']['messageData'])
  };
  // action['historique']['messageData'] = JSON.parse(action['historique']['messageData']);
  return {...state, historiques: [...state.historiques, a]}
}

function onInitAppDebriefElementAction(state: AppState, action: Action){
  console.log('init app debrief elements action reducer');
  let debriefElements = [...action['debriefElements']];
  debriefElements.sort((a,b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime());

  return {...state, debriefElements: debriefElements};
}

function onAddAppDebriefElementAction(state: AppState, action: Action){
  console.log('add app debrief element action reducer');
  let newDebriefElements = [...state.debriefElements, action['debriefElement']];
  newDebriefElements.sort((a,b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime());

  console.log('new Debrief elements', newDebriefElements);

  return {...state, debriefElements: newDebriefElements};
}

function onInitAppBudgetAction(state: AppState, action: Action){
  console.log('init app budget action reducer');

  return {...state, budget: action['budget']}
}

function onAppAddDepensesAction(state: AppState, action: Action){
  console.log('add app depenses action reducer');
  return {
    ...state,
    budget: {
      ...state['budget'],
      'depenses': state['budget']['depenses'] + action['depenses']
    }
  };
}


function onAppRemoveDepensesAction(state: AppState, action: Action){
  console.log('app remove depenses action reducer');
  return {
    ...state,
    budget: {
      ...state['budget'],
      'depenses': state['budget']['depenses'] - action['depenses']
    }
  };
}

function onAppSetLigneDepenseAction(state: AppState, action: Action){
  console.log('app set ligne depense action reducer');
  let newState = {
    ...state,
    budget: {
      ...state['budget'],
      'lignesDepenses': {
        ...state['budget']['lignesDepenses'],
      }
    }
  }

  newState['budget']['lignesDepenses'][action['ligne']] = action['depense'];

  console.log('lignesDepenses', newState['budget']['lignesDepenses']);
  // On recalcule les depenses (somme des values de lignesDepenses)
  newState['budget']['depenses'] = Object.values(newState['budget']['lignesDepenses']).reduce((a,b) => (a+ (b as unknown as number)), 0);

  // On recalcule le reste
  newState['budget']['reste'] = newState['budget']['total'] - newState['budget']['depenses'];

  newState['budget']['depenses'] = precise(newState['budget']['depenses']);
  newState['budget']['reste'] = precise(newState['budget']['reste']);
  console.log('newState', newState);

  return newState;
}

/**
 * Retourne la precision en 2 chiffre apres virgule du nombre
 */
function precise(x){
  return parseFloat(Number.parseFloat(x).toPrecision(10));
}

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}
