import { TypeRessource, TypeRessourceAdapter } from './type-ressource';
import { MediaObject, MediaObjectAdapter } from './media-object';
import { Adapter } from '@models/adapter';
import { Injectable } from '@angular/core';

export interface Ressource{
  id: string,
  nom?: string,
  type?: TypeRessource,
  fichier?: MediaObject,
}

@Injectable({
  providedIn: 'root'
})
export class RessourceAdapter implements Adapter<Ressource>{
    constructor(
        private mediaObjectAdpter: MediaObjectAdapter,
        private typeressourceadpter: TypeRessourceAdapter,
    ){}
    adapt(data: any): Ressource{
    const ressource = {
      'id': data['id'],
      'nom': data['nom'],
      'type': data['type']? this.typeressourceadpter.adapt(data['type']) : null,
      'fichier': data['fichier']? this.mediaObjectAdpter.adapt(data['fichier']) : null
    }
    return ressource as Ressource;
  }
  
}