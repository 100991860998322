import { MessagerieConfigComponent } from './messagerie-config/messagerie-config.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FormArray } from '@angular/forms';

import { MessagerieRoutingModule } from './messagerie-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from "@angular/material/checkbox";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RoleGuard } from '../services/auth-guard.service';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [MessagerieConfigComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MessagerieRoutingModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MultiSelectModule,
  ],
  providers: [
    RoleGuard
  ]
})
export class MessagerieModule { }
