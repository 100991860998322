import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { jeuSelectAction } from '@app/core/actions/jeu.actions';

@Component({
  selector: 'app-animations',
  templateUrl: './animations.component.html',
  styleUrls: ['./animations.component.scss']
})
export class AnimationsComponent implements OnInit {

  constructor(
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(jeuSelectAction({id: 0}));
  }

  onSelectedJeu(id: number){


    this.router.navigate(['/animation/' + id]);
  }
}
