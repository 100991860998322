import { NgModule } from "@angular/core";
import { Router, RouterModule, Routes } from "@angular/router";
import { TypeEquipeCreateComponent } from "./type-equipe-create/type-equipe-create.component";
import { TypeEquipeListComponent } from "./type-equipe-list/type-equipe-list.component";
import { TypeEquipeModificationComponent } from "./type-equipe-modification/type-equipe-modification.component";
import { TypeEquipeShowComponent } from "./type-equipe-show/type-equipe-show.component";

const routes: Routes = [
    { path: "show", component: TypeEquipeShowComponent },
    { path: "list", component: TypeEquipeListComponent },
    { path: "create", component: TypeEquipeCreateComponent },
    { path: "edit/:id", component: TypeEquipeModificationComponent },
    { path: "", redirectTo: "list", pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TypeEquipeRouting { }