import { Equipe, EquipeAdapter } from '../models/equipe';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, switchMap, tap } from 'rxjs/operators';
import { Jeu } from '@models/jeu';
import { JoueurService } from './joueur.service';
import { Observable } from 'rxjs';
import { Lister } from './listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class EquipeService extends Lister<Equipe> {

  constructor(protected http: HttpClient, protected adapter: EquipeAdapter, private joueurService: JoueurService,) {
    super(http, adapter);
    this.setListUri('api/equipes');
  }


  getOne(id: number){
    const url = `${environment.apiUrl}/api/equipes/${id}`;

    return this.http.get(url).pipe(
      map((data: any) => this.adapter.adapt(data)),
    );
  }


  update(equipe: any, id: number){
    // const url = `${environment.apiUrl}/api/equipes/${id}`;
    const url = `${environment.apiUrl}/custom/api/equipe/update-equipe/${id}`;

    return this.http.put(url, equipe);
  }

  getFromConnectedUser(): Observable<Equipe> {
    return this.joueurService.getConnectedUser().pipe(
      map(joueur => joueur.id),
      switchMap(id => {
        return this.getOne(id)
      }),
    );
  }

  getDestinatairesVirement(idEquipe: number) {
    const url = `${environment.apiUrl}/custom/api/jeu/get-destinataires-virements/${idEquipe}`;
    return this.http.get(url).pipe();
  }

  getInfoClient(idJoueur: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/equipe/get-info-client/${idJoueur}/${tour}`;

    return this.http.get(url);
  }

  getInfoEtat(idEquipe: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/equipe/get-info-etat/${idEquipe}/${tour}`;

    return this.http.get(url);
  }

  getInfoHacker(idEquipe: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/equipe/get-info-hacker/${idEquipe}/${tour}`;

    return this.http.get(url);
  }

  getSituationFinanciereEtat(idEquipe: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/equipe/get-situation-financiere-etat/${idEquipe}/${tour}`;

    return this.http.get(url);
  }

  getSituationFinanciereHacker(idEquipe: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/equipe/get-situation-financiere-hacker/${idEquipe}/${tour}`;

    return this.http.get(url);
  }

  addEquipe(equipe: Equipe, idJeu: number){
    const url = `${environment.apiUrl}/custom/api/equipe/add-equipe/${idJeu}`;

    return this.http.post(url, equipe);
  }

  loadOtherInformation(idEquipe: number){
    const url = `${environment.apiUrl}/custom/api/equipe/get-other-information-equipe/${idEquipe}`;

    return this.http.get(url);
  }

  removeEquipe(idEquipe: number){
    const url = `${environment.apiUrl}/api/equipes/${idEquipe}`;

    return this.http.delete(url);
  }
}
