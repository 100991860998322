import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JeuRoutingModule } from './jeu-routing.module';
import { JeuListComponent } from './jeu-list/jeu-list.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule, MatButton } from '@angular/material/button';
import { JeuEditComponent } from './jeu-edit/jeu-edit.component';
import { JeuCreateComponent } from './jeu-create/jeu-create.component';
import { MatSelectModule } from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { JeuInfoComponent } from './jeu-info/jeu-info.component';
import { PageJeuExempleComponent } from './page-jeu-exemple/page-jeu-exemple.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { JeuFormulaireComponent } from './jeu-formulaire/jeu-formulaire.component';
import { PageCreationJeuComponent } from './designer/page-creation-jeu/page-creation-jeu.component';
import { GameDesignerComponent } from './designer/game-designer/game-designer.component';
import { PageEditionEquipeComponent } from './designer/page-edition-equipe/page-edition-equipe.component';
import { ListEquipeComponent } from './designer/page-edition-equipe/list-equipe/list-equipe.component';
import { ListEquipeItemComponent } from './designer/page-edition-equipe/list-equipe/list-equipe-item/list-equipe-item.component';
import { EditionParametresComponent } from './designer/edition-parametres/edition-parametres.component';
import { PageEditionJoueursComponent } from './designer/page-edition-joueurs/page-edition-joueurs.component';
import { ListJoueurItemComponent } from './designer/page-edition-joueurs/list-joueur-item/list-joueur-item.component';
import { PageEditionProduitsInnovationComponent } from './designer/page-edition-produits-innovation/page-edition-produits-innovation.component';
import { PageEditionProduitsCyberComponent } from './designer/page-edition-produits-cyber/page-edition-produits-cyber.component';
import { ProduitListItemComponent } from './designer/produit-list-item/produit-list-item.component';
import { EditProduitsSalonComponent } from './designer/page-edition-produits-innovation/edit-produits-salon/edit-produits-salon.component';
import { ListProduitEditItemComponent } from './designer/page-edition-produits-innovation/edit-produits-salon/list-produit-edit-item/list-produit-edit-item.component';
import { PageEditionAnnoncesComponent } from './designer/page-edition-annonces/page-edition-annonces.component';


import { AnnonceJeuConfigurationComponent } from './annonce-jeu-configuration/annonce-jeu-configuration.component';
import { CarouselModule } from 'primeng/carousel';
import { AnnonceJeuEditItemComponent } from './annonce-jeu-edit-item/annonce-jeu-edit-item.component';
import { ContentUrlPipe } from '@app/shared/pipes/content-url.pipe';

import { CreateBreakingNewsComponent } from './designer/create-breaking-news/create-breaking-news.component';
import { CrudBreakingNewsComponent } from './designer/crud-breaking-news/crud-breaking-news.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PageEditionBreakingNewsComponent } from './designer/page-edition-breaking-news/page-edition-breaking-news.component';
import { ListProduitCyberItemComponent } from './designer/page-edition-produits-cyber/list-produit-cyber-item/list-produit-cyber-item.component';
import { TableauListJeuComponent } from './jeu-list/tableau-list-jeu/tableau-list-jeu.component';
import { EditionAnimationComponent } from './edition-animation/edition-animation.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@app/app.module';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    JeuListComponent,
    JeuEditComponent,
    JeuCreateComponent,
    JeuInfoComponent,
    PageJeuExempleComponent,
    JeuFormulaireComponent,
    PageCreationJeuComponent,
    GameDesignerComponent,
    PageEditionEquipeComponent,
    ListEquipeComponent,
    ListEquipeItemComponent,
    EditionParametresComponent,
    PageEditionJoueursComponent,
    ListJoueurItemComponent,
    PageEditionProduitsInnovationComponent,
    PageEditionProduitsCyberComponent,
    ProduitListItemComponent,
    EditProduitsSalonComponent,
    ListProduitEditItemComponent,
    PageEditionAnnoncesComponent,
    AnnonceJeuConfigurationComponent,
    AnnonceJeuEditItemComponent,
    JeuListComponent,
    JeuEditComponent,
    JeuCreateComponent,
    JeuInfoComponent,
    PageJeuExempleComponent,
    JeuFormulaireComponent,
    CreateBreakingNewsComponent,
    CrudBreakingNewsComponent,
    ContentUrlPipe,
    PageEditionBreakingNewsComponent,
    ListProduitCyberItemComponent,
    TableauListJeuComponent,
    EditionAnimationComponent,
  ],
  imports: [
    CommonModule,
    JeuRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatSlideToggleModule,
    TabViewModule,
    AccordionModule,
    MultiSelectModule,
    CarouselModule,
    ModalModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      defaultLanguage: 'fr',
    })
  ],
  exports: [
    JeuListComponent,
    JeuInfoComponent
  ]
})
export class JeuModule { }
