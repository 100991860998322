<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="jeuForm">
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label>Nom du jeu:</label>
          <input type="text" class="form-control" formControlName="nom">
        </div>
        <div class="form-group">
          <label>Description:</label>
          <textarea spellcheck="false" class="form-control" rows=5 formControlName="description"></textarea>
        </div>

        <div class="form-group">
          <label>Type de jeu associé</label><br>
          <mat-form-field appearance="fill">
            <mat-label>Type de jeu</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let typeJeu of allTypeJeux" [value]="typeJeu.iri">
                {{ typeJeu.nom }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group col">
          <label>Cloner à partir d'un jeu exemple</label><br>
          <button type="button" mat-raised-button color="primary" *ngIf="!cloned"(click)="clone()">Cloner</button>
          <mat-form-field appearance="fill" *ngIf="cloned">
            <mat-label>Jeu exemple</mat-label>
            <mat-select formControlName="templateJeu" (selectionChange)="updateTemplateJeu($event)">
              <mat-option *ngFor="let jeu of allTemplateJeux" [value]="jeu.iri">
                {{ jeu.nom }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="form-group">
          <mat-slide-toggle formControlName="isExemple">Définir comme exemple</mat-slide-toggle>
        </div>

        <div class="form-group">
          <label>Equipe:</label><br>
          <p-accordion>
            <p-accordionTab *ngFor="let equipe of equipes, let equipeIndex = index">
              <ng-template pTemplate="header" class="row">
                <div class="col-auto mr-auto">{{equipe.nom}}</div>
                <div class="col-auto" (click)="goToEditEquipe(equipe.id)">
                  <button type="button" mat-raised-button>
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
                <div class="col-auto" (click)="removeEquipe(equipeIndex)"><button type="button" mat-raised-button>
                    <mat-icon>close</mat-icon>
                  </button></div>
              </ng-template>
              <ng-template pTemplate="content">
                <mat-list class="dark">
                  <mat-list-item *ngFor="let typeJoueur of equipe.typeEquipes.typeJoueurs, let i = index" class="row">
                    <div class="col-auto mr-auto">{{ typeJoueur.nom }}</div>
                    <div class="col-auto ml-auto">
                      <button type="button" *ngIf="!equipe.joueurs[i].user" (click)="listUser(i, equipeIndex, listUserModal)" class="mr-auto" mat-raised-button>
                        <mat-icon>add</mat-icon>
                      </button>
                      <button type="button" *ngIf="equipe.joueurs[i].user" class="mr-auto" mat-raised-button>
                        <span>{{equipe.joueurs[i].user.username}}</span>
                        <mat-icon (click)="removeUser(i, equipeIndex)">close</mat-icon>
                      </button>
                    </div>
                  </mat-list-item>
                </mat-list>
              </ng-template>
            </p-accordionTab>
          </p-accordion>

          <button type="button" (click)="triggerModal(createEquipeModal, 'newEquipe')" mat-mini-fab>
            <mat-icon>add</mat-icon>
          </button> Nouvelle Equipe
        </div>

        <div class="form-group">
          <label>Animateurs:</label><br>
          <p-multiSelect [options]="allAnimateurs" formControlName="animateurJeus" defaultLabel="Selectioner un animateur" optionLabel="username" optionValue="iri" display="chip">
          </p-multiSelect>
        </div>

        <div class="form-group">
          <label>Nombre de joueurs</label>
          <input type="number" class="form-control" formControlName="nbJoueurs">
        </div>

        <div class="form-group">
          <label>Debut du Jeu</label>
          <div>
            <input type="date" class="col-md-4" formControlName="debut_date">
            <input type="time" class="col-md-4" formControlName="debut_time">
          </div>
        </div>
        <div class="form-group">
          <label>Nombre de tours</label>
          <input type="number" class="form-control" formControlName="nbTours">
        </div>
        <!--<div class="form-group">
          <label>Jeu Terminé</label>
          <input type="checkbox" formControlName="finished">
        </div> !-->
        <button type="submit" mat-raised-button color="primary" [disabled]="jeuForm.invalid">
          <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
          Enregistrer
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #createEquipeModal let-modal>
  <div class="modal-content">

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Création d'équipe</h4>
      <button type="button" class="close" aria-label="Fermer" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label>Nom de l'équipe</label>
          <input type="text" class="form-control" [(ngModel)]="currentEquipe.nom" name="nom_current_equipe">
        </div>

        <div class="form-group">
          <label>Type d'équipe: </label>

          <mat-form-field appearance="fill">
            <mat-label>Type d'équipe</mat-label>
            <mat-select [(ngModel)]="currentEquipe.iriTypeEquipe" name="iri_type_equipe_current_equipe" class="dark">
              <mat-option *ngFor="let typeEquipe of allTypeEquipes" [value]="typeEquipe.iri">
                {{ typeEquipe.nom }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">Fermer</button>
      <button type="button" mat-raised-button color="primary" (click)="addEquipe()">Valider</button>
    </div>
  </div>
</ng-template>

<ng-template #listUserModal let-modal>
  <div class="modal-content">

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Création d'un joueur</h4>
      <button type="button" class="close" aria-label="Fermer" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label>Joueur réel:</label>
          <mat-select [(ngModel)]="currentJoueur.iriUser" name="iri_user_for_joueur" class="dark">
            <mat-option *ngFor="let user of notSelectedUser(allUsers)" [value]="user.iri">{{ user.username }}</mat-option>
          </mat-select>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">Fermer</button>
      <button type="button" mat-raised-button color="primary" (click)="addUser()">Sélectionner</button>
    </div>
  </div>
  </ng-template>
