import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Jeu } from '@models/jeu';
import { JeuService } from '@services/jeu.service';
import { TypeJeuService } from '@services/type-jeu.service';
import { TypeJeu } from '@models/type-jeu';
import { Equipe } from '@models/equipe';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TypeEquipeService } from '@services/type-equipe.service';
import { TypeEquipe } from '@models/type-equipe';
import { User } from '@models/user';
import { UserService } from '@services/user.service';
import { Joueur } from '@models/joueur';

@Component({
  selector: 'app-jeu-create',
  templateUrl: './jeu-create.component.html',
  styleUrls: ['./jeu-create.component.scss']
})
export class JeuCreateComponent implements OnInit {


  jeuForm : FormGroup;
  jeu : any;

  allTemplateJeux: Jeu[];
  iriTemplate: string = '';

  allTypeJeux: TypeJeu[] = [];
  allTypeEquipes: TypeEquipe[] = [];
  allAnimateurs: User[] = [];
  allUsers = [];

  cloned: boolean = false;
  equipes: Equipe[] = [];
  currentEquipe: Equipe = {
    'nom': '',
    'iriTypeEquipe': '',
    'typeEquipes': null,
    'joueurs': [],
  };
  isSubmitting = false;

  currentJoueurInfo = {
    "index" : null,
    "equipeIndex" : null
  }

  currentJoueur : Joueur = {
    'type': '',
    'isPrincipal': true,
    'iriUser': '',
    'user' : null,
    'equipe': null,
  }

  createEquipeModalRef: NgbModalRef;
  selectUserModalRef: NgbModalRef;

  constructor(
    private jeuService : JeuService,
    private typeJeuService: TypeJeuService,
    private typeEquipeService: TypeEquipeService,
    private userService: UserService,
    private router : Router,
    private formBuilder : FormBuilder,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.getTypeJeux();
    this.getTypeEquipes();
    this.getAnimateur();
    this.getUsers();
    this.getAllTemplateJeux()
  }

  getTypeJeux(){
    this.typeJeuService.getAll().subscribe(typeJeux => {
      this.allTypeJeux = typeJeux;
    });
  }

  getTypeEquipes(){
    this.typeEquipeService.getAll().subscribe(typeEquipes => {
      this.allTypeEquipes = typeEquipes;
    });
  }

  getAnimateur(){
    this.userService.getAnimateur().subscribe(animateurs => {
      this.allAnimateurs = animateurs;
    })
  }

  getUsers(){
    this.userService.getFreeUser().subscribe(users => {
      this.allUsers = users.map((user) => {
        return {
          'iri' : user.iri,
          'isSelected' : false,
          'username' : user.username,
          'email' : user.email
        };
      })

    })
  }

  getAllTemplateJeux(){
    this.jeuService.getOnlyTemplates().subscribe(jeux => {
      this.allTemplateJeux = jeux;
    })
  }

  initForm(){
    this.jeuForm = this.formBuilder.group({
      nom : ['Jeu', Validators.required],
      description : ['Description jeu'],
      nbJoueurs : [5, Validators.required],
      nbTours : [8, Validators.required],
      debut_date : ['2021-07-01', Validators.required],
      debut_time : ['00:00', Validators.required],
      finished : [false],
      animateurJeus : [null],
      typeJeu : [null, Validators.required],
      templateJeu : [null],
      isExemple : [false, Validators.required],
    });
  }

  notSelectedUser(listUsers){
    return listUsers.filter((user) => user.isSelected == false)
  }

  onSubmit(){
    const formValue = this.jeuForm.value;
    let finalForm;
    let jeuValue;
    this.jeu = formValue as Jeu;
    this.jeu.debut = formValue['debut_date'] + formValue['debut_time'];
    this.jeu.finished = formValue['finished'];
    if(this.jeuForm.valid){
      this.isSubmitting = true;
      jeuValue = {...this.jeuForm.value};
      const formValue = this.jeuForm.value;
      jeuValue = {
        'nom': formValue.nom,
        'description': formValue.description,
        'nbJoueurs': formValue.nbJoueurs,
        'nbTours': formValue.nbTours,
        'tour': 0,
        'debut': formValue.debut_date + formValue.debut_time,
        'debutReel': formValue.debut_date + formValue.debut_time,
        'type': formValue.typeJeu,
        'equipes': [],
        'finished': false,
        'started': false,
        'isExemple': formValue.isExemple,
        'animateurJeus': formValue.animateurJeus ? formValue.animateurJeus.map((animateur) => {
          return {
            "user" : animateur
          }
        }) : []
      }
      for(let i = 0; i < this.equipes.length; i++){
        let mappedjoueurs = this.equipes[i].joueurs.filter((joueur) => joueur.user != null);
        jeuValue.equipes[i] = {
          'nom': this.equipes[i].nom,
          'type': this.equipes[i].iriTypeEquipe,
          'isExemple': true,
          'joueurs': mappedjoueurs.map((joueur) => {
            return {
              'user': joueur.iriUser,
              'isPrincipal': joueur.isPrincipal,
              'type': joueur.type
            };
          })
        };
      }
      this.jeuService.create(jeuValue).subscribe(
        (res) => {
          this.isSubmitting = false;
          this.router.navigate(['/jeux']);
        }
      )
    }
  }

  triggerModal(content, type: string){
    if(type == 'newEquipe')
      this.createEquipeModalRef = this.modalService.open(content);
    else if(type == 'selectUser')
      this.selectUserModalRef = this.modalService.open(content);
  }

  listUser(index: number, equipeIndex : number, content){
    this.currentJoueurInfo = {
      "index" : index,
      "equipeIndex" : equipeIndex
    };
    this.triggerModal(content, 'selectUser');
  }

  removeUser(index, equipeIndex){
    for(let i = 0; i < this.allUsers.length; i++){
        if(this.allUsers[i].iri == this.equipes[equipeIndex].joueurs[index].user.iri){
          this.allUsers[i].isSelected = false;
      }
    }
    this.equipes[equipeIndex].joueurs[index].user = null;
  }

  removeEquipe(equipeIndex){
    let arrayJoueur = this.equipes[equipeIndex].joueurs.filter((joueurs) => joueurs.user != null);
    let arrayIriUser = arrayJoueur.map((joueur) => joueur.user.iri);
    for(let i = 0; i < this.allUsers.length; i++){
      if(arrayIriUser.includes(this.allUsers[i].iri)){
        this.allUsers[i].isSelected = false;
      }
    }
    this.equipes.splice(equipeIndex, 1);
  }

  getEmptyJoueur(){
    return {
      // 'type': typeJoueur['@id'],
      'isPrincipal': true,
      'user': null
    } as Joueur;
  }
  addUser(){
    const equipeIndex = this.currentJoueurInfo.equipeIndex;
    const index = this.currentJoueurInfo.index;
    const equipe = this.equipes[equipeIndex];
    this.equipes[equipeIndex].joueurs[index].iriUser = this.currentJoueur.iriUser;

    // Selectionne un joueur dans la liste des users
    for(let i = 0; i < this.allUsers.length; i++){
      if(this.allUsers[i].iri == this.currentJoueur.iriUser){
        this.equipes[equipeIndex].joueurs[index].user = {
          'iri' : this.currentJoueur.iriUser,
          'username' : this.allUsers[i].username
        } as User;
        this.allUsers[i].isSelected = true;
      }
    }
    this.currentJoueur.iriUser = null;
    this.selectUserModalRef.close();
  }

  addEquipe(){
    // On recupere le typeEquipe correspondant a l'iriTypeEquipe de currentEquipe
    if(this.currentEquipe.iriTypeEquipe != ""){
      this.currentEquipe.typeEquipes = this.allTypeEquipes.find(t => t.iri == this.currentEquipe.iriTypeEquipe);
      const totalTypeJoueur = this.currentEquipe.typeEquipes.typeJoueurs.length;
      let allJoueur = this.currentEquipe.typeEquipes.typeJoueurs.map((typeJoueur) => {
        return {
          'type': typeJoueur['@id'],
          'isPrincipal': true,
          'user': null,
          'iriUser': null,
        } as Joueur;
      })
      this.currentEquipe.joueurs = allJoueur;
      this.equipes.push({...this.currentEquipe});
      this.currentEquipe.nom = "";
      this.currentEquipe.iriTypeEquipe = "";
      this.currentEquipe.typeEquipes = null;
      this.createEquipeModalRef.close();
    }
  }

  typeJeuChanged(event){
    let iriTypeJeu = event.value;

    // Load TypeJeu from the database according to the selected TypeJeu
    // Set nbTours as TypeJeu's nbTours

    this.typeJeuService.getTypeJeuByIri(iriTypeJeu).subscribe(data => {
      this.jeuForm.patchValue({
        'nbTours': data.nbTours
      });
    });

  }

  updateTemplateJeu($event){
    const jeu = this.allTemplateJeux.find((jeu) => this.jeuForm.get('templateJeu').value == jeu.iri)
    this.updateFromJeuTemplate(jeu);
    this.cloned = true;
  }

  clone(){
    this.cloned = true;
  }

  updateFromJeuTemplate(jeu: Jeu){
    const datetime = jeu['debut'].split("T", 2);
    const time = datetime[1].slice(0,5);
    this.jeuForm.patchValue({
      nom: jeu['nom'],
      description: jeu['description'],
      typeJeu: jeu['type']['@id'],
      nbJoueurs: jeu['nbJoueurs'],
      nbTours: jeu['nbTours'],
      debut_date: datetime[0],
      debut_time: time,
      finished: jeu['finished'],
    });
    this.jeu = jeu;

    for(let i = 0; i < this.jeu.equipes.length; i++){
      const typeJoueur = this.jeu.equipes[i].joueurs.map((joueur) => {
        return joueur.type['@id'];
      });
      const allTypeJoueur = this.jeu.equipes[i].type.typeJoueurs;
      let joueurs = [];
      for(let j= 0; j < allTypeJoueur.length; j++){
        const indexJoueur = typeJoueur.indexOf(allTypeJoueur[j]['@id']);
        if(indexJoueur == -1){
          joueurs[j] = {
            'type': allTypeJoueur[j]['@id'],
            'isPrincipal': true,
            'iriUser': '',
            'user': null,
            'equipe': this.jeu.equipes[i]['@id']
          } as Joueur;
        }
        else {
          joueurs[j] = this.jeu.equipes[i].joueurs[indexJoueur];
          joueurs[j].type = joueurs[j].type['@id'];
        }
      }
      this.jeu.equipes[i].joueurs = [...joueurs];
      this.jeu.equipes[i].iriTypeEquipe = this.jeu.equipes[i].type['@id'];
      this.jeu.equipes[i].typeEquipes = this.jeu.equipes[i].type;
    }
    this.equipes = this.jeu.equipes;
  }
}
