import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TypeJoueurAdapter, TypeJoueur } from '@models/type-joueur';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lister } from './listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class TypeJoueurService extends Lister<TypeJoueur>{

  constructor(
    protected http: HttpClient,
    protected adapter: TypeJoueurAdapter
  ) {
    super(http, adapter);
    this.setListUri("api/type_joueurs");
   }


  add(typeJoueur: TypeJoueur): Observable<TypeJoueur>{
    const url = `${environment.apiUrl}/api/type_joueurs`;

    return this.http.post(url, typeJoueur).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }

  getOne(id: number): Observable<TypeJoueur>{
    const url = `${environment.apiUrl}/api/type_joueurs/${id}`;

    return this.http.get(url).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }

  update(id: number, typeJoueur: TypeJoueur){
    const url = `${environment.apiUrl}/api/type_joueurs/${id}`;

    return this.http.put(url, typeJoueur).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }

  delete(id: number): Observable<any>{
    const url = `${environment.apiUrl}/api/type_joueurs/${id}`;

    return this.http.delete(url);
  }
}
