import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { Room } from '@models/room';
import { MessagerieService } from '@services/messagerie.service';
import { Joueur } from '@models/joueur';
import { Message } from '@models/message';
import { Store, select } from '@ngrx/store';
import { addMessageAction, initMessagesAction } from '@app/core/actions/message.actions';
import { selectMessages } from '@app/core/selectors/message.selector';
import { environment } from '@environments/environment';
import { Jeu } from '@models/jeu';
import { AuthenticationService } from '@services/authentication.service';
import { setUnseenAction } from '@app/core/actions/app.actions';

@Component({
  selector: 'app-chat-messagerie',
  templateUrl: './chat-messagerie.component.html',
  styleUrls: ['./chat-messagerie.component.scss']
})
export class ChatMessagerieComponent implements OnInit, OnChanges {
  @Input() room: Room;
  @Input() joueur: Joueur;
  @Input() jeu: Jeu;
  @Input() allRooms: boolean = false;
  @Input() readOnly: boolean = false; // if it is readOnly, we don't show the sendMessage form

  @ViewChild('tchatContent') tchatContent: ElementRef;

  typedMessage: string;
  messages: Message[];
  maxScroll = 0;

  loadingMessages: boolean = false;

  constructor(
    private messagerieService: MessagerieService,
    private store: Store,
    private authService: AuthenticationService
  ) {
  }

  ngOnChanges(): void{
    // Get Messages already there
    if(this.allRooms){

      this.getMessages();
    }
    else{
      this.getMessages(this.room);
    }
  }

  get isAnimateur(){
    return this.authService.isAnimateur();
  }

  ngOnInit(): void {
    this.maxScroll = 0;

    // Get Messages already there
    if(this.allRooms){

      this.getMessages();
    }
    else{
      this.getMessages(this.room);
    }
    this.store.pipe(select(selectMessages)).subscribe(messages => {


      if(this.allRooms){
        // Pour l'animateur
        let allMessages = [];
        Object.values(messages).forEach(t => {
          allMessages = allMessages.concat(t);
        });
        allMessages = [...allMessages].sort((m1, m2) => m1.createdAt.getTime() - m2.createdAt.getTime());

        this.messages = allMessages;
        // this.tchatContent.nativeElement.scrollTop = this.tchatContent.nativeElement.scrollHeight;

      }
      else{
        if(messages && messages[this.room.id]){

          this.messages = messages[this.room.id];

          if(this.messages){
            this.messages = [...this.messages].sort((m1, m2) => m2.createdAt.getTime() - m1.createdAt.getTime());
          }

          if(this.tchatContent){
            setTimeout(() => {
              console.log('scroll');
              this.maxScroll = Math.max(this.tchatContent.nativeElement.scrollHeight, this.maxScroll);

              // must be
              // this.tchatContent.nativeElement.scrollTop = this.maxScroll;
              this.tchatContent.nativeElement.scrollTop = 0;
            }, 0);
          }
        }
      }
    });
  }

  getMessages(room?: Room){

    console.log('room is ', room);
    if(!room){
      this.loadingMessages = true;
      this.messagerieService.getAllMessages(this.jeu.id).subscribe(messages => {

        let data = {
          'messages': messages,
          'room': {
            id: 0
          }
        };
        this.loadingMessages = false;
        this.store.dispatch(initMessagesAction(data));
      });
    }else{
      this.loadingMessages = true;
      this.messagerieService.getMessages(room).subscribe(messages => {

        let data = {
          'messages': messages,
          'room': room
        };

        this.store.dispatch(initMessagesAction(data));
        this.loadingMessages = false;
      });
    }
  }

  sendMessage(){
    let message = {
      'text': this.typedMessage,
      'joueur': this.joueur,
      'createdAt': new Date()
    } as Message;

    this.messagerieService.sendMessage(this.typedMessage, this.room, this.joueur).subscribe();
    console.log('dispatch sendMessage');
    this.store.dispatch(addMessageAction({message: message, room: this.room}));
    // this.store.dispatch(setUnseenAction({ roomId: this.room.id }));

    this.typedMessage = "";
  }

  contentUrl(path: string){
    return `${environment.apiUrl}${path}`;
  }


}
