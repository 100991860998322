<div *ngIf="ressource$ | async as ressource">
    <div class="division-right">
        <form [formGroup] = "ressourceForm" (ngSubmit)="onUpdate()">
            <div class="row">
                <div class="col-md-12">
                        <div class="form-group">
                            <label for="nom_ressource">Nom du ressource :</label>
                            <input type="text" [(ngModel)]="ressource.nom" formControlName="nom" class="form-control" id="nom_ressource">
                        </div>
                        <div class="form-group">
                            <label for="inputType">Type</label>
                            <select [(ngModel)]="ressource.type.iri" class="form-control" id="inputType" formControlName="type">
                              <option *ngFor="let typeRessource of typeRessourceAll" [ngValue]="typeRessource.iri">{{ typeRessource.nom }}</option>
                            </select>    
                        </div>
                        <div class="form-group">
                            <label for="fichier_ressource">Fichier :</label><br>
                            <input type="file" id="fichier_ressource" (change)=onFileSelect($event) [ngModelOptions]="{standalone: true}">
                        </div>
                        <button type="submit" mat-raised-button color="primary">
                        <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
                            Modifier ressource
                        </button>
                </div>
            </div>
        </form>
    </div>
</div>
