import { Lister } from '@services/listers/lister.service';
import { Jeu, JeuAdapter } from '@models/jeu';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class JeuLister extends Lister<Jeu>{
  constructor(http: HttpClient, adapter: JeuAdapter){
    super(http, adapter);
    this.setListUri('api/jeus');
  }
}
