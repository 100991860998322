import { BreakingNew } from '@models/breakingNew';
import { JeuService } from './../../../services/jeu.service';
import { BreakingNewService } from './../../../services/breaking-new.service';
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Jeu } from '@models/jeu';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MediaObjectService } from '@services/media-object.service';
import { environment } from '@environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-breaking-new',
  templateUrl: './breaking-new.component.html',
  styleUrls: ['./breaking-new.component.scss']
})
export class BreakingNewComponent implements OnInit {

  environmentToLink: string;
  closeModal: string;
  id : string;
  jeu$: Observable<Jeu>;
  breakingNewToEdit;
  breakingNewToAdd;
  breakingNews: BreakingNew[];
  breakingNewsOnForm: BreakingNew[];
  editForm : FormGroup;
  addForm : FormGroup;
  isSubmitting = false;
  fichierIsUploaded = false;
  fichierTempUrl : SafeResourceUrl;

  constructor(
    private sanitizer : DomSanitizer,
    private mediaObjectService: MediaObjectService,
    private formBuilder: FormBuilder,
    private jeuService: JeuService,
    private modalService: NgbModal,
    private route:ActivatedRoute,
    private breakingNewService: BreakingNewService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.environmentToLink = environment.apiUrl;
    this.id = this.route.snapshot.paramMap.get('idJeu');
    this.load();
    this.initForm();
  }

  load(){
    this.jeu$ = this.jeuService.getOne(this.id);
    this.jeu$.subscribe(
      (res)=>{
        this.breakingNews = res.breakingNews;
      }
    );
  }

  initForm(){
    this.editForm = this.formBuilder.group(
      {
        id: [null],
        nom: [null, Validators.required],
        description: [null],
        tourApparition: [null, Validators.required],
        fichier: [null],
        fichierSource: [null]
      }
    );
    this.addForm = this.formBuilder.group(
      {
        nom: [null, Validators.required],
        description: [null],
        tourApparition: [null, Validators.required],
        fichier: [null],
        fichierSource: [null]
      }
    );
 }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onFileSelect1(event){
    const files = event.target.files;
    if(files.length>0){
     this.fichierIsUploaded = true;
     this.fichierTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
     this.editForm.patchValue({
      fichierSource : files[0]
     });
    }
  }

  onFileSelect2(event){
    const files = event.target.files;
    if(files.length>0){
     this.fichierIsUploaded = true;
     this.fichierTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
     this.addForm.patchValue({
      fichierSource : files[0]
     });
    }
  }

  onUpdate(){
    if (this.editForm.valid) {
      this.isSubmitting = true;
      this.breakingNewToEdit = this.editForm.value as BreakingNew;
      if (this.editForm.get('fichierSource').value == null) {
        const breakingNewUpdate = {
          id:this.breakingNewToEdit.id,
          tourApparition:this.breakingNewToEdit.tourApparition,
          nom:this.breakingNewToEdit.nom,
          description:this.breakingNewToEdit.description,
        }
        this.breakingNewService.update(breakingNewUpdate).subscribe(
          (data: any) => {
            this.load();
          },
          (error) => null,
          () => {
            this.isSubmitting = false;
          }
        );
      } else {
        const formData = new FormData();
        formData.append("file", this.editForm.get('fichierSource').value);
        this.mediaObjectService.createMediaObject(formData).subscribe(
          (res: any) => {
            this.breakingNewToEdit.fichier = res["@id"];
            this.breakingNewService.update(this.breakingNewToEdit).subscribe(
              (data: any) => {
                this.load();
              },
              (error) => null,
              () => {
                this.isSubmitting = false;
              }
            );
          }
        );
      }
    }
  }

  onAdd(){
    if (this.addForm.valid) {
      this.isSubmitting = true;
      if (this.addForm.get('fichierSource').value == null) {
        const breakingNewAdd = {
          tourApparition:this.addForm.value.tourApparition,
          nom:this.addForm.value.nom,
          description:this.addForm.value.description,
          jeu:"/api/jeus/"+this.id,
          started: false,
        }
        this.breakingNewToAdd = breakingNewAdd;
        this.breakingNewService.create(breakingNewAdd).subscribe(
          (data: any) => {
            this.load();
          },
          (error) => null,
          () => {
            this.isSubmitting = false;
          }
        );
      } else {
        const formData = new FormData();
        formData.append("file", this.addForm.get('fichierSource').value);
        this.mediaObjectService.createMediaObject(formData).subscribe(
          (res: any) => {
            const breakingNewAdd2 = {
              tourApparition:this.addForm.value.tourApparition,
              nom:this.addForm.value.nom,
              description:this.addForm.value.description,
              jeu:"/api/jeus/"+this.id,
              started: false,
              fichier: res["@id"],
            };
            this.breakingNewToAdd = breakingNewAdd2;
            this.breakingNewService.create(breakingNewAdd2).subscribe(
              (data: any) => {
                this.load();
              },
              (error) => null,
              () => {
                this.isSubmitting = false;
              }
            );
          }
        );
      }
    }
  }

  onStart(breakingNew){
    const breakingNewStart ={
      id:breakingNew.id,
    }
    this.breakingNewService.update(breakingNewStart).subscribe(
      ()=>{
        this.load();
      }
    );
  }

}
