import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { jeuSelectAction } from '@app/core/actions/jeu.actions';
import { SalonNouveauteService } from '@services/salon-nouveaute.service';
import { Produit } from '@models/produit';
import { SalonNouveaute } from '@models/salon-nouveaute';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { JeuService } from '@services/jeu.service';
import { ProduitService } from '@services/produit.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-salon-nouveaute',
  templateUrl: './salon-nouveaute.component.html',
  styleUrls: ['./salon-nouveaute.component.scss']
})
export class SalonNouveauteComponent implements OnInit {


  idJeu: number;
  closeModal: string;
  currentSalon : SalonNouveaute = {
    'id' : null,
    'jeu': null,
    'nom': null,
    'description': null,
    'tour': null,
    'started': false,
    'dateLancement' : null,
    'produits': []
  };
  public nbTotalTours: number;
  public allProducts: Produit[];
  allSalons: SalonNouveaute[] = [];
  produitIri: string;
  salonId: Array<number>=[];
  private currentRound : number;
  private id;

  public fetchedSalons:SalonNouveaute[];
  createSalonModalRef: NgbModalRef;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private modalService: NgbModal,
    private productService: ProduitService,
    private salonNouveauteService: SalonNouveauteService,
    private router: Router,
    private jeuService:JeuService
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.store.dispatch(jeuSelectAction({ id: this.idJeu}));
    this.getProduct();
    this.getNbToursJeu();
  }

  getProduct(){
    this.productService.getAll().subscribe(
      (allProducts)=>{
        this.allProducts = allProducts;
      }
    );
  }

  getSalonInJeu(){
    this.salonNouveauteService.getSalonByJeu(this.idJeu).subscribe(
      (salon)=>{
        this.fetchedSalons = salon;
        var existedRound = this.fetchedSalons.map(
          (salon)=>salon.tour
        );
        for(var i=0;i<this.nbTotalTours;i++){
          this.currentSalon = {
            'id' : null,
            'jeu': null,
            'nom': null,
            'description': null,
            'tour': null,
            'started': false,
            'dateLancement' : null,
            'produits': []
          };
          var round = i+1;
          var salonFound = this.fetchedSalons.find(salon=> round==salon.tour );

          if(salonFound!=undefined){
			  this.currentSalon = salonFound;
		  }
          this.allSalons.push({...this.currentSalon});
        }
      }
    );
  }

  getNbToursJeu(){
    this.jeuService.getOne(this.idJeu).subscribe(
      (jeu)=>{
        this.nbTotalTours = jeu.nbTours;
        //~ this.nbTotalTours = 5;
        this.getSalonInJeu();
      }
    )
  }


 /************Select Salon setting***************/
  handleSelect(createSalon, round:number){

    this.currentRound = round;
    this.currentSalon = {...this.allSalons[this.currentRound-1]};
    this.triggerModal(createSalon);
 }


  onInsertSalon(){
	 /************Create new Salon setting***************/
    if(this.currentSalon.id == null){

      this.currentSalon.tour = this.currentRound;
      this.currentSalon.jeu = '/api/jeus/'+this.idJeu;

      this.salonNouveauteService.createSalon(this.currentSalon).subscribe(
        (salon:any)=>{
            this.allSalons[this.currentRound-1] = salon;
        }
      );

    }else{
	/************Update salon setting***************/
      const id =  this.currentSalon.id;
      this.salonNouveauteService.updateSalon(this.currentSalon).subscribe(
        (salon:any)=>{
          this.allSalons[this.currentRound-1] = salon;
        }
      );
    }

    this.createSalonModalRef.close();


  }

 /************Launch setting***************/
  handleLaunch(round:number){
	var id = this.allSalons[round-1].id;
    this.salonNouveauteService.startTime(id).subscribe(
      (salon:any)=>
      {
		 this.allSalons[round-1] = salon;
	 }
    );
  }

  /************Delete setting***************/
  handleDelete(round:number){
	var id = this.allSalons[round-1].id;
    this.salonNouveauteService.deleteSalon(id).subscribe(()=>{
		this.allSalons[round-1] =  {
		  'id' : null,
		  'jeu': null,
		  'nom': null,
		  'description': null,
		  'tour': null,
		  'started': false,
		  'dateLancement' : null,
		  'produits': []
		}
	});

  }


  /************Modal setting*************/
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      // this.deleteCurrent();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


 triggerModal(content){
	  this.createSalonModalRef = this.modalService.open(content);
  }

  /***********************************/
}
