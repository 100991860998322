<h2>2. {{ 'edition_equipe' | translate }}</h2>
<ng-container *ngIf="jeu">
  <div class="w-separator-form">
  <app-edition-parametres [jeu]="jeu"></app-edition-parametres>
  <app-list-equipe [jeu]="jeu"></app-list-equipe>

  <div class="float-button">
  <button mat-raised-button color="primary" (click)="suivant()" [disabled]="loadingInitConfig">
    <span class="spinner-border spinner-border-sm" *ngIf="loadingInitConfig"></span>
    {{ 'valider' | translate }}
  </button>
  </div>

  </div>
</ng-container>
