<router-outlet></router-outlet>
<ng-template #noConnectionModal>
  <div class="modal-header">
  </div>
  <div class="modal-body">
    <p>
      Erreur de connexion au serveur
    </p>
    <button class="btn btn-success" (click)="reessayer()">Réessayer</button>
  </div>
</ng-template>
