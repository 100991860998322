import { Component, OnInit, Input } from '@angular/core';
import { Jeu } from '@models/jeu';
import { FormGroup, FormControl } from '@angular/forms';
import { DebriefComment } from '@models/debrief-comment';
import { DebriefService } from '@services/debrief.service';

@Component({
  selector: 'app-commentaire-debrief',
  templateUrl: './commentaire-debrief.component.html',
  styleUrls: ['./commentaire-debrief.component.scss']
})
export class CommentaireDebriefComponent implements OnInit {
  @Input() jeu: Jeu;

  loadingAddComment = false;

  debriefElements: any[] = [];

  debriefCommentForm = new FormGroup({
    'debriefComment': new FormControl('')
  });

  constructor(
    private debriefService: DebriefService
  ) { }

  ngOnInit(): void {
  }

  addDebriefComment(){
    this.loadingAddComment = true;
    let texte = this.debriefCommentForm.get('debriefComment').value;

    let debriefComment = {
      'jeu': this.jeu,
      'tour': this.jeu.tour,
      'texte': texte
    } as DebriefComment;

    this.debriefService.addDebriefComment(debriefComment).subscribe(() => {
      this.loadingAddComment = false;

      this.debriefCommentForm.patchValue({
        'debriefComment': ''
      });
    });

  }

}
