import { Adapter } from '@models/adapter';
import { Injectable } from '@angular/core';

export interface TypeProduit{
    iri: string,
    id: number,
    nom?: string,
    slug?: string,
}

@Injectable({
  providedIn: 'root'
})
export class TypeProduitAdapter implements Adapter<TypeProduit>{
  adapt(data: any): TypeProduit{
    const typeProduit = {
        'iri': data['@id'],
        'nom': data['nom'],
        'id': data['id'],
        'slug': data['slug'],
    }
    return typeProduit as TypeProduit;
  }
}