<div class="division-data">
  <div class="division-left">
    <div class="user-profil">
      <div class="user-photo">
        <img [src]="contentUrl()" alt="logo" (click)="fileInput.click()">
        <input class="form-control" type="file" id="logo" name="logo" (change)="onLogoSelect($event)" style="opacity: 0;" #fileInput formControlName="logoTypeJeu">
      </div>
    </div>
  </div>
  <div class="division-right">
    <form [formGroup]="typeJeuForm" (ngSubmit)="onCreateTypeJeu()">
      <div *ngIf=" typeEquipeAll " class="row">
        <div class="col-md-12">

        <!-- <div class="col-md-2">
          <label for="logo">
            <img [src]="contentUrl()" alt="Pdp" class="form-label"  class="w-100">
          </label>
          <input type="file" class="form-group" formControlName="logoTypeJeu" (change)=onLogoSelect($event) style="opacity: 0;" id="logo">
        </div> -->
          <div class="form-group">
            <label for="nom">Nom du type de jeu</label>
            <input type="text" id="nom"
                   class="form-control" formControlName="nom">
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea spellcheck="false" id="description"
                      class="form-control" formControlName="description">
            </textarea>
          </div>
          <div class="form-group">
            <p-multiSelect [options]="typeEquipeAll" defaultLabel="Types d'équipes associées" optionLabel="nom" display="chip" formControlName="selectedEquipes">
            </p-multiSelect>
          </div>
          <!--<div class="form-group">
            <label for="type_joueurs">Types de joueurs associés</label>
            <input type="text" id="type_joueurs"
                   class="form-control">
          </div> !-->
          <div class="row form-group">
            <label  class="col-md-3" for="nbTours">Nombre de tours</label>
            <input  class="col-md-3" type="number" id="nbTours" formControlName="nbTours" min="1" max="3">
          </div>
          <div class="row form-group">
              <label  class="col-md-3" for="duree">Durée du jeu (en heure)</label>
              <input  class="col-md-3" type="number" id="duree" formControlName="duree" min="1" max="2">
            </div>
          <button type="submit" mat-raised-button color="primary" [disabled]="typeJeuForm.invalid">
            <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
            Créer le type de jeu
          </button>
        </div>
    </div>
  </form>
  </div>
</div>


