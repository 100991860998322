import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { Equipe } from './equipe';
import { User, UserAdapter } from './user';
import { TypeJoueur } from './type-joueur';
import { MediaObject } from './media-object';

export interface Joueur {
    id?: number,
    iri?: string,
    type?: TypeJoueur | string,
    objectifPublic?: string,
    objectifPrive?: string,
    isPrincipal?: boolean,
    iriUser?: string,
    user?: User,
    equipe?: Equipe,
    indexEquipe?: number,
    description?: string,
    dossierPersonnel?: MediaObject
}

@Injectable({
    providedIn: 'root'
  })
export class JoueurAdapter implements Adapter<Joueur> {
    constructor(private userAdapter: UserAdapter){}
    adapt(data:any):Joueur {
        return {
            id: data["id"],
            iri: data["@id"],
            type: data["type"],
            objectifPrive: data["objectifPrive"],
            objectifPublic: data["objectifPublic"],
            isPrincipal: data["isPrincipal"],
            user: data["user"],
            description : data["description"],
            // this.userAdapter.adapt(data["user"]),
            equipe : data["equipe"],
            dossierPersonnel: data['dossierPersonnel'] as MediaObject
        } as Joueur;
    }
}
