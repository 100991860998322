import { createSelector } from '@ngrx/store';
import { selectJeu } from './jeu.selector';
import { JeuState } from '../states/jeu.state';
import { AppState } from '../states/app.state';

export const selectAppState = (state) => state.app;

export const selectTest = (state) => state.app.loadResultatAction;

export const selectAppJeu = createSelector(
  selectAppState,
  (state: AppState) => state.jeu
);

export const selectAppJoueur = createSelector(
  selectAppState,
  (state: AppState) => state.joueur
);

export const selectAppHistoriques = createSelector(
  selectAppState,
  (state: AppState) => state.historiques
);

export const selectAppDebriefElements = createSelector(
  selectAppState,
  (state: AppState) => state.debriefElements
);

export const selectAppBudget = createSelector(
  selectAppState,
  (state: AppState) => state.budget
);

export const selectAppLoadResultat = createSelector(
  selectAppState,
  (state: AppState) => state.loadResultat
);

export const selectAppLoadDecisions = createSelector(
  selectAppState,
  (state: AppState) => state.loadDecisions
);

export const selectAppStartPitch = createSelector(
  selectAppState,
  (state: AppState) => state.startPitch
);

export const selectAppLoadVote = createSelector(
  selectAppState,
  (state: AppState) => state.loadVote
);


export const selectAppLoadSondage = createSelector(
  selectAppState,
  (state: AppState) => state.loadSondage
);

export const selectAppChangementVote = createSelector(
  selectAppState,
  (state: AppState) => state.changementVote
);

export const selectAppChangementNouveauxProduits = createSelector(
  selectAppState,
  (state: AppState) => state.changementNouveauxProduits
);

export const selectAppVirement = createSelector(
  selectAppState,
  (state: AppState) => state.virement
);

export const selectAppEtapeSuivante = createSelector(
  selectAppState,
  (state: AppState) => state.etapeSuivante
);

export const selectUnseenRooms = createSelector(
  selectAppState,
  (state: AppState) => state.unseenRooms
);
