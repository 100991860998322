import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CustomDatasource } from '@datasources/custom-datasource';
import { TypeEquipe, TypeEquipeAdapter } from '@models/type-equipe';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaObjectService } from '@services/media-object.service';
import { TypeEquipeService } from '@services/type-equipe.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { merge } from 'rxjs';
import { debounce, debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-type-equipe-list',
  templateUrl: './type-equipe-list.component.html',
  styleUrls: ['./type-equipe-list.component.scss']
})
export class TypeEquipeListComponent implements OnInit, AfterViewInit {

  dataSource : CustomDatasource<TypeEquipe>;
  displayedColumns = ["type_equipe_nom", "action"];
  defaultPageSize = 5;
  closeModal: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private typeEquipeService: TypeEquipeService,
    private mediaObjectService: MediaObjectService,
    private adapter: TypeEquipeAdapter,
    private modalService: NgbModal) { }
  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      debounceTime(500),
      tap(() => {
        this.load()
      })
    ).subscribe();
  }

  ngOnInit(): void {
    this.dataSource = new CustomDatasource<TypeEquipe>(this.typeEquipeService, this.adapter);
    this.dataSource.load("", null, this.defaultPageSize);
  }

  load(){
    let sortCol = this.sort.active;
    let sortDirection = this.sort.direction;
    let orders = {[sortCol]: sortDirection};

    this.dataSource.load("", this.paginator.pageIndex, this.paginator.pageSize, orders);
  }

  total() {
    return this.dataSource.total;
  }

  onDelete(id: number){
    this.typeEquipeService.delete(id).subscribe(
      () => {
        this.load();
      }
    );
  }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  contentUrl(logo){
    return this.mediaObjectService.contentUrl(logo);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
