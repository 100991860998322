import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
export interface Student{
  id: number,
  name: string,
  age: number
}

@Injectable({
  providedIn: 'root'
})
export class StudentAdapter implements Adapter<Student>{
  adapt(data: any): Student {
    return {
      "id": data.id,
      "name": data['name'],
      "age": data.age
    } as Student;
  }

}
