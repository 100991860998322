import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { TypeEquipe } from './type-equipe';
import { Joueur, JoueurAdapter } from './joueur';
import { Jeu } from './jeu';
import { MediaObject } from './media-object';
export interface Equipe{
  id?: number,
  iri?: string,
  nom?: string,
  description?: Text,
  iriTypeEquipe?: string,
  jeu?: Jeu,
  iriJeu?: string,
  typeEquipes?: TypeEquipe,
  type?: TypeEquipe,
  joueurs?: Joueur[],
  objectifPublic?: string,
  moyensActions?: string,
  montantVirements?: number,
  logo?: MediaObject
}

@Injectable({
  providedIn: 'root'
})
export class EquipeAdapter implements Adapter<Equipe>{
  constructor(private joueurAdapter: JoueurAdapter){}
  adapt(data: any): Equipe {
    return {
      "id": data['id'],
      "iri": data['@id'],
      "nom": data['nom'],
      "description": data['description'],
      "joueurs": data['joueurs'].map((j:any) => this.joueurAdapter.adapt(j)),
      "objectifPublic": data['objectifPublic'],
      "moyensActions": data['moyensActions'],
      "typeEquipes": data['type'],
      "type": data['type'],
      "iriJeu": data['jeu'],
      "montantVirements": data['montantVirements'],
      "logo": data['logo']
    } as Equipe;
  }
}
