import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionJeu } from '@interfaces/actionJeu';
import { Jeu } from '@models/jeu';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActionJeuService } from '@services/action-jeu.service';
import { JeuService } from '@services/jeu.service';

@Component({
  selector: 'app-timeline-page',
  templateUrl: './timeline-page.component.html',
  styleUrls: ['./timeline-page.component.scss']
})
export class TimelinePageComponent implements OnInit {

  idJeu: number;
  jeu: Jeu;

  allActionJeux: any[];
  createActionModalRef: NgbModalRef;
  currentActionJeu: any = {
    "nom" : null,
    "slug" : null,
    "type" : null,
    "duree" : null,
    "started" : false,
    "finished" : false,
    "jeu" : null,
    "tourLancement" : null,
    "dateLancement" : new Date(),
  };

  constructor(private router : Router,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private jeuService: JeuService,
              private actionJeuService: ActionJeuService,
              ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.getJeu(this.idJeu);
  }

  getJeu(id: number){
    this.jeuService.getOne(id).subscribe((jeu: Jeu) => {
      this.jeu = jeu;
      this.getAllActions(this.idJeu, this.jeu.tour);
    });
  }

  getAllActions(idJeu, tour){
    this.actionJeuService.getByJeuAndTour(idJeu, tour).subscribe(
      res => {
        this.allActionJeux = res;
      }
    )
  }

  //TODO: chrono compte a rebours
  addActionJeu(){
    this.currentActionJeu.jeu = "/api/jeus/" + this.idJeu;

    this.actionJeuService.createActionService({...this.currentActionJeu}).subscribe((actionJeu) => {

        if(actionJeu.tourLancement === this.jeu.tour)
          this.allActionJeux.push(actionJeu);
      }
    );
    this.resetCurrentActionJeu();
    this.createActionModalRef.close();
  }

  resetCurrentActionJeu(){
    this.currentActionJeu = {
      "nom" : null,
      "slug" : null,
      "type" : null,
      "duree" : null,
      "started" : false,
      "finished" : false,
      "jeu" : null,
      "tourLancement" : null,
      "dateLancement" : new Date(),
    };
  }

  triggerModal(content){
    this.createActionModalRef = this.modalService.open(content);
  }

}
