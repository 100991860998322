import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router"
import { ProduitListComponent } from "./produit-list/produit-list.component"
import { ProduitCreateComponent } from "./produit-create/produit-create.component"
import { ProduitModificationComponent } from "./produit-modification/produit-modification.component"
const routes: Routes  = [
    { path: "list", component: ProduitListComponent },
    { path: "create", component: ProduitCreateComponent },
    { path: "edit/:id", component: ProduitModificationComponent},
    { path: "", redirectTo: "list", pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]    
})
export class ProduitRouting { }

