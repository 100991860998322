import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, finalize, delay } from 'rxjs/operators';
import { IListerService } from '@interfaces/lister.service.interface';
import { Adapter } from '@models/adapter';

export class CustomDatasource<T> implements DataSource<T>{
  private collectionSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();

  public get total()
  {
    return this.totalSubject.getValue();
  }

  constructor(private service: IListerService<T>, private adapter: Adapter<T>){

  }
  connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
    this.service.count$.pipe(delay(0)).subscribe(total => {
      this.totalSubject.next(total);
    });

    return this.collectionSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this.collectionSubject.complete();
    this.loadingSubject.complete();
  }

  load(filter={}, pageIndex = 0, pageSize = 10, orders = {}){
    this.loadingSubject.next(true);
    this.service.getAll(filter, pageIndex, pageSize, orders).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).pipe(

    ).subscribe((collection) => {
      this.collectionSubject.next(collection);
    });
  }
}
