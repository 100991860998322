import { Injectable } from "@angular/core";
import { Adapter } from "./adapter"
import { MediaObject, MediaObjectAdapter } from "./media-object";
import { TypeProduit } from "./typeProduit";

export interface Produit
{
    id: number,
    iri: string,
    nom: string,
    description: string,
    cout : number,
    espoirGain : number,
    niveauRisque: number,
    type: TypeProduit,
    photo?: MediaObject,
    strategieSecurites?: string,
    costAsPercentIt?: number
}
@Injectable({
    providedIn: "root"
})
export class ProduitAdapter implements Adapter<Produit>  {
    constructor(private mediaObjectAdapter: MediaObjectAdapter) {}
    adapt(data: any): Produit {
        return {
            id : data['id'],
            iri : data['@id'],
            nom : data.nom,
            description: data['description'],
            cout : data['cout'],
            espoirGain : data['espoirGain'],
            niveauRisque: data['niveauRisque'],
            type: data['type'],
            photo: data['photo']? this.mediaObjectAdapter.adapt(data['photo']): null,
            strategieSecurites : data['strategieSecurites'],
            costAsPercentIt: data['costAsPercentIt']
        } as Produit;
    }

}
