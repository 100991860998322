import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SalonNouveaute, SalonNouveauteAdapter } from '@models/salon-nouveaute';
import { Lister } from '@services/listers/lister.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalonNouveauteService extends Lister<SalonNouveaute>{

  constructor(
    protected httpClient: HttpClient,
    protected salonAdapter: SalonNouveauteAdapter
  ) {
    super(httpClient, salonAdapter);
    this.setListUri('api/salon_nouveautes');
  }

  createSalon(salon: SalonNouveaute){
    const url = `${environment.apiUrl}/${this.listUri}`;
    return this.http.post(url,salon).pipe(
      map(
        (data)=>{
          return this.adapter.adapt(data)
        }
      )
    );
  }

  getSalonByTour(tour:Number, idJeu:number){
    const url = `${environment.apiUrl}/api/salon_nouveautes/?tour=${tour}&jeu=${idJeu}`;

    return this.http.get<SalonNouveaute>(url).pipe(
      map(data=>data['hydra:member']),
      tap((data)=>{
          return this.adapter.adapt(data)
        }
      )
    );
  }

  getSalonByJeu(idJeu:number){
    const url = `${environment.apiUrl}/api/salon_nouveautes/?jeu=${idJeu}`;

    return this.http.get<SalonNouveaute>(url).pipe(
      map(data=>data['hydra:member']),
      tap((data)=>{
          return this.adapter.adapt(data)
        }
      )
    );
  }

  getSalonByJeuAndTour(idJeu: number, tour: number): Observable<SalonNouveaute>{
    return this.getAll({"jeu": idJeu, "tour": tour}).pipe(
      map(result => result[0])
    )
  }

  startTime(id:number){
    const url = `${environment.apiUrl}/${this.listUri}/startTime/`+id;
    return this.http.put(url,{}).pipe(
      tap((data)=>{
          return this.adapter.adapt(data)
        }
      )
    );
  }

  updateSalon(salon:SalonNouveaute){
    const url = `${environment.apiUrl}/${this.listUri}/`+salon.id;
    return this.http.put(url,salon).pipe(
     tap((data)=>{
          return this.adapter.adapt(data)
        }
      )
    );
  }

  deleteSalon(id:number){
	  const url = `${environment.apiUrl}/${this.listUri}/`+id;
		return this.http.delete(url).pipe();
	}

}
