<html lang="en">
<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">
  <meta content="" name="description">
  <meta content="" name="author">
  <title>CSG | Reset Password</title>

</head>

<body>
  <div class="video-bg">
    <video src="assets/images/bg-body.mp4" loop muted autoplay></video>
    <div class="overlay"></div>
    <div class="container-custom login-back">
      <div class="login-form mt-5">
        <div class="logo-gm"><img src="assets/images/logo-blanc.png"></div>
        <div class="formulaire">
          <div class="titre-data">
            <h2>Récupération mot de passe</h2>
          </div>
          <div class="csg-form">
            <form>
              <div class="form-label-group-custom">
                <input type="email" value="" name="password" id="newPassword" class="form-control-custom" placeholder="Mot de passe" required="" autofocus="" [formControl]="newPassword">
                <i class="icon-email"></i>

              </div>

              <button type="submit" class="btn_gradient" (click)="onSubmitNewPassword()">
                <span class="spinner-border spinner-border-sm" *ngIf="loadingResetPassword"></span>
                CHANGER
              </button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</body>
</html>
