<mat-list class="dark">
    <mat-list-item *ngFor="let breakingNew of allBreakingNews">
        <app-crud-breaking-news [breakingNew]="breakingNew" (deleted)="delete($event)"></app-crud-breaking-news>
    </mat-list-item>
</mat-list>
<div class="text-center">
    <button mat-mini-fab color="primary" (click)="triggerModal(createModal)">
        <mat-icon>add</mat-icon>
    </button>
</div>


<ng-template let-modal #createModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4>{{ 'creation_breaking_news' | translate }}</h4>
        </div>
        <div class="modal-body">
            <form [formGroup]="form">
                <div class="form-group">
                    <label>{{ 'nom' | translate }}:</label>
                    <input type="text" class="form-control" formControlName="nom">
                  </div>
                  <div class="form-group">
                    <label>{{ 'layout_description' | translate }}:</label>
                    <textarea spellcheck="false" class="form-control" rows=5 formControlName="description"></textarea>
                  </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" color="warn" mat-raised-button (click)="modal.close()">{{ 'annuler' | translate }}</button>
            <button type="button" color="primary" mat-raised-button (click)="save()" [disabled]="form.invalid || loadingCreation">
                <span class="spinner-border spinner-border-sm mr-1" *ngIf="loadingCreation"></span>
                {{ 'creer' | translate }}
            </button>
        </div>
    </div>

</ng-template>
