<div class="division-data">
  <div class="division-left">
    <div class="user-profil">
      <div class="user-photo">
        <img [src]="contentUrl()" alt="logo" (click)="fileInput.click()">
        <input class="form-control" type="file" id="logo_type_equipe" name="logo" (change)="onFileChange($event)" style="opacity: 0;" #fileInput formControlName="logoFile">
      </div>
    </div>
  </div>

  <div class="division-right">
    <form [formGroup]="typeEquipeForm" (ngSubmit)="onSubmit()">
      <div class="row">
          <!--<div class="col-md-3">
              Image equipe
              <label for="logo_type_equipe" class="form-label">
                  <img [src]="contentUrl()" alt="Pdp" class="w-100">
              </label>
              <input class="form-control" type="file" id="logo_type_equipe" formControlName="logoFile" (change)="onFileChange($event)" style="opacity: 0;">
          </div>!-->
          <div class="col-md-12">
              <div class="form-group">
                  <label for="nom_type_equipe">Nom type d'équipe :</label>
                  <input type="text" formControlName="nom" class="form-control" id="nom_type_equipe">
              </div>
              <div class="form-group">
                  <label for="description_type_equipe">Description:</label>
                  <textarea spellcheck="false" formControlName="description" id="description_type_equipe" class="form-control" rows=5></textarea>
              </div>

              <div class="form-group">
                <label>Classification</label>
                <mat-select formControlName="ecosysteme">
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let ecosysteme of ecosystemes" [value]="ecosysteme.slug">{{ ecosysteme.nom }}</mat-option>
                </mat-select>
              </div>

              <div class="form-group">
                  <label for="number_min_joueur">Nombre de joueurs min:</label>
                  <input type="number" class="form-control col-2" formControlName="nbJoueursMin" id="number_min_joueur" min=0 value=2>
              </div>
              <div class="form-group">
                  <label for="number_max_joueur">Nombre de joueurs max:</label>
                  <input type="number" class="form-control col-2" formControlName="nbJoueursMax" id="number_max_joueur" min=0 value=6>
              </div>
              <button type="submit" mat-raised-button color="primary" [disabled]="!typeEquipeForm.valid">
                <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
                Créer le type d'équipe
              </button>
          </div>
      </div>
  </form>

  </div>

</div>

