import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { jeuSelectAction, jeuSelectedAction } from '../actions/jeu.actions';
import { Router } from '@angular/router';

@Injectable()
export class JeuEffects {

  selectJeu$ = createEffect(() => this.actions$.pipe(
    ofType(jeuSelectAction),
    mergeMap(
      action => of().pipe(
        map(data => jeuSelectedAction()),
        tap(() => {

          this.router.navigate['/'];
        })
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
}
