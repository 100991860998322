<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="typeJoueurForm">
      <div class="row">
          <div class="col-md-2">
          </div>
          <div class="col-md-8">
              <div class="form-group">
                  <label>Nom:</label>
                  <input type="text" class="form-control" formControlName="nom">
              </div>
              <div class="form-group">
                  <label>Description:</label>
                  <textarea spellcheck="false" class="form-control" rows=5 formControlName="description"></textarea>
              </div>

              <div class="form-group">
                <mat-form-field appearance="fill">
                  <mat-label>Type d'équipe associé</mat-label>
                  <mat-select formControlName="iriTypeEquipe">
                    <mat-option *ngFor="let typeEquipe of typeEquipes" [value]="typeEquipe.iri">
                      {{typeEquipe.nom}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <button type="submit" mat-raised-button color="primary" [disabled]="typeJoueurForm.invalid">
                <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
                Enregistrer
              </button>
          </div>
      </div>
  </form>

  </div>
