<div>
  Configuration des nouveautés produits<br>
  <div>

    <mat-list role="list" *ngIf="allSalons">
      <mat-list-item role="listitem" *ngFor="let salon of allSalons; let i=index">
		<div>
			  Round {{i+1}} {{ salon.nom}}
			<button id="round" (click)="handleSelect(createSalon,i+1)" mat-raised-button >Sélectionner</button>
			<button *ngIf="salon.id" (click)="handleDelete(i+1)" mat-raised-button color="warn">Supprimer</button>
		</div>

      </mat-list-item>
    </mat-list>
  </div>
</div>

<ng-template #createSalon let-modal>
  <div class="modal-content">

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Configuration Nouveauté Produit</h4>
      <button type="button" class="close" aria-label="Fermer" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body">
      <form #salonForm = "ngForm">
          <div class="form-group">
            <label>Nom du salon</label>
            <input type="text" class="form-control dark" name="nom" [(ngModel)]="currentSalon.nom" required>
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea spellcheck="false" class="form-control dark" name="description" [(ngModel)]="currentSalon.description" required></textarea>
          </div>
          <div class="form-group dark" >
            <label>Liste des produits:</label>
              <p-multiSelect [options]="allProducts" name="produit" optionLabel="nom" [(ngModel)]="currentSalon.produits" defaultLabel="Les Produits associés" display="chip" optionValue="iri" >
              </p-multiSelect>
          </div>
          <!-- <button type="submit" (click)="onInsertSalon()">save</button> -->
      </form>
    </div>

    <div class="modal-footer">
      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">{{ 'fermer' | translate }}</button>
      <button type="button" mat-raised-button color="primary" (click)="onInsertSalon(); modal.close('Save click')">Ajouter</button>
    </div>
  </div>
</ng-template>
