import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { RoomAdapter, Room } from '@models/room';
import { map } from 'rxjs/operators';
import { Joueur } from '@models/joueur';
import { MessageAdapter } from '@models/message';

@Injectable({
  providedIn: 'root'
})
export class MessagerieService {

  constructor(
    private http: HttpClient,
    private roomAdapter: RoomAdapter,
    private messageAdapter: MessageAdapter
  ) { }

  getRoomsOfJoueur(idJoueur: number){
    const url = `${environment.apiUrl}/custom/api/joueur/rooms-of-joueur/${idJoueur}`;

    return this.http.get(url).pipe(
      map((data: any) => data.map(x => this.roomAdapter.adapt(x)))
    );
  }

  getAllRooms(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/get-all-rooms/${idJeu}`;

    return this.http.get(url).pipe(
      map((data: any) => data.map(x => this.roomAdapter.adapt(x)))
    );
  }

  getMessages(room: Room){
    const url = `${environment.apiUrl}/custom/api/messagerie/get-messages/${room.id}`;

    return this.http.get(url).pipe(
      map((data: any) => data.map(x => this.messageAdapter.adaptCustom(x)))
    );
  }

  getAllMessages(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/messagerie/get-all-messages/${idJeu}`;

    return this.http.get(url).pipe(
      map((data: any) => data.map(x => this.messageAdapter.adaptCustom(x)))
    );
  }

  sendMessage(message: string, room: Room, joueur: Joueur){
    const url = `${environment.apiUrl}/custom/api/messagerie/send-message`;

    return this.http.post(url, {
      'message': message,
      'room': room.id,
      'joueur': joueur.id
    });

  }
}
