import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { AnnonceAdapter } from '@models/annonce';

@Injectable({
  providedIn: 'root'
})
export class AnnonceService {

  constructor(
    private http: HttpClient,
    private annonceAdapter: AnnonceAdapter
  ) { }

  /**
   * Retourne les annonces qui sont définis pour le Jeu
   * @param idJeu
   * @param tour
   * @param onlyPublished si à true, alors on retourne seulement les annonces publiées
   */
  getAnnoncesJeu(idJeu: number, tour: number, onlyPublished=false){
    let url = `${environment.apiUrl}/custom/api/annonce/get-annonces-jeu/${idJeu}/${tour}`;

    if(onlyPublished){
      url += '?published=true';
    }

    return this.http.get(url).pipe(
      map((data: any) => data.map(x => this.annonceAdapter.adapt(x)))
    );
  }

  lancerAnnonce(idAnnonce: number){
    const url = `${environment.apiUrl}/custom/api/annonce/lancer-annonce/${idAnnonce}`;

    return this.http.post(url, {}).pipe(
      map((data: any) => this.annonceAdapter.adapt(data))
    );
  }

  createAnnonceJeu(data: any) {
    return this.http.post(`${environment.apiUrl}/custom/api/annonce`, data);
  }

  updateAnnonceJeu(id: number, data: any) {
    if(id == null)
      return this.createAnnonceJeu(data);
    else
      return this.http.post(`${environment.apiUrl}/custom/api/annonce/${id}`, data);
  }

  deleteAnnonce(id: number){
    return this.http.post(`${environment.apiUrl}/custom/api/annonce/delete-annonce/${id}`, {});
  }
}
