import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export interface SituationFinanciere{
  id?: number,
  cashflow?: number,
  revenues?: number,
  ebitda?: number,
  tour?: number,
  cashflowLegacy?: number,
  revenuesNewOperations?: number,
  cashflowNewOperations?: number,
  estimationItCostsLegacy?: number,
  estimationItCostsNewOperations?: number,
  revenuesTotal?: number,
  cashflowTotal?: number,
  ebitdaTotal?: number,
  stockPrice?: number
}

@Injectable({
  providedIn: 'root'
})
export class SituationFinanciereAdapter implements Adapter<SituationFinanciere>{
    adapt(data: any){
      return {
        'id': data['id'],
        'cashflow': data['cashflow'],
        'revenues': data['revenues'],
        'ebitda': data['ebitda'],
        'tour': data['tour'],
        'cashflowLegacy': data['cashflowLegacy'],
        'revenuesNewOperations': data['revenuesNewOperations'],
        'cashflowNewOperations': data['cashflowNewOperations'],
        'estimationItCostsLegacy': data['estimationItCostsLegacy'],
        'estimationItCostsNewOperations': data['estimationItCostsNewOperations'],
        'revenuesTotal': data['revenuesTotal'],
        'cashflowTotal': data['cashflowTotal'],
        'ebitdaTotal': data['ebitdaTotal'],
        'stockPrice': data['stockPrice']
      } as SituationFinanciere;
    }
}
