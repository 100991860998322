import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FormArray } from '@angular/forms';

import { RessourceRoutingModule } from './ressource-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from "@angular/material/checkbox";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RoleGuard } from '../services/auth-guard.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { RessourceListComponent } from './ressource-list/ressource-list.component';
import { RessourceCreateComponent } from './ressource-create/ressource-create.component';
import { RessourceEditComponent } from './ressource-edit/ressource-edit.component';
import { RessourceShowComponent } from './ressource-show/ressource-show.component';

@NgModule({
  declarations: [RessourceListComponent, RessourceCreateComponent, RessourceEditComponent, RessourceShowComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RessourceRoutingModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MultiSelectModule,
  ],
  providers: [
    RoleGuard
  ]
})
export class RessourceModule { }
