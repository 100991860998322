<div class="division-data">
  <div class="division-left">
    <div class="user-profil">
      <div class="user-photo">
        <img [src]="contentUrl(typeJeu.logo)" alt="logo" (click)="fileInput.click()">
        <input class="form-control" type="file" id="logo" name="logo" (change)="onLogoSelect($event)" style="opacity: 0;" #fileInput formControlName="logoTypeJeu">
      </div>
    </div>
  </div>
  <div class="division-right">
    <form *ngIf="typeJeu"  [formGroup]="typeJeuForm" (ngSubmit)="onSaveTypeJeu()">
      <div *ngIf=" typeEquipeAll" class="row" >
        <div class="col-md-12">
          <!-- <div class="col-md-2">
            <label for="logo" class="form-label">
              <img [src]="contentUrl(typeJeu.logo)" alt="Pdp" class="form-label"  class="w-100">
            </label>
            <input type="file" class="form-control" formControlName="logoTypeJeu" (change)=onLogoSelect($event) id="logo" style="opacity: 0;">
          </div> -->
          <!-- <div class="col-md-6 col-md-offset-2"> -->
            <div class="form-group">
              <label for="nom">Nom du type de jeu</label>
              <input type="text" id="nom"
                     class="form-control" formControlName="nom" [(ngModel)]="typeJeu.nom">
            </div>

            <div class="form-group">
              <label for="description">Description</label>
              <textarea spellcheck="false" id="description"
                        class="form-control" formControlName="description" [(ngModel)]="typeJeu.description">
              </textarea>
            </div>

            <div class="form-group">
              <p-multiSelect [options]="typeEquipeAll" defaultLabel="Types d'équipes associées" optionLabel="nom" optionValue="@id" display="chip" formControlName="selectedEquipes">
              </p-multiSelect>
            </div>

            <!--<div class="form-group">
              <label for="type_joueurs">Types de joueurs associés</label>
              <input type="text" id="type_joueurs"
                     class="form-control">
            </div> !-->

            <div class=" row form-group">
              <label class="col-md-3" for="nbTours">Nombre de tours : </label>
              <input class="col-md-3" type="number" id="nbTours" formControlName="nbTours" min="1" max="3" [(ngModel)]="typeJeu.nbTours">
            </div>

            <div class=" row form-group">
                <label class="col-md-3" for="duree">Durée du jeu (en heure): </label>
                <input class="col-md-3" type="number" id="duree" formControlName="duree" min="1" max="2" [(ngModel)]="typeJeu.duree">
              </div>

            <button type="submit" mat-raised-button color="primary" [disabled]="typeJeuForm.invalid">
              <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
              Enregistrer
            </button>
        <!-- </div> -->
        </div>
      </div>
    </form>
  </div>
</div>

