import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(private authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.authService.isAdmin() || this.authService.isAnimateur() || this.authService.isOwnerOf(parseInt(route.params.id));
    }

}


@Injectable({
    providedIn: "root"
})
export class AnimOrAdmin implements CanActivate {
    constructor(private authService : AuthenticationService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if(this.authService.isAdmin() || this.authService.isAnimateur()){
            return true;
        }else{
            this.router.navigate(["/layout"]);
        }
    }


}


@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate{
    constructor(private router: Router, private authService: AuthenticationService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if(this.authService.is_connected()){
            return true;
        } else {
            this.router.navigate(["/login"]);
        }
    }

}
