import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IListerService } from '@interfaces/lister.service.interface';
import { Ressource, RessourceAdapter } from '@models/ressource'
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Lister } from '@services/listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class RessourceService extends Lister<Ressource>{

  constructor(protected httpClient: HttpClient, protected adaptor: RessourceAdapter) {
    super(httpClient, adaptor);
    this.setListUri('api/ressources');
  }

  getOne(id): Observable<Ressource>{
    return this.httpClient.get(`${environment.apiUrl}/api/ressources/${id}`).pipe(

      map(data => this.adaptor.adapt(data)),
    )
  }

  getRessourceByUrl(url:string) : Observable<Ressource>{
    return this.httpClient.get(`${environment.apiUrl}`+ url).pipe(
      map(this.adaptor.adapt)
    )
  }

  update(ressource: any): Observable<Ressource>{

    return this.httpClient.put(`${environment.apiUrl}/api/ressources/${ressource.id}`, ressource).pipe(
      map(data => this.adaptor.adapt(data))
    );
  }

  uploadFormDataFile(formdata : FormData): Observable<any>{
    const url = `${environment.apiUrl}/api/media_objects`;
    return this.http.post<any>(url, formdata,{ headers: {'Content-Type': ''} });
  }

  createRessource(formValue: any) {
    const ressource = formValue as Ressource;

    return this.httpClient.post(`${environment.apiUrl}/api/ressources`, ressource).pipe(
      map((data: any) => this.adaptor.adapt(data))
    )
  }

  create(ressource: Ressource){

    const url = `${environment.apiUrl}/api/ressources`;
    return this.http.post(url, ressource).pipe(
      map(
        (data)=>{
          return this.adapter.adapt(data)
        }
      )
    )
  }

  delete(id: number){
    return this.httpClient.delete(`${environment.apiUrl}/api/ressources/${id}`)
  }


}
