<div class="division-data">
    <div class="division-right">
        <form [formGroup] = "ressourceForm" (ngSubmit)="onCreate()">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="nom_ressource">Nom du ressource :</label>
                        <input type="text" formControlName="nom" class="form-control" id="nom_ressource">
                    </div>
                    <div class="form-group">
                        <label for="inputType">Type</label>
                        <select class="form-control" id="inputType" formControlName="type">
                          <option *ngFor="let typeRessource of typeRessourceAll" [ngValue]="typeRessource.iri">{{ typeRessource.nom }}</option>
                        </select>    
                    </div>
                    <div class="form-group">
                        <label for="fichier_ressource">Fichier :</label><br>
                        <input type="file"  id="fichier_ressource" (change)=onFileSelect($event)>
                    </div>
                    <button type="submit" mat-raised-button color="primary">
                        <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
                        Créer le ressource
                    </button>
                </div>
            </div>
        </form>
</div>
  
