import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Equipe } from '@models/equipe';
import { Observable } from 'rxjs';
import { EquipeService } from '@services/equipe.service';

@Component({
  selector: 'app-equipe-show',
  templateUrl: './equipe-show.component.html',
  styleUrls: ['./equipe-show.component.scss']
})
export class EquipeShowComponent implements OnInit {
  idEquipe: number;
  equipe$: Observable<Equipe>;

  equipe: Equipe;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private equipeService: EquipeService
  ) { }

  ngOnInit(): void {
    this.idEquipe = this.route.snapshot.params.idEquipe;
    this.loadEquipe();
  }

  loadEquipe(){
    this.equipe$ = this.equipeService.getOne(this.idEquipe);
  }

  updateEquipe(){
    this.router.navigate(['/equipe/' + this.idEquipe + '/edit']);
  }
}
