import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private connectionTimeoutSubject = new BehaviorSubject<boolean>(true);
  connectionTimeout = this.connectionTimeoutSubject.asObservable();

  constructor() { }

  setOffline()
  {
    this.connectionTimeoutSubject.next(false);
  }

  setOnline()
  {
    this.connectionTimeoutSubject.next(true);
  }
}
