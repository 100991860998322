import { Component, Input, OnInit } from '@angular/core';
import { Jeu } from '@models/jeu';
import { JeuService } from '@services/jeu.service';

@Component({
  selector: 'app-jeu-info',
  templateUrl: './jeu-info.component.html',
  styleUrls: ['./jeu-info.component.scss']
})
export class JeuInfoComponent implements OnInit {

  @Input() idJeu: number;

  jeu: Jeu;


  constructor(private jeuService: JeuService) { }

  ngOnInit(): void {
    this.jeuService.getOne(this.idJeu).subscribe(
      (jeu) => {
        this.jeu = jeu;
      }
    )
  }

}
