import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Produit, ProduitAdapter } from '@models/produit';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Lister } from './listers/lister.service';
import { Jeu } from '@models/jeu';

@Injectable({
  providedIn: 'root'
})
export class ProduitService extends Lister<Produit> {

  constructor(protected httpClient: HttpClient, protected produitAdapter: ProduitAdapter) {
    super(httpClient, produitAdapter);
    this.setListUri("api/produits");
  }

  saveProduit(produit: Produit): Observable<Produit>{
    return this.httpClient.post(`${environment.apiUrl}/${this.listUri}`, produit).pipe(
      map(data => {
        return this.adapter.adapt(data)
      })
    );
  }

  update(produit: Produit): Observable<Produit>{
    return this.httpClient.put(`${environment.apiUrl}/${this.listUri}/${produit.id}`, produit).pipe(
      map(data => {
        return this.adapter.adapt(data)
      })
    );
  }

  delete(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}/${this.listUri}/${id}`);
  }

  get_outils_cyber(){

    return this.httpClient.get(`${environment.apiUrl}/${this.listUri}`+'?type.slug=investissement-securite').pipe(
      map(data => {return data['hydra:member']}),
      map((t: any[]) => t.map(x => this.adapter.adapt(x)))
    );
  }

  get_produits_accessibles(idJeu: number, tour: number){
    return this.httpClient.get(`${environment.apiUrl}/custom/api/entreprise/produitsAccessibles/${idJeu}/${tour}`);
  }

  getByMultipleId(listId: number[]){
    let params = "";
    listId.forEach((id, index)=> {
      // if(index == 0) params = "id=" + id;
      // else
      params += "&id[]=" + id;
    });
    const url = encodeURI(`${environment.apiUrl}/${this.listUri}?_page=1&itemsPerPage=30${params}`);
    return this.httpClient.get(url);
  }

  getProduitsTour(jeu: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/entreprise/nouveautes-tour/${jeu}/${tour}`;

    return this.httpClient.get(url).pipe(
      map((data: any) => data.map(x => this.produitAdapter.adapt(x)))
    );
  }

  getProduitsAnciens(jeu: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/entreprise/produits-anciens/${jeu}/${tour}`;

    return this.httpClient.get(url).pipe(
      map((data: any) => data.map(x => this.produitAdapter.adapt(x)))
    );
  }

  lancerNouveautes(jeu: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/jeu/lancer-nouveautes/${jeu}/${tour}`;

    return this.httpClient.post(url, {});
  }

  getProduitsInnovation(jeu: Jeu){
    const url = `${environment.apiUrl}/custom/api/jeu/get-produits-innovation/${jeu}`;

    return this.httpClient.get(url).pipe(
      map((data: any) => data.map(x => this.produitAdapter.adapt(x)))
    );
  }

  getProduitsCyber(jeu: Jeu){
    const url = `${environment.apiUrl}/custom/api/jeu/get-produits-cyber/${jeu}`;

    return this.httpClient.get(url).pipe(
      map((data: any) => data.map(x => this.produitAdapter.adapt(x)))
    );
  }

  addProduitToSalon(idJeu: number, tour: number, idProduit: number){
    const url = `${environment.apiUrl}/custom/api/jeu/add-produit-to-salon/${idJeu}/${tour}`;

    return this.httpClient.post(url, {'produit': idProduit}).pipe(
      map((data: any) => this.produitAdapter.adapt(data))
    );
  }

  deleteProduitFromSalon(idJeu: number, tour: number, idProduit: number){
    const url = `${environment.apiUrl}/custom/api/jeu/delete-produit-from-salon/${idJeu}/${tour}`;

    return this.httpClient.post(url, {'produit': idProduit});
  }

  setCoutProduitCyber(idJeu: number, cout: number){
    const url = `${environment.apiUrl}/custom/api/jeu/set-cout-produit-cyber/${idJeu}`;

    return this.httpClient.post(url, {'cout': cout});
  }
}
