import { UserShowComponent } from './user-show/user-show.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from './user-list/user-list.component';
import { AnimOrAdmin, RoleGuard} from '@services/auth-guard.service';
import { UserEditComponent } from './user-edit/user-edit.component';
import { AnimateurListComponent } from './animateur-list/animateur-list.component';


const routes: Routes = [
  { path: 'list', canActivate: [AnimOrAdmin], component: UserListComponent},
  { path: 'animateurlist', canActivate: [AnimOrAdmin], component: AnimateurListComponent},
  { path: 'show/:id', canActivate: [RoleGuard], component: UserShowComponent},
  { path: 'edit/:id', canActivate: [RoleGuard], component: UserEditComponent},
  { path: '', redirectTo: 'list', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
