import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '@services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  newPassword = new FormControl('');
  loadingResetPassword = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  onSubmitNewPassword(){
    let password = this.newPassword.value;
    let idUser = +this.route.snapshot.queryParamMap.get('user');
    let token = this.route.snapshot.queryParamMap.get('token');

    this.loadingResetPassword = true;
    this.userService.resetPassword(idUser, password, token).subscribe(() => {
      this.loadingResetPassword = false;
      this.router.navigate(['/login']);
    });
  }
}
