import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { StudentAdapter, Student } from '@models/student';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpClient, private adapter: StudentAdapter) { }

  getStudents(){
    return this.http.get(`${environment.apiUrl}/api/students`).pipe(
      map((data: any) => data['hydra:member']),
      map((t: any[]) => t.map(x => this.adapter.adapt(x)))
    );
  }
}
