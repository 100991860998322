import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Joueur } from '@models/joueur';
import { JoueurService } from '@services/joueur.service';

@Component({
  selector: 'app-joueur-show',
  templateUrl: './joueur-show.component.html',
  styleUrls: ['./joueur-show.component.scss']
})
export class JoueurShowComponent implements OnInit {

  idJoueur : number;
  joueur : Joueur;

  constructor(
    private route : ActivatedRoute,
    private joueurService : JoueurService,
    private router :  Router
  ) { }

  ngOnInit(): void {
    this.idJoueur = this.route.snapshot.params.idJoueur;
    this.getOneJoueur();
  }

  getOneJoueur(){
    this.joueurService.getOne(this.idJoueur).subscribe(
      (joueur)=>{
        this.joueur = joueur;
      }
    );
  }

  goToUpdate(id : number){
    return '/joueur/'+id+'/edit';
  }

}
