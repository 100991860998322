import { Injectable, Inject } from "@angular/core";
import { Adapter } from "./adapter";
import { MediaObject, MediaObjectAdapter} from "./media-object";
import { TypeJeuService } from "@services/type-jeu.service";
import { TypeJeu, TypeJeuAdapter } from "./type-jeu";
import { TypeJoueur, TypeJoueurAdapter } from '@models/type-joueur';

export interface TypeEquipe {
    id?: number,
    '@id'?: string,
    iri?: string,
    nom: string,
    description?: string,
    nbJoueursMin?: number,
    nbJoueursMax?: number,
    logo?: MediaObject,
    ecosysteme: string,
    typeJeux?: TypeJeu[],
    typeJoueurs?: TypeJoueur[]
}

@Injectable({
    providedIn: 'root'
  })
export class TypeEquipeAdapter implements Adapter<TypeEquipe> {
    constructor(
      private mediaObjectAdaptor: MediaObjectAdapter,
      // private typeJoueurAdapter: TypeJoueurAdapter
    ){

    }

    adapt(data: any): TypeEquipe {

        const typeEquipe = {
            'id': data.id,
            '@id': data["@id"],
            'iri': data['@id'],
            'nom': data.nom,
            'nbJoueursMin': data.nbJoueursMin,
            'nbJoueursMax': data.nbJoueursMax,
            'description': data.description,
            'logo': data.logo ? this.mediaObjectAdaptor.adapt(data.logo) : null,
            'ecosysteme': data.ecosysteme,
            'typeJeux': data.typeJeux as TypeJeu[],
            'typeJoueurs': data['typeJoueurs'] as TypeJoueur[]
          }

          return typeEquipe as TypeEquipe;

    }
}
