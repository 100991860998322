import { RessourceEditComponent } from './ressource-edit/ressource-edit.component';
import { RessourceCreateComponent } from './ressource-create/ressource-create.component';
import { RessourceShowComponent } from './ressource-show/ressource-show.component';
import { RessourceListComponent } from './ressource-list/ressource-list.component';
import { RessourceModule } from './ressource.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'list', component: RessourceListComponent},
  { path: "show/:id", component: RessourceShowComponent },
  { path: "create", component: RessourceCreateComponent },
  { path: "edit/:id", component: RessourceEditComponent },
  { path: '', redirectTo: 'list', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RessourceRoutingModule { }