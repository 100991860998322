import { JeuState } from '../states/jeu.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectJeu = (state) => state.jeu;

export const selectJeuId = createSelector(
  selectJeu,
  (state: JeuState) => state.id
);

export const selectNouvelleAnalyseMarche = createSelector(
  selectJeu,
  (state: JeuState) => state.nouvelleAnalyseMarche
);