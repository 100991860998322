import { Message } from './message';
import { Historique } from './historique';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';

export interface DebriefElement{
  id?: number,
  typeDebrief?: string,
  content?: Message | Historique | any,
  debriefChecked?: boolean,
  createdAt?: Date,
}

@Injectable({
  providedIn: 'root'
})
export class DebriefElementAdapter implements Adapter<DebriefElement>{
  adapt(data: any){
    return {
      'id': data['id'],
      'typeDebrief': data['typeDebrief'],
      'content': data,
      'debriefChecked': data['debriefChecked'],
      'createdAt': data['createdAt'] ? new Date(data['createdAt']) : null
    } as DebriefElement;
  }
}
