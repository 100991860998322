import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const matchPasswordValidator :ValidatorFn=(control : AbstractControl) : ValidationErrors | null =>{
    const password = control.get('password');
    const confirm_password = control.get('confirm_password');
    if(confirm_password.value){
      return password && confirm_password && password.value !== confirm_password.value ? {passNotMatch : true} : null;
    }else{
      return null;
    }
}