import { Component, Input, OnInit } from '@angular/core';
import { timer } from "rxjs";

@Component({
  selector: 'app-chronometre',
  templateUrl: './chronometre.component.html',
  styleUrls: ['./chronometre.component.scss']
})

export class ChronometreComponent implements OnInit {

  @Input() dateDepart?: Date;

  timerDisplay : any = {
    hours: 0,
    minutes: { digit1: 0, digit2: 0 },
    seconds: { digit1: 0, digit2: 0 },
  };


  time = 0;
  currentDate : Date = new Date();
  temp;

  constructor() { }

  ngOnInit() {

    this.time = this.calculateDiff(this.dateDepart);
    timer(0, 1000).subscribe(ellapsedCycles => {
        this.time++;
        if(this.time>=0){
          this.timerDisplay = this.getDisplayTimer(this.time)
        }
        else{
          this.time = this.calculateDiff(this.dateDepart);
        }
    });

  }

  getDisplayTimer(time: number) {
    const hours = Math.floor(time / 3600);
    const minutes = '0' + Math.floor(time % 3600 / 60);
    const seconds = '0' + Math.floor(time % 3600 % 60);

    return {
      hours: hours,
      minutes: { digit1: minutes.slice(-2, -1), digit2: minutes.slice(-1) },
      seconds: { digit1: seconds.slice(-2, -1), digit2: seconds.slice(-1) },
    };
  }

  calculateDiff(dateSent: Date){
    let currentDate = new Date();

    dateSent = new Date(dateSent);



    var seconds = (currentDate.getTime() - dateSent.getTime()) / 1000;
    return Math.floor( seconds );

  }
}
