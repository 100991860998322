<mat-list-item>
  <div class="container">

    <div class="row">
      <div class="col-4 description-joueur">
        <a [routerLink]="goTo(joueur.id)"><span> {{ joueur.equipe.nom }}, </span> {{ joueur.type['traductionKey'] | translate }} </a>
      </div>
      <div class="col-8">
        <div class="row">


          <div class="col-6">

            <mat-form-field>
              <mat-label>{{ 'joueur_reel' | translate }}</mat-label>
            <mat-select [formControl]="joueurReel" (selectionChange)="assignJoueur()" [disabled]="loadingAssignJoueur || !canEdit">
              <mat-option [value]=""></mat-option>
              <mat-option [value]="joueurReelTemplate.id" *ngFor="let joueurReelTemplate of allFreeUsers; let i=index" [disabled]="assignedUsers.indexOf(joueurReelTemplate.id) != -1">{{ joueurReelTemplate.username }}</mat-option>
            </mat-select>
            </mat-form-field>
            <span class="spinner-border spinner-border-sm" *ngIf="loadingAssignJoueur"></span>
          </div>
          <div class="col-6">

            <!-- <button mat-raised-button color="primary" class="valider-joueur-reel" [disabled]="loadingAssignJoueur" (click)="assignJoueur()" *ngIf="joueurReel.value">
              <span class="spinner-border spinner-border-sm" *ngIf="loadingAssignJoueur"></span>
              Valider
            </button> -->
            <mat-label>Email (pour envoi)</mat-label>
            <input type="email" class="form-control" placeholder="{{ 'email_joueur' | translate }}" [formControl]="emailSending"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</mat-list-item>
<hr>
