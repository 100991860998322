<div>
    <mat-table class="lessons-table" matSort matSortActive="id" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource">
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
            <mat-cell *matCellDef="let ressource">{{ ressource.id }}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="ressource_nom">
            <mat-header-cell *matHeaderCellDef>Ressources</mat-header-cell>
            <mat-cell *matCellDef="let ressource">{{ ressource.nom }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ressource_type">
          <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
          <mat-cell *matCellDef="let ressource">{{ ressource.type.nom }}</mat-cell>
      </ng-container>
    
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let ressource">
                <button mat-icon-button color="warning" [routerLink]="['/ressource/edit', ressource.id]">
                    <mat-icon>build</mat-icon>
                </button>
                <button mat-icon-button color="danger" (click)="triggerModal(modalData)">
                    <mat-icon>close</mat-icon>
                </button>
                <ng-template #modalData let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Supprimer </h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p><strong>Voulez-vous vraiment supprimer <span class="text-primary">"{{ressource.nom}}"</span></strong></p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">{{ 'fermer' | translate }}</button>
                      <button type="button" mat-raised-button color="primary" (click)="onDelete(ressource.id); modal.close('Save click')">Confirmer la suppression</button>
                    </div>
                  </ng-template>
            </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row;columns : displayedColumns"></mat-row>
    
    </mat-table>
    <mat-paginator [length]="dataSource.total" [pageSize]="defaultPageSize" [pageSizeOptions]="[5,10,20]"></mat-paginator>
    <div class="row">
        <div class="col text-center">
          <button type="button" routerLink="/ressource/create" mat-raised-button color="primary">Créer un nouveau ressource</button>
        </div>
      </div>
    </div>
    
