import { Adapter } from '@models/adapter';
import { Injectable } from '@angular/core';
import { TypeEquipe, TypeEquipeAdapter } from '@models/type-equipe';
import { StudentAdapter } from './student';

export interface TypeJoueur{
  id: string,
  iri?: string,
  nom?: string,
  traductionkey?: string,
  description?: Text,
  typeEquipe?: TypeEquipe
}

@Injectable({
  providedIn: 'root'
})
export class TypeJoueurAdapter implements Adapter<TypeJoueur>{
  constructor(
    private typeEquipeAdapter: TypeEquipeAdapter
  ){

  }
  adapt(data: any): TypeJoueur{
    const typeJoueur = {
      'id': data['id'],
      'iri': data['@id'],
      'nom': data['nom'],
      'traductionKey': data['traductionKey'],
      'description': data['description'],
      'typeEquipe': data['typeEquipe'] ? this.typeEquipeAdapter.adapt(data['typeEquipe']) : null
    }

    return typeJoueur as TypeJoueur;
  }
}
