import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { TypeEquipe } from './type-equipe';
import { TypeEquipeAdapter } from "@models/type-equipe";
import { TypeEquipeService } from '@services/type-equipe.service';
import { Observable } from 'rxjs';
import { TypeJeuService } from '@services/type-jeu.service';
export interface TypeJeu{
  id?: number,
  '@id'?: string,
  iri?: string,
  logo?: string,
  duree: number,
  nbTours: number,
  nom: string,
  description?: Text,
  typeEquipes?:  TypeEquipe[],
}

@Injectable({
  providedIn: 'root'
})
export class TypeJeuAdapter implements Adapter<TypeJeu>{
  constructor(private typeEqAdapt : TypeEquipeAdapter){}

  transformMinToHour(min: number): number{
    return Math.floor(min/60);
  }
  adapt(data: any): TypeJeu{
    const TypeJeu = {
      'id': data.id,
      '@id': data["@id"],
      'iri': data['@id'],
      'logo': data.logo,
      'duree': this.transformMinToHour(data.duree),
      'nbTours': data.nbTours,
      'nom': data.nom,
      'description': data.description,
      'typeEquipes': data.typeEquipes as TypeEquipe[],
    }

    return TypeJeu as TypeJeu;
  }
}
