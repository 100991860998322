import { Component, OnInit } from '@angular/core';
import { User } from '@models/user';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-animateur-list',
  templateUrl: './animateur-list.component.html',
  styleUrls: ['./animateur-list.component.scss']
})
export class AnimateurListComponent implements OnInit {

  animateurList$: Observable <User[]>;
  
  constructor(
    private userService: UserService, 
  ) { }

  ngOnInit(): void {
    this.animateurList$ = this.userService.getAnimateurList();
  }

}
