import { Jeu } from './jeu';
import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export interface Historique{
  id?: number,
  titre?: string,
  description?: string,
  tour?: number,
  jeu?: Jeu,
  type?: string,
  published?: boolean,
  messageData?: any,
  createdAt?: Date
}


@Injectable({
  providedIn: 'root'
})
export class HistoriqueAdapter implements Adapter<Historique>{
  adapt(data: any){
    return {
      'id': data['id'],
      'titre': data['titre'],
      'description': data['description'],
      'tour': data['tour'],
      'jeu': data['jeu'] as Jeu,
      'type': data['type'],
      'published': data['published'],
      'messageData': JSON.parse(data['messageData']),
      'createdAt': new Date(data['createdAt'])
    }
  }
}
