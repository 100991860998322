<div>
  <mat-table class="lessons-table"  matSort matSortActive="id" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header="id"></mat-header-cell>
      <mat-cell *matCellDef="let type">{{type.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="logo">
      <mat-header-cell *matHeaderCellDef mat-sort-header="logo"></mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let type">
        <img [src]="contentUrl(type)" alt="logo" width="64px">
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nom">
      <mat-header-cell *matHeaderCellDef mat-sort-header="nom"></mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let type">{{type.nom}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type_jeu_nom">
      <mat-header-cell *matHeaderCellDef>Types de jeux</mat-header-cell>
      <mat-cell *matCellDef="let type"><img [src]="contentUrl(type)" alt="logo" width="64px"> {{ type.nom }}</mat-cell>
  </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let type">
        <button mat-icon-button color="warning" [routerLink]="['/type-jeu/update',type.id]">
          <mat-icon>build</mat-icon>
        </button>
        <button mat-icon-button color="danger" (click)="triggerModal(modalData)">
          <mat-icon>close</mat-icon>
        </button>

        <!-- <mat-slide-toggle *ngIf="isAdmin()" (change)="toggleActive($event, user.active, user.id)" [checked]="user.active" [disabled]="loading && currentId == user.id">Actif</mat-slide-toggle>
        <span class="text-danger" *ngIf="errorForbidden && currentId == user.id">Vous ne pouvez pas modifier cette ressource</span> -->

        <ng-template #modalData let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Delete </h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p><strong>Are you sure you want to delete <span class="text-primary">"{{type.nom}}"</span> profile?</strong></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Close</button>
            <button type="button" class="btn btn-danger" (click)="onDelete(type.id); modal.close('Save click')">Delete</button>
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator #paginator [length]="dataSource.total" [pageIndex]="0" [pageSize]="3" [pageSizeOptions]="[1, 10, 20, 30]"></mat-paginator>
  <div class="row ">
    <div class="col text-center">
      <button type="button" routerLink="/type-jeu/create" mat-raised-button color="primary">Créer un nouveau type de jeu</button>
    </div>
  </div>
</div>
