import { HttpClient } from '@angular/common/http';
import { DroitMessagerieRequest, DroitMessagerieRequestAdapter} from './../models/droitmessagerieRequest';
import { Injectable } from '@angular/core';
import { Equipe } from '../models/equipe';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Lister } from './listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class DroitMessagerieService extends Lister<DroitMessagerieRequest> {


  constructor(
    protected http : HttpClient,
    private adaptor: DroitMessagerieRequestAdapter
  ) {
    super(http, adaptor);
    this.setListUri('api/droit_messageries');
  }

  addDroitMessagerie(id1,id2){
    var equipeIRI = "/api/equipes/";
    var url = `${environment.apiUrl}/api/droit_messageries`
    var droitMessagerieRequest = {
      source: equipeIRI+id1,
      destination: equipeIRI+id2
    };

    return this.http.post<DroitMessagerieRequest>(url, droitMessagerieRequest).pipe(
      map((data: any) => this.adaptor.adapt(data))
    ).subscribe();
  }

  delete(id){
    return this.http.delete(`${environment.apiUrl}/api/droit_messageries/${id}`)
  }



}



