import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { Jeu } from '@models/jeu';
import { Joueur } from '@models/joueur';
import { JeuService } from '@services/jeu.service';
import { JoueurService } from '@services/joueur.service';

import { environment } from '@environments/environment';
import { Store, select } from '@ngrx/store';
import { Observable, forkJoin, merge } from 'rxjs';
import { selectJeuId, selectJeu } from '@app/core/selectors/jeu.selector';
import { selectUser } from '@app/core/selectors/user.selector';
import { UserService } from '@services/user.service';
import { nouvelleAnalyseMarcheAction, playJeuAction } from '@app/core/actions/jeu.actions';
import { selectAppJoueurAction, selectAppJeuAction, addAppHistoriqueAction, initAppBudgetAction, loadResultatAction, loadSondageAction, changementVoteAction, virementAction, changementNouveauxProduitsAction, etapeSuivanteAction, addAppDebriefElementAction, setUnseenAction, loadDecisionsAction, loadVoteAction, startPitchAction, setSeenRoomAction } from '@app/core/actions/app.actions';

import * as io from 'socket.io-client';
import { addMessageAction } from '@app/core/actions/message.actions';
import { Message } from '@models/message';
import { Room } from '@models/room';
import { MessageService } from 'primeng/api';
import { SharedToastService } from '@services/shared/shared-toast.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Historique } from '@models/historique';
import { selectMessages } from '@app/core/selectors/message.selector';
import { SituationFinanciere } from '@models/situation-financiere';
import { EntrepriseService } from '@services/entreprise.service';
import { Budget } from '@models/budget';
import { tourResultat, tourBudgetHacker } from '@utils/jeu-utils';
import { EquipeService } from '@services/equipe.service';
import { selectAppLoadResultat, selectUnseenRooms } from '@app/core/selectors/app.selector';
import { DebriefElement } from '@models/debrief-element';
import { map, distinctUntilChanged, filter } from 'rxjs/operators';
import { ActionJeu } from '@models/action-jeu';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@models/user';
import { MessagerieService } from '@services/messagerie.service';

@Component({
  selector: 'app-layout-joueur',
  templateUrl: './layout-joueur.component.html',
  styleUrls: ['./layout-joueur.component.scss'],
  providers: [MessageService],
})
export class LayoutJoueurComponent implements OnInit {
  descriptionPerso: BsModalRef;
  @ViewChild('descriptionPersoModal') descriptionPersoModal: TemplateRef<any>;
  @ViewChild('dossierPersonnel') dossierPersonnel;
  @ViewChild('tchatContent') tchatContent: ElementRef;

  jeu: Jeu;
  infoUser;
  jeuIsEmpty: boolean = false;
  idJoueur;
  joueur: Joueur;
  socket;
  startedTime: Date;
  infoEquipe: Object = {
    titreResultat: '',
    fakeResultat: '30',
    fakeIncrease: 0,
  };

  jeuId$: Observable<number>;
  jeu$: Observable<any>;
  user$: Observable<any>;
  user: User;

  launchedGetJoueur = false;
  launchedGetJeu = false;
  phasesJeu = [];
  activePhase: string = '';
  isAnimateur = false;
  hasBreakingNews = false;
  breakingNew = null;
  historiques: Historique[];
  lastMessages: Message[] = [];
  layoutData: any;
  modalRef: BsModalRef;
  lastPhase: ActionJeu;

  situationFinanciereActuelle: SituationFinanciere;
  situationFinancierePrecedente: SituationFinanciere;

  unseenMessage: number = 0;

  activeLang = 'fr';
  translatedText: any = {};

  isMessagerie: boolean = false;
  openedModalMessagerie: boolean = false;

  rooms: Room[] = [];
  room: Room = null;
  roomMessages: Object = {};
  loadingLogout: boolean = false;

  constructor(
    private jeuService: JeuService,
    private authService: AuthenticationService,
    private joueurService: JoueurService,
    private userService: UserService,
    private messageService: MessageService,
    private sharedToastService: SharedToastService,
    private modalService: BsModalService,
    private messagerieService: MessagerieService,
    private router: Router,
    private store: Store,
    private entrepriseService: EntrepriseService,
    private equipeService: EquipeService,
    private translate: TranslateService
  ) {
    this.jeuId$ = this.store.pipe(select(selectJeuId));
    this.jeu$ = this.store.pipe(select(selectJeu));
    this.user$ = this.store.pipe(select(selectUser));
    this.translate
      .stream([
        'toast_nouvelle_element_journal',
        'toast_nouvelle_element_descri',
        'toast_nouveaux_produit',
        'toast_nouveaux_produit_descri',
        'toast_nouveaux_sondages',
        'toast_nouveaux_sondages_descri',
        'toast_reponse_sondages',
        'toast_reponse_sondages_descri',
        'toast_vote_change',
        'toast_vote_change_descri',
        'toast_nouveaux_produits_change',
        'toast_nouveaux_produits_change_descri',
        'toast_virement',
        'toast_virement_descri',
        'toast_etapes_suivantes',
        'toast_etapes_suivantes_descri',
        'toast_attack',
        'toast_attack_descri1',
        'toast_attack_descri2',
        'toast_demande_communication',
        'toast_demande_communication_descri',
      ])
      .subscribe((resultat) => {
        this.translatedText = resultat;
      });
  }

  ngOnInit(): void {
    this.checkUrl();
    this.checkAnimateur();
    this.store.pipe(select(selectUnseenRooms)).subscribe((data) => {
      console.log('unseen room', data);
      this.unseenMessage = data.length;

      data.forEach(roomId => {
        this.roomMessages[roomId] = {...this.roomMessages[roomId], 'unseen': true};
      });
    });

    this.jeu$.subscribe((jeu) => {
      if (jeu.id != 0) {
        if (!this.launchedGetJeu) {
          this.getJeu(jeu.id);
          this.launchedGetJeu = true;
        }

        if (!this.isAnimateur) {
          if (!this.launchedGetJoueur) {
            this.getJoueur();

            this.launchedGetJoueur = true;
          }
        }
      }
    });
    this.getUserInfo();

    this.initFakeTimer();
    this.initSharedToast();

    this.loadLastMessages();

    merge(
      this.store.pipe(
        map((state) => state['app']['loadDecisions']),
        distinctUntilChanged()
      ),
      this.store.pipe(
        map((state) => state['app']['loadResultat']),
        distinctUntilChanged()
      )
    ).subscribe(() => {
      this.loadDataLayout();
    });
  }

  /**
   * Changement des url
   */
  checkUrl(){
    let url = this.router.url;
    console.log('url', url);
    if(url == '/layout/joueur/messagerie'){
      this.isMessagerie = true;
    }else{
      this.isMessagerie = false;
    }
  }

  changeLanguage(e, lang: string) {
    e.preventDefault();
    console.log('change language');
    this.activeLang = lang;
    this.translate.use(lang);
  }

  get tourResultat() {
    return tourResultat(this.jeu.tour, this.jeu.currentAction?.type);
  }
  /**
   * Initialise les données nécessaires pour le layout
   * A besoin que this.joueur ne soit pas null
   */
  loadDataLayout() {
    if (!this.joueur || !this.jeu) {
      return null;
    }

    console.log('load data layout ');
    if (this.joueur.equipe['type']['ecosysteme'] == 'entreprise') {
      let tourReelResultat = tourResultat(
        this.jeu.tour,
        this.jeu.currentAction?.type
      );

      forkJoin({
        situationFinancierePrecedente:
          this.entrepriseService.getSituationFinanciere(
            this.joueur.equipe.id,
            this.jeu.tour - 1
          ),
        situationFinanciereActuelle:
          this.entrepriseService.getSituationFinanciere(
            this.joueur.equipe.id,
            this.jeu.tour
          ),
        layoutData: this.entrepriseService.getDataLayoutEntreprise(
          this.joueur.equipe.id
        ),
      }).subscribe((data) => {
        /**
         * 1) Situation Financiere precedente
         */
        let situationFinancierePrecedente = data.situationFinancierePrecedente;
        this.situationFinancierePrecedente = situationFinancierePrecedente;

        /**
         * 2) Situation financiere actuelle
         */
        this.situationFinanciereActuelle = data.situationFinanciereActuelle;

        /**
         * 3) Layout data
         */
        let layoutData = data.layoutData;
        this.layoutData = layoutData;

        /**
         * COMMUN
         */
        // On initialise le budget, notamment l'ebitda, le revenues, et le cashflowTotal
        // Utilisé pour le calcul dans nouveaux produits, produits cyber et virements entreprise
        let budgetPrecedent = {
          ebitda: situationFinancierePrecedente.ebitda,
          revenues: situationFinancierePrecedente.revenues,
          itCosts: -(
            situationFinancierePrecedente.estimationItCostsLegacy +
            situationFinancierePrecedente.estimationItCostsNewOperations
          ),
          total: situationFinancierePrecedente.cashflowTotal,
          depenses: 0,
          reste: situationFinancierePrecedente.cashflowTotal,
          coutProduitInnovation: layoutData['coutProduitInnovation'],
          coutProduitCyber: layoutData['coutProduitCyber'],
          lignesDepenses: {},
        } as Budget;

        console.log('budget before dispatch', budgetPrecedent);
        console.log(situationFinancierePrecedente);

        this.store.dispatch(initAppBudgetAction({ budget: budgetPrecedent }));
      });

      this.loadEntrepriseResultatStockPrice();
    } else if (this.joueur.equipe['type']['ecosysteme'] == 'client') {
      this.loadClientResultatShareHashtag();
    } else if (this.joueur.equipe['type']['ecosysteme'] == 'etat') {
      let tourReelResultat = tourResultat(
        this.jeu.tour,
        this.jeu.currentAction?.type
      );

      this.equipeService
        .getSituationFinanciereEtat(this.joueur.equipe.id, tourReelResultat)
        .subscribe((situationFinanciere) => {
          let budget = {
            total: situationFinanciere['cashflow_end'],
            reste: situationFinanciere['cashflow_end'],
            depenses: 0,
            lignesDepenses: {}
          };

          this.store.dispatch(initAppBudgetAction({ budget: budget }));
        });

      this.loadEtatResultatPopularite();
    } else if (this.joueur.equipe['type']['ecosysteme'] == 'menace') {
      // let tourReelBudgetHacker = tourResultat(
      //   this.jeu.tour,
      //   this.jeu.currentAction?.type
      // );


      this.store.pipe(map(state => state['app']['startPitch']), filter((val, index) => val != 0), distinctUntilChanged()).subscribe(() => {
        this.getSituationFinanciereHacker();
      });

      this.getSituationFinanciereHacker();

      this.loadHackerResultat();
    }
  }

  getSituationFinanciereHacker(){
    let tourReelBudgetHacker = this.jeu.tour-1;
    this.equipeService.getSituationFinanciereHacker(this.joueur.equipe.id, tourReelBudgetHacker).subscribe((situationFinanciere) => {
      let budget = {
        total: situationFinanciere['cashflowEnd'],
        reste: situationFinanciere['cashflowEnd'],
        depenses: 0,
        lignesDepenses: {},
        virements: situationFinanciere['virements'],
        gain: situationFinanciere['gain']
      };

      console.log('budget layout data', budget);
      this.store.dispatch(initAppBudgetAction({ budget: budget }));
    });
  }

  loadHackerResultat() {
    let tourReelResultat = tourResultat(
      this.jeu.tour,
      this.jeu.currentAction?.type
    );
    let infoEquipe = this.equipeService
      .getInfoHacker(this.joueur.equipe.id, tourReelResultat)
      .subscribe((infoEquipe) => {
        this.infoEquipe['resultat'] = infoEquipe['resultat'] * 1000;
        this.infoEquipe['increase'] = infoEquipe['increase'];
      });
  }

  loadEtatResultatPopularite() {
    let tourReelResultat = tourResultat(
      this.jeu.tour,
      this.jeu.currentAction?.type
    );
    let infoEquipe = this.equipeService
      .getInfoEtat(this.joueur.equipe.id, tourReelResultat)
      .subscribe((infoEquipe) => {
        this.infoEquipe['resultat'] = infoEquipe['resultat'];
        this.infoEquipe['increase'] = infoEquipe['increase'];
      });
  }

  /**
   * Loader les donnes du client
   */
  loadClientResultatShareHashtag() {
    let tourReelResultat = tourResultat(
      this.jeu.tour,
      this.jeu.currentAction?.type
    );
    let infoEquipe = this.equipeService
      .getInfoClient(this.joueur.id, tourReelResultat)
      .subscribe((infoEquipe) => {
        this.infoEquipe['resultat'] = infoEquipe['resultat'];
        this.infoEquipe['increase'] = infoEquipe['increase'];
      });
  }

  /**
   * Pour loader les donnees resultat stock price pour l'entreprise (en haut à droite)
   */
  loadEntrepriseResultatStockPrice() {
    let tourReelResultat = tourResultat(
      this.jeu.tour,
      this.jeu.currentAction?.type
    );
    let infoEquipe = this.entrepriseService
      .getInfoEntreprise(this.joueur.equipe.id, tourReelResultat)
      .subscribe((infoEquipe) => {
        this.infoEquipe['resultat'] = infoEquipe['resultat'];
        this.infoEquipe['increase'] = infoEquipe['increase'];
      });
  }

  initSharedToast() {
    this.sharedToastService.sharedToastMessage.subscribe((message) => {
      this.messageService.add({
        ...message,
        life: 3000,
      });
    });
  }

  checkAnimateur() {
    this.isAnimateur = this.authService.isAnimateur();
  }

  initFakeTimer() {
    const today = new Date();

    this.startedTime = new Date(today.getTime() + 20 * 60000);
  }

  getUserInfo() {
    const userId = this.authService.currentUserInfo?.id;

    this.userService.getUser(userId.toString()).subscribe(
      (user) => {
        this.user = user;
        if (user.jeuActuel) {
          this.store.dispatch(playJeuAction({ id: user.jeuActuel.id }));
        } else {
          this.router.navigate(['/list-jeux-user']);
        }
      },
      (error) => {
        this.authService.logout();
      }
    );
  }

  getAllJeu() {
    this.jeuService.getAll().subscribe((jeu) => {
      this.jeu = jeu[0];
      if (this.jeu == null) {
        this.jeuIsEmpty = true;
      }

      if (this.jeu.started == true) {
        this.router.navigate(['/layout/joueur/page-dashboard-jeu']);
      }
    });
  }

  getJeu(id: number) {
    console.log('getJeu layout');
    this.jeuService.getOne(id).subscribe((jeu) => {
      this.jeu = jeu;

      if(this.jeu && this.jeu.finished){
        this.logout();
      }

      this.breakingNew = jeu.currentBreakingNew;
      this.store.dispatch(selectAppJeuAction({ jeu: this.jeu }));
      console.log('this jeu from getJeu', this.jeu);
      this.registerAnimationJeuListener();

      // double appel (ici et dans getJoueur), forkJoin à faire?
      this.loadDataLayout();
      if (this.jeu.currentAction) {
        this.activePhase = this.jeu.currentAction.type;
      }
    });
  }

  getJoueur() {
    const userId = this.authService.currentUserInfo.id;
    this.joueurService.getJoueurOfUser(userId).subscribe((joueur) => {
      this.joueur = joueur;
      // console.log(this.joueur);
      this.store.dispatch(selectAppJoueurAction({ joueur: this.joueur }));

      this.loadDataLayout();
      this.loadRooms();

      this.setInformationsEquipe(this.joueur.equipe['type']['ecosysteme']);
    });
  }

  getPhases() {
    this.jeuService
      .getPhases(this.jeu.id, this.jeu.tour)
      .subscribe((actionsJeu) => {
        console.log('actionsJeu from getPhases', actionsJeu);
        this.phasesJeu = actionsJeu;
      });
  }

  setInformationsEquipe(ecosysteme: string) {
    switch (ecosysteme) {
      case 'entreprise':
        this.infoEquipe['titreResultat'] = 'Cours de Bourse';
        this.infoEquipe['fakeResultat'] = '101.34';
        this.infoEquipe['fakeIncrease'] = 0.3;
        break;
      case 'etat':
        this.infoEquipe['titreResultat'] = 'Popularité (en %)';
        this.infoEquipe['fakeResultat'] = '40%';
        this.infoEquipe['fakeIncrease'] = -27;

        break;
      case 'menace':
        this.infoEquipe['titreResultat'] = 'Gain';
        this.infoEquipe['fakeResultat'] = '1250';
        this.infoEquipe['fakeIncrease'] = 417;
        break;
      case 'client':
        this.infoEquipe['titreResultat'] = 'Influence (en %)';
        this.infoEquipe['fakeResultat'] = '65.5%';
        this.infoEquipe['fakeIncrease'] = 50.5;
        break;
      default:
        break;
    }
  }

  registerAnimationJeuListener() {
    if (!sessionStorage.getItem('currentUser')) {
      this.router.navigate(['/login']);
    }

    if (!this.socket) {
      this.socket = io.io(environment.socketIoUrl, {
        auth: {
          token: JSON.parse(sessionStorage.getItem('currentUser'))['token'],
        },
      });
    }
    this.socket.on('message', (data) => {
      console.log('received message', data['message']);

      let message = {
        text: data['message']['text'],
        joueur: data['message']['joueur'],
        createdAt: new Date(data['message']['createdAt']),
      } as Message;

      let room = data['message']['room'] as Room;
      // if(typeof(room["id"]))
      console.log(room);

      this.store.dispatch(addMessageAction({ message: message, room: room }));

      // Adding debriefElement
      let debriefElement = {
        typeDebrief: 'message',
        content: data['message'],
        debriefChecked: false,
        createdAt: data['message']['createdAt'] as Date,
      } as DebriefElement;
      console.log('debrief to add ', debriefElement);

      this.store.dispatch(
        addAppDebriefElementAction({ debriefElement: debriefElement })
      );
      this.store.dispatch(setUnseenAction({ roomId: room.id }));
    });

    this.socket.on('notify', (data) => {
      switch (data['key']) {
        case 'changement-phase':
          console.log('notified ', data);
          if (data['jeu']) {
            this.jeu = data['jeu'];
            this.lastPhase = this.jeu.currentAction;
            this.updatePhase();
          }
          break;

        case 'changement-tour':
          console.log('changement tour detected', data);
          if (data['jeu']) {
            this.jeu = data['jeu'];
            this.store.dispatch(selectAppJeuAction({ jeu: this.jeu }));
            console.log('load resultat changement tour');
            this.store.dispatch(loadResultatAction());
            this.updatePhase();
          }
          this.breakingNew = null;

          break;
        case 'terminer-phase':
          console.log('terminer-phase detected', data);
          if (data['jeu']) {
            this.jeu = data['jeu'];
            this.store.dispatch(selectAppJeuAction({ jeu: this.jeu }));

            if (
              data['jeu']['currentAction'] &&
              data['jeu']['currentAction']['type'] == 'resultats'
            ) {
              console.log('load resultat terminer phase');
              this.store.dispatch(loadResultatAction());
            }

            // Passage vers les votes
            if (
              data['jeu']['currentAction'] &&
              data['jeu']['currentAction']['type'] == 'votes'
            ) {
              console.log('load vote terminer phase');
              this.store.dispatch(loadVoteAction());
            }

            // Passage vers decisions
            if(
              data['jeu']['currentAction'] &&
              data['jeu']['currentAction']['type'] == 'decisions'
            ){
              this.store.dispatch(loadDecisionsAction());
            }

            // Passage de decisions vers pitchs
            console.log('current action is', this.lastPhase?.type);
            console.log('next action is', data['jeu']['currentAction']['type']);

            if (
              data['jeu']['currentAction'] &&
              data['jeu']['currentAction']['type'] == 'pitchs' &&
              this.lastPhase?.type != 'pitchs'
            ) {
              console.log('decisions vers pitchs');
              // this.store.dispatch(loadDecisionsAction());
              this.store.dispatch(startPitchAction());
            }
            this.updatePhase();
          }
          break;
        case 'add-breaking-new':
          console.log('breaking new is here', data);
          this.breakingNew = data['breakingNew'];
          break;
        case 'add-historique':
          console.log('historique is here', data);

          this.store.dispatch(
            addAppHistoriqueAction({ historique: data['historique'] })
          );

          let debriefElement = {
            typeDebrief: 'historique',
            content: data['historique'],
            debriefChecked: false,
            createdAt: data['historique']['createdAt'] as Date,
          } as DebriefElement;
          console.log('debrief to add ', debriefElement);

          this.store.dispatch(
            addAppDebriefElementAction({ debriefElement: debriefElement })
          );

          if (
            data['historique'] &&
            data['historique']['type'] != 'debrief-comment'
          ) {
            this.sharedToastService.toast({
              severity: 'success',
              summary: this.translatedText.toast_nouvelle_element_journal,
              detail: this.translatedText.toast_nouvelle_element_descri,
            });
          }
          break;
        case 'lancement-produits':
          console.log('produits lances', data);
          this.sharedToastService.toast({
            severity: 'success',
            summary: this.translatedText.toast_nouveaux_produit,
            detail: this.translatedText.toast_nouveaux_produit_descri,
          });
          break;
        case 'lancer-annonce':
          console.log('lancer annonce', data);
          if (data['jeu']) {
            this.jeu = data['jeu'];

            if (data['annonce']) {
              this.jeu['currentAnnonce'] = data['annonce'];
              this.jeu = JSON.parse(JSON.stringify(this.jeu)); // for reference
              this.store.dispatch(selectAppJeuAction({ jeu: this.jeu }));
            }
          }
          break;
        case 'add-sondage-entreprise-client':
          console.log('add sondage entreprise', data);

          this.sharedToastService.toast({
            severity: 'success',
            summary: this.translatedText.toast_nouveaux_sondages,
            detail: this.translatedText.toast_nouveaux_sondages_descri,
          });
          this.store.dispatch(loadSondageAction());
          this.store.dispatch(nouvelleAnalyseMarcheAction({ is_new: true }));
          break;
        case 'reponse-sondage-client-entreprise':
          this.sharedToastService.toast({
            severity: 'success',
            summary: this.translatedText.toast_reponse_sondages,
            detail: this.translatedText.toast_reponse_sondages_descri,
          });
          this.store.dispatch(loadSondageAction());
          break;
        // I uncomment this because the votes should be per 'Joueur', not per 'Equipe'
        // case 'changement-vote':
        //   this.sharedToastService.toast({
        //     severity: 'info',
        //     summary: this.translatedText.toast_vote_change,
        //     detail: this.translatedText.toast_vote_change_descri,
        //   });
        //   this.store.dispatch(changementVoteAction());
        //   break;
        case 'changement-nouveaux-produits':
          this.sharedToastService.toast({
            severity: 'info',
            summary: this.translatedText.toast_nouveaux_produits_change,
            detail: this.translatedText.toast_nouveaux_produits_change_descri,
          });
          this.store.dispatch(changementNouveauxProduitsAction());
          break;
        case 'virement':
          this.sharedToastService.toast({
            severity: 'info',
            summary: this.translatedText.toast_virement,
            detail: this.translatedText.toast_virement_descri,
          });
          this.store.dispatch(virementAction());
          break;
        case 'nouvelle-etape-operation':
          this.sharedToastService.toast({
            severity: 'info',
            summary: this.translatedText.toast_etapes_suivantes,
            detail: this.translatedText.toast_etapes_suivantes_descri,
          });
          this.store.dispatch(etapeSuivanteAction());
          break;
        case 'attaque-success':
          let typeAttaque = '';
          if (data['attaque'] && data['attaque']['type']) {
            typeAttaque = data['attaque']['type']['slug'];
          }
          this.sharedToastService.toast({
            severity: 'warn',
            summary: '',
            detail: `${this.translatedText.toast_attack_descri1} ${typeAttaque}. ${this.translatedText.toast_attack_descri2}`,
          });
          break;
        case 'evenement-hacker-cible':
          if (data['message'] && data['severity']) {
            this.sharedToastService.toast({
              severity: data['severity'],
              summary: 'Hacker',
              detail: data['message'],
            });
          }
          break;
        case 'demande-communication-public':
          this.sharedToastService.toast({
            severity: 'info',
            summary: this.translatedText.toast_demande_communication,
            detail: `${data.declaration.createur.nom} ${this.translatedText.toast_demande_communication_descri}`,
          });
          console.log('Data from socket', data);
          break;
        case 'jeu-termine':
          console.log('jeu is termine');
          this.logout();
          break;
        default:
          break;
      }
    });
  }

  updatePhase() {
    this.activePhase = this.jeu.currentAction?.type;
  }

  contentUrl(url: string) {
    return `${environment.apiUrl}/${url}`;
  }

  navigateToDossier() {
    alert(window.location.origin);
  }

  onPhaseFinished() {
    console.log('phase is finished');
  }

  toast() {
    this.messageService.add({
      severity: 'success',
      summary: 'Service Message',
      detail: 'Via MessageService',
    });
  }

  goToMessagerie(e) {
    e.preventDefault();
    this.router.navigate(['/layout/joueur/messagerie']);
  }

  showDescriptionPerso() {
    this.modalRef = this.modalService.show(this.descriptionPersoModal, {
      class: 'modal-lg',
    });
  }

  closeDescriptionPerso() {
    this.modalRef.hide();
  }

  loadRooms(){
    this.messagerieService.getRoomsOfJoueur(this.joueur.id).subscribe(rooms => {
      this.rooms = rooms;

      this.rooms.forEach(room => {
        this.roomMessages[room.id] = null;
      });
    });
  }

  /**
   * Load les derniers messages qui arrivent
   * On sélectionne les derniers messages de tous les Room
   */
  loadLastMessages() {
    this.store.pipe(select(selectMessages)).subscribe((messages) => {
      this.lastMessages = [];

      console.log('messages rooms', messages);

      // Message contient les rooms, donc m contient une liste de messages
      Object.keys(messages).forEach((k) => {
        let m = messages[k];
        if (m.length > 0) {
          if (this.isAnimateur) {
            this.lastMessages = this.lastMessages.concat(m.slice(-2)); // deux derniers messages
          }

          let othersMessages = [];
          if (this.joueur) {
            othersMessages = m.filter((x) => {
              return x.joueur.id != this.joueur.id;
            });
            this.lastMessages = this.lastMessages.concat(
              othersMessages.slice(-2)
            );

            this.roomMessages[k] = {...othersMessages[othersMessages.length-1]};
          }

          // condition pour qu'on ne notifie pas à droite mes messages
          // if(this.joueur && m[m.length-1].joueur.id != this.joueur.id){
          //   this.lastMessages.concat(m.slice(-2));
          // }
        }
      });

      // Sorting the messages and scrolling
      this.lastMessages = [...this.lastMessages].sort(
        (m1, m2) => m2.createdAt.getTime() - m1.createdAt.getTime()
      );
      if (this.tchatContent) {
        // set timeout because angular has not displayed the new message yet, we let one tick
        setTimeout(() => {
          // this.tchatContent.nativeElement.scrollTop = this.tchatContent.nativeElement.scrollHeight;
          this.tchatContent.nativeElement.scrollTop = 0;
        }, 0);
      }
    });
  }

  navigateToPage() {
    if (this.isAnimateur) {
      this.router.navigate(['/layout/joueur/animateur']).then(() => {
        this.checkUrl();
      });
    } else {
      this.router.navigate(['/layout/joueur/page']).then(() => {
        this.checkUrl();
      });
    }
  }

  telechargerDossierPersonnel() {
    this.dossierPersonnel.nativeElement.click();
    this.modalRef.hide();
  }

  logout() {
    this.loadingLogout = true;
    this.userService.logout().subscribe(() => {
      this.authService.logout();
      // this.loadingLogout = false;
      this.router.navigate(['/login']);
    });
  }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  openRoom(roomId: number){
    console.log('room open');
    this.openedModalMessagerie = true;
    this.room = this.rooms.find(r => {
      return r.id == roomId;
    });

    if(this.room){
      this.roomMessages[this.room.id] = null;
    }

    this.store.dispatch(setSeenRoomAction({ roomId: +this.room.id }));
  }

  closeMessenger(){
    this.openedModalMessagerie = false;

    if(this.room){
      this.roomMessages[this.room.id] = null;
    }
    this.room = null;
  }
}


