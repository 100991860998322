import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BreakingNew } from '@models/breakingNew';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreakingNewService } from '@services/breaking-new.service';

@Component({
  selector: 'app-crud-breaking-news',
  templateUrl: './crud-breaking-news.component.html',
  styleUrls: ['./crud-breaking-news.component.scss']
})
export class CrudBreakingNewsComponent implements OnInit, AfterContentInit {
  @Input("breakingNew") breakingNew : BreakingNew;
  @Output("deleted") deleted : EventEmitter<{deleted: boolean}> = new EventEmitter<{deleted: boolean}>();

  form : FormGroup;
  confirmationModal : NgbModalRef;
  newbreakingNew : BreakingNew;

  loadingEdit : boolean;
  loadingDelete: boolean;
  constructor(
    private service: BreakingNewService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    // console.log(this.breakingNew);
  }


  triggerModal(content) {
    this.newbreakingNew = {...this.breakingNew};
    this.confirmationModal = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    this.confirmationModal.result.then((res) => {;
      // console.log(`Closed with: ${res}`);
      this.confirmationModal.close();
    }, (error) => {
      // console.log("Error");
      this.confirmationModal.close();
    });
  }

  edit(){
    this.loadingEdit = true;
    console.log(this.newbreakingNew);
    // console.log(this.oldbreakingNew);
    this.service.update(this.newbreakingNew).subscribe(
      (result) => {
        console.log("Here is the result", result);
        this.breakingNew = result;
        this.confirmationModal.close();
        this.loadingEdit = false;
      },
      (error) => {
        console.log("There was an error", error);
        this.confirmationModal.close();
        this.loadingEdit = false;
      }
    );

  }

  delete(){
    this.loadingDelete = true;
    this.service.delete(this.breakingNew.id).subscribe(
      (result) => {
        this.deleted.emit({deleted: true});
        this.loadingDelete = false;
        this.confirmationModal.close();
      }
    );
  }

  initForm(){
    this.form = new FormGroup({
      "nom" : new FormControl("", Validators.required),
      "description" : new FormControl("", Validators.required),
    });
    console.log(this.form.get("nom"));
    console.log(this.form.get("description"));
  }

  populateForm(){
    // this.form.patchValue({
    //   "nom" : this.breakingNew.nom,
    //   "description" : this.breakingNew.description
    // });
    this.form.patchValue(this.breakingNew);
  }
}
