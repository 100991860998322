import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Jeu } from '@models/jeu';
import { JeuService } from '@services/jeu.service';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-dashboard-animateur',
  templateUrl: './dashboard-animateur.component.html',
  styleUrls: ['./dashboard-animateur.component.scss']
})
export class DashboardAnimateurComponent implements OnInit {
  jeu: Jeu;
  activatedOnglet: string = 'news';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jeuService: JeuService,
    private authService: AuthenticationService
  ) {

  }

  ngOnInit(): void {
    let idJeu = this.route.snapshot.params.idJeu;
    this.initJeu(idJeu);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  activateOnglet(onglet: string){
    this.activatedOnglet = onglet;
  }

  initJeu(idJeu: number){
    this.jeuService.getOne(idJeu).subscribe(jeu => {
      this.jeu = jeu;
    });

  }
}
