import { Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { MediaObjectService } from '@services/media-object.service';
import { TypeEquipeService } from "@services/type-equipe.service";
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-type-equipe-create',
  templateUrl: './type-equipe-create.component.html',
  styleUrls: ['./type-equipe-create.component.scss']
})
export class TypeEquipeCreateComponent implements OnInit, OnChanges {

  // typeEquipeForm = new FormGroup({
  //   nom: new FormControl('', Validators.required),
  //   description : new FormControl'('', Validators.required),
  //   nbJoueursMin : new FormControl(2, Validators.required),
  //   nbJoueursMax : new FormControl(6, Validators.required)
  // })
  ecosystemes = [
    {
      'slug': 'entreprise',
      'nom': 'Entreprise'
    },
    {
      'slug': 'client',
      'nom': 'Client'
    },
    {
      'slug': 'menace',
      'nom': 'Menace'
    },
    {
      'slug': 'etat',
      'nom': 'Etat'
    }
  ];

  uploadedPhoto: boolean = false;
  // photoTempUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("http://localhost:8000/media/anonyme.png");
  photoTempUrl: SafeResourceUrl;
  typeEquipeForm = this.formBuilder.group({
    nom: [null, Validators.required],
    description: [null, Validators.required],
    nbJoueursMin: [2, Validators.required],
    nbJoueursMax: [6, Validators.required],
    logo: [null],
    logoFile: [null],
    logoSource: [null],
    ecosysteme: [null]
  });

  isSubmitting = false;

  constructor(private formBuilder: FormBuilder,
    private typeEquipeService: TypeEquipeService,
    private mediaObjectService: MediaObjectService,
    private router: Router,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  onFileChange(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.photoTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]))
      this.uploadedPhoto = true;
      // this.photoTempUrl = URL.createObjectURL(event.target.files);
      this.typeEquipeForm.patchValue({
        logoSource: files[0]
      });
    }
  }

  onSubmit() {
    //TODO: Implement treatment of the data

    if (this.typeEquipeForm.valid) {
      this.isSubmitting = true;
      if (this.typeEquipeForm.get('logoSource').value == null) {
        this.typeEquipeService.createTypeEquipe(this.typeEquipeForm.value).subscribe(
          (data: any) => {
            this.router.navigate(['/type-equipes']);
          },
          (error) => null,
          () => {
            this.isSubmitting = false;
          }
        );
      } else {
        const formData = new FormData();
        formData.append("file", this.typeEquipeForm.get('logoSource').value);
        this.mediaObjectService.createMediaObject(formData).subscribe(
          (res: any) => {
            this.typeEquipeForm.patchValue({ "logo": res["@id"] });
            this.typeEquipeService.createTypeEquipe(this.typeEquipeForm.value).subscribe(
              (data: any) => {
                this.router.navigate(['/type-equipes']);
              },
              (error) => null,
              () => {
                this.isSubmitting = false;
              }
            );
            // return res.contentUrl
          }
        );
      }

    }
  }

  contentUrl() {
    if(this.uploadedPhoto) return this.photoTempUrl;
    else return this.sanitizer.bypassSecurityTrustUrl(`${environment.apiUrl}/media/undraw_profile_pic_ic5t.png`);
  }

}
