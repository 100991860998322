<!-- <app-loading-grow *ngIf="loadingAnnonces"></app-loading-grow> -->
<div>

  <ng-container *ngIf="!loadingAnnonces">
    <p-carousel
    [value]="annonces"
    [numVisible]="1"
    [numScroll]="1"
    [circular]="false"
    [page]="page"
    class="carousel-annonces"
    >
    <ng-template pTemplate="header"></ng-template>
    <ng-template let-annonce pTemplate="item">
      <div class="annonce-item-container">
        <app-annonce-jeu-edit-item [annonce]="annonce" [tour]="tour" (deletedAnnonce)="onDeletedAnnonce()" *ngIf="annonce.type != 'add-button'"></app-annonce-jeu-edit-item>
        <div *ngIf="annonce.type == 'add-button'">
          <button mat-mini-fab mat-fab color="primary" (click)="createAnnonce()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </ng-template>
  </p-carousel>
  <!--<button mat-raised-button
  type="button"
  class="mr-2"
  color="primary"
  (click)="createAnnonce()"
  >Créer une annonce</button>-->
</ng-container>

</div>
