import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TypeJoueur } from '@models/type-joueur';
import { TypeJoueurService } from '@services/type-joueur.service';
import { Router } from '@angular/router';
import { TypeEquipeService } from '@services/type-equipe.service';
import { TypeEquipe } from '@models/type-equipe';

@Component({
  selector: 'app-type-joueur-create',
  templateUrl: './type-joueur-create.component.html',
  styleUrls: ['./type-joueur-create.component.scss']
})
export class TypeJoueurCreateComponent implements OnInit {
  typeJoueurForm: FormGroup;
  typeEquipes: TypeEquipe[] = [];
  isSubmitting = false;
  constructor(
    private typeJoueurService: TypeJoueurService,
    private typeEquipeService: TypeEquipeService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.getTypeEquipes();
  }

  getTypeEquipes(){
    this.typeEquipeService.getAll().subscribe(data => {
      this.typeEquipes = data;
    });
  }

  onSubmit(){

    if(this.typeJoueurForm.valid){
      this.isSubmitting = true;
      const formValue = this.typeJoueurForm.value;

      let typeJoueur = formValue as TypeJoueur;
      typeJoueur.typeEquipe = typeJoueur['iriTypeEquipe'];

      this.typeJoueurService.add(typeJoueur).subscribe(
        (data)=>{
            this.router.navigate(['/type-joueur'])
        },
        (error)=>null,
        ()=>{
          this.isSubmitting = false;
        }
      );
    }
  }

  initForm(){
    this.typeJoueurForm = new FormGroup({
      'nom': new FormControl('', Validators.required),
      'description': new FormControl(''),
      'iriTypeEquipe': new FormControl('')
    });
  }
}
