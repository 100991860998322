<button mat-mini-fab color="warn" (click)="deleteAnnonce()" [disabled]="loadingDeleteAnnonce">
  <mat-icon>delete</mat-icon>
</button>
<div class="form-group pt-5">
    <label for="type_annonce">{{ 'type_annonce' | translate }}</label><br>
    <mat-select name="type-annonce" [(ngModel)]="annonce.type" placeholder="{{ 'select_type_annonce' | translate }}" (selectionChange)="changeType()">
        <mat-option value="annonce-cyber">{{ 'annonce_cyber' | translate }}</mat-option>
        <mat-option value="annonce-innovation">{{ 'annonce_innovation' | translate }}</mat-option>
        <mat-option value="annonce-produits-anciens" *ngIf="tour > 1">{{ 'annonce_anciens_produits' | translate }}</mat-option>
        <mat-option value="annonce-produits">{{ 'annonce_nouveaux_produits' | translate }}</mat-option>
        <mat-option value="annonce-lancement-tour">{{ 'annonce_lancement_tour' | translate }}</mat-option>
</mat-select>
</div>

<ng-container *ngIf="annonce.type == 'annonce-cyber' || annonce.type == 'annonce-innovation'">
    <div class="row py-2">
        <div class="col-8">
            <div class="form-group">
                <label>{{ 'titre' | translate }}</label>
                <input type="text" name="titre" [(ngModel)]="annonce.titre" class="form-control" placeholder="Titre ...">
            </div>
        </div>
    </div>
    <div class="row" style="min-height: 200px;">
        <div class="col-8">
            <div class="form-group">
                <label>{{ 'image' | translate }}</label><br>
                <img *ngIf="annonce.image != undefined" [src]="contentUrl()" width="100%" alt="" (click)="inputUpload.click()">
                <input id="photo_annonce" type="file" name="file_annonce" (change)="selectPhoto($event)" [(ngModel)]="photo" #inputUpload [hidden]="annonce.image != undefined">
            </div>

        </div>
        <div class="col-4">
            <div class="form-group h-100">
                <label>Description</label>
                <textarea spellcheck="false" name="description" style="width: 100%;height: 100%;" [(ngModel)]="annonce.description" placeholder="Description..."></textarea>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="annonce.type == 'annonce-lancement-tour'">
    <div class="row py-2">
        <div class="col-9">
            <input type="text" name="titre" [(ngModel)]="annonce.titre" class="form-control">
        </div>
    </div>
    <div class="row">
        <div class="col">
            <img [src]="contentUrl()" width="100%" alt="">
        </div>
    </div>
</ng-container>
<ng-container *ngIf="annonce.type == 'annonce-produits'">
    <div class="row py-2">
        <div class="col-9">
            <input type="text" name="titre" [(ngModel)]="annonce.titre" class="form-control">
        </div>
    </div>
</ng-container>
<button *ngIf="!annonce.id" mat-raised-button (click)="update()"><span
    class="spinner-border spinner-border-sm"
    *ngIf="loading"
  ></span>{{ 'creer' | translate }}</button>
<button *ngIf="annonce.id" mat-raised-button (click)="update()"><span
    class="spinner-border spinner-border-sm"
    *ngIf="loading"
  ></span>{{ 'modifier' | translate }}</button>
