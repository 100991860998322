<div class="container">
  <div class="row" *ngIf="jeu">
    <div class="col-12">
      <form (ngSubmit)="submitEditionAnimation()" [formGroup]="editionForm">
        <div class="form-group">
          <label>Date prévue de début</label>
          <input type="date" class="form-control" formControlName="dateDebut"/>
        </div>

        <div class="form-group">
          <label>Animateur</label>
          <mat-select formControlName="animateur" name="animateur">
            <mat-option></mat-option>
            <mat-option *ngFor="let animateur of allAnimateurs" [value]="animateur.id">{{ animateur.firstname }} {{ animateur.lastname }} ({{ animateur.username}})</mat-option>
          </mat-select>
        </div>
        <button mat-raised-button color="primary" [disabled]="loadingSubmitAnimation">
          <span class="spinner-border spinner-border-sm" *ngIf="loadingSubmitAnimation"></span>
          Valider
        </button>
      </form>
    </div>
  </div>
</div>
