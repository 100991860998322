<div class="division-data"  *ngIf="produit">
    <div class="division-left">
      <div class="user-profil">
        <div class="user-photo">
          <img [src]="photoTempUrl" alt="Image du produit" width="100%" (click)="fileInput.click()">
              <input class="form-control" type="file" id="" (change)="onFileChange($event)" style="opacity: 0;" #fileInput>
        </div>
      </div>
    </div>
    <div class="division-right">
        <div>
            <form [formGroup]="produitForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <!-- <div class="col-md-3">
                        Image produit
                        <label for="photo_produit" class="form-label">
                            <img [src]="photoTempUrl" alt="Image du produit" class="w-100">
                        </label>
                        <input class="form-control" type="file" id="photo_produit" formControlName="photoFile" (change)="onFileChange($event)" style="opacity: 0;">
                    </div> -->
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="nom" class="form-label">{{ 'nom_produit' | translate }}</label>
                            <input type="text" formControlName="nom" class="form-control">
                        </div>
                        <!-- <div class="form-group">
                            <label for="inputType" class="form-label">{{ 'type_produit' | translate }}</label>
                            <select class="form-control" id="inputType"  (change)="onChangeType($event)" formControlName="type">
                                <option *ngFor="let type of types" [ngValue]="type.iri" >{{ type.nom }}</option>
                            </select>
                        </div> -->
                        <div class="form-group">
                          <label for="inputType" class="form-label">{{ 'type_produit' | translate }}</label>
                          <mat-select id="inputType"  (change)="onChangeType($event)" formControlName="type">
                            <mat-option [value]="type.iri" *ngFor="let type of types">{{ type.nom }}</mat-option>
                          </mat-select>
                        </div>
                        <div class="form-group">
                            <label for="description">{{ 'description_produit' | translate }}</label>
                            <textarea spellcheck="false" formControlName="description" id="" class="form-control"></textarea>
                        </div>
                        <!-- <div class="form-group">
                            <label for="cout" class="form-label">Cout</label>
                            <input type="number" formControlName="cout" class="form-control">
                        </div>
                        <div class="form-group" *ngIf="condition1">
                            <label for="espoirGain" class="form-label">Gain espéré</label>
                            <input type="number" formControlName="espoirGain" class="form-control">
                        </div>
                        <div class="form-group" *ngIf="condition2">
                            <label for="espoirGain" class="form-label">Niveau de risque</label>
                            <input type="number" formControlName="niveauRisque" class="form-control">
                        </div> -->
                        <!-- <input type="submit" value="Enregistrer" class="btn btn-primary" [disabled]="produitForm.invalid"> -->
                        <!-- <button class="btn btn-danger">{{ 'annuler' | translate }}</button> -->
                        <div class="form-group" *ngIf="condition2">
                          <label for="costAsPercentIt">{{ 'cout_percent_couts_it' | translate }}</label>
                          <input type="number" formControlName="costAsPercentIt" class="form-control">
                        </div>
                        <button type="submit" mat-raised-button color="primary" [disabled]="produitForm.invalid || isSubmitting">
                            <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
                            {{ 'enregistrer' | translate }}
                        </button>
                        <button type="button" mat-raised-button color="warn" (click)="annuler()">
                          {{ 'retour' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


