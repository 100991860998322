import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TypeJoueurService } from '@services/type-joueur.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeJoueur } from '@models/type-joueur';
import { Observable } from 'rxjs';
import { TypeEquipeService } from '@services/type-equipe.service';
import { TypeEquipe } from '@models/type-equipe';

@Component({
  selector: 'app-type-joueur-edit',
  templateUrl: './type-joueur-edit.component.html',
  styleUrls: ['./type-joueur-edit.component.scss']
})
export class TypeJoueurEditComponent implements OnInit {
  typeJoueurForm: FormGroup;
  typeJoueur: TypeJoueur;
  typeEquipes: TypeEquipe[] = [];

  idTypeJoueur: number;

  loadingTypeJoueur = true;
  loadingTypeEquipes = true;

  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private typeJoueurService: TypeJoueurService,
    private typeEquipeService: TypeEquipeService,
    private router : Router,
  ) { }

  ngOnInit(): void {
    this.idTypeJoueur = this.route.snapshot.params.id;

    this.initForm();
    this.getTypeJoueur(this.idTypeJoueur);
    this.getTypeEquipes();
  }

  getTypeJoueur(id){
    this.typeJoueurService.getOne(id).subscribe(data => {
      this.typeJoueur = data;

      this.patchForm(this.typeJoueur);
      this.loadingTypeJoueur = false;
    });
  }

  getTypeEquipes(){
    this.typeEquipeService.getAll().subscribe(data => {
      this.typeEquipes = data;
      this.loadingTypeEquipes = false;
    });
  }

  onSubmit(){
    let updatedTypeJoueur = this.typeJoueurForm.value;
    this.isSubmitting = true;
    updatedTypeJoueur.typeEquipe = updatedTypeJoueur['iriTypeEquipe'];

    updatedTypeJoueur = updatedTypeJoueur as TypeJoueur;

    this.typeJoueurService.update(this.idTypeJoueur, updatedTypeJoueur).subscribe(
      (data)=>{
          this.router.navigate(['/type-joueur'])
      },
      (error)=>null,
      ()=>{
        this.isSubmitting = false;
      }
    );
  }

  initForm(){
    this.typeJoueurForm = new FormGroup({
      'nom': new FormControl('', Validators.required),
      'description': new FormControl(''),
      'iriTypeEquipe': new FormControl('')
    });
  }

  patchForm(typeJoueur: TypeJoueur){
    this.typeJoueurForm.patchValue({
      'nom': typeJoueur['nom'],
      'description': typeJoueur['description'],
      'iriTypeEquipe': typeJoueur.typeEquipe ? typeJoueur.typeEquipe.iri : ''
    });
  }
}
