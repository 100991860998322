import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { TypeEquipe } from '@models/type-equipe';
import { TypeEquipeService } from '@services/type-equipe.service';
import { MediaObjectService } from '@services/media-object.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TypeJoueur } from '@models/type-joueur';
import { TypeJoueurService } from '@services/type-joueur.service';
import { TypeJoueurLister } from '@services/listers/type-joueur-lister';

@Component({
  selector: 'app-type-equipe-modification',
  templateUrl: './type-equipe-modification.component.html',
  styleUrls: ['./type-equipe-modification.component.scss']
})
export class TypeEquipeModificationComponent implements OnInit {
  private id: number;
  typeEquipe$: Observable<TypeEquipe>;
  public typeEquipeForm: FormGroup;
  public typeEquipe: TypeEquipe;
  uploadedPhoto: boolean = false;
  photoTempUrl: SafeResourceUrl;

  allTypeJoueurs: TypeJoueur[] = [];

  isSubmitting = false;

  ecosystemes = [
    {
      'slug': 'entreprise',
      'nom': 'Entreprise'
    },
    {
      'slug': 'client',
      'nom': 'Client'
    },
    {
      'slug': 'menace',
      'nom': 'Menace'
    },
    {
      'slug': 'etat',
      'nom': 'Etat'
    }
  ];

  constructor(private route: ActivatedRoute,
    private service: TypeEquipeService,
    private mediaObjectService: MediaObjectService,
    private typeJoueurLister: TypeJoueurLister,
    private router: Router,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.typeEquipe$ = this.service.getOne(this.id);
    this.getTypeJoueurs();

    this.typeEquipe$.subscribe(
      (typeEquipe) => {
        this.typeEquipe = typeEquipe;

        let selectedTypeJoueurs = typeEquipe['typeJoueurs'].map(x => x['@id']);
        // Création du formulaire
        this.typeEquipeForm = this.fb.group({
          nom: [typeEquipe.nom, Validators.required],
          description: [typeEquipe.description, Validators.required],
          nbJoueursMin: [typeEquipe.nbJoueursMin, Validators.required],
          nbJoueursMax: [typeEquipe.nbJoueursMax, Validators.required],
          logoFile: [''],
          logoSource: [null],
          typeJoueurs: [],
          ecosysteme: [typeEquipe.ecosysteme]
        });
        console.log('typeEquipe', typeEquipe);
        console.log('typeEquipeForm', this.typeEquipeForm);

        this.typeEquipeForm.patchValue({
          'typeJoueurs': selectedTypeJoueurs
        });
      }
    )
  }

  getTypeJoueurs(){
    this.typeJoueurLister.getAll().subscribe(typeJoueurs => {
      this.allTypeJoueurs = typeJoueurs;
    });
  }

  onFileChange(event) {
    const files= event.target.files;
    if (files.length > 0) {
      this.photoTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]))
      this.uploadedPhoto = true;
      this.typeEquipeForm.patchValue({
        logoSource: event.target.files[0]
      });
    }
  }

  onSubmit() {
    if (this.typeEquipeForm.valid) {
      this.isSubmitting = true;

      // Creation de l'objet typeEquipe à enregistrer
      const id = this.typeEquipe.id;
      this.typeEquipe = this.typeEquipeForm.value as TypeEquipe;
      this.typeEquipe.id = id;
      // Si il n'a pas choisi une nouvelle photo
      if (this.typeEquipeForm.get('logoSource').value == null) {
        // On enregistre simplement les informations
        this.service.update(this.typeEquipe).subscribe(
          () => {
            this.router.navigate(['/type-equipes'])
          },
          (error) => null,
          () => {
            this.isSubmitting = false;
          }
        );
      }
      // Sinon si il a changer le photo
      else {
        // Creation du formData à envoyer pour l'upload du photo
        const formData = new FormData();
        console.log('logoSource', this.typeEquipeForm.get('logoSource'));

        formData.append("file", this.typeEquipeForm.get('logoSource').value);
        this.service.uploadFile(formData).subscribe(
          (res) => {
            // On enregistre l'IRI du MediaObject
            this.typeEquipe.logo = res["@id"];
            // On enregistre le typeEquipe
            this.service.update(this.typeEquipe).subscribe(
              () => {
                this.router.navigate(['/type-equipes'])
              },
              (error) => null,
              () => {
                this.isSubmitting = false;
              }
            );
          }
        )
      }
    } // Validation de la formulaire
  }

  contentUrl(logo) {
    if(this.uploadedPhoto) return this.photoTempUrl;
    else return this.mediaObjectService.contentUrl(logo)
  }
}
