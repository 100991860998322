import { MessagerieConfigComponent } from './messagerie-config/messagerie-config.component';
import { MessagerieModule } from './messagerie.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: ':idJeu', component: MessagerieConfigComponent},
  // { path: '', redirectTo: '1', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessagerieRoutingModule { }
