import { createAction, props } from '@ngrx/store';

export const resetJeuAction = createAction(
  '[Jeu] RESET_JEU'
);

export const jeuSelectAction = createAction(
  '[Jeu] SELECT_JEU',
  props<{id: number}>()
);

export const passerTourSuivant = createAction(
  '[Jeu] PASSER_TOUR_SUIVANT',
  props<{idJeu: number, tourActuel: number}>()
)

export const jeuSelectedAction = createAction(
  '[Jeu] SELECTED_JEU'
);

export const playJeuAction = createAction(
  '[Jeu] PLAY_JEU',
  props<{id: number}>()
);


export const nouvelleAnalyseMarcheAction = createAction(
  '[Jeu] NOUVELLE_ANALYSE_MARCHE',
  props<{is_new:boolean}>()
);
