import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedToastService {
  private toastMessage = new BehaviorSubject<any>({});

  sharedToastMessage = this.toastMessage.asObservable();

  constructor() { }

  toast(message){
    this.toastMessage.next(message);
  }

}
