import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Jeu, JeuAdapter } from '@models/jeu';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Lister } from './listers/lister.service';
import { ActionJeuAdapter } from '@models/action-jeu';

@Injectable({
  providedIn: 'root'
})
export class JeuService extends Lister<Jeu> {

  constructor(protected http: HttpClient, protected adapter: JeuAdapter, protected actionJeuAdapter: ActionJeuAdapter) {
    super(http, adapter);
    this.setListUri('api/jeus');
   }

  /**
   *
   * @param idJeu Id du jeu concerné
   *
   * @return Jeu
   */
  getOne(idJeu): Observable<Jeu>{
    return this.http.get<Jeu>(`${environment.apiUrl}/api/jeus/${idJeu}`).pipe(
      map(this.adapter.adapt)
    );
  }

  getOnlyTemplates(): Observable<Jeu[]>{
    return this.getAll({"isExemple": true});
  }

  /**
   * @param jeu
   */
  create(jeu : Jeu){
    const url = `${environment.apiUrl}/api/jeus`;
    return this.http.post(url,jeu).pipe(
      map(this.adapter.adapt)
    )
  }

  /**
   * Création de Jeu directement (pas API-platform)
   * @param jeu: Jeu
   *
   */
  createJeu(jeu: Jeu){
    const url = `${environment.apiUrl}/custom/api/create-jeu`;
    return this.http.post(url, jeu).pipe(
      map(this.adapter.adaptCustom)
    );
  }

  /**
   * Edit le jeu ayant l'id idJeu avec le jeu passé en 2ème paramètre
   */
  editJeu(idJeu: number, jeu: Jeu){
    const url = `${environment.apiUrl}/custom/api/edit-jeu/${idJeu}`;
    return this.http.post(url, jeu).pipe(
      map((data: any) => this.adapter.adaptCustom(data))
    );
  }

  update(jeu: Jeu, id:number){
    const url = `${environment.apiUrl}/api/jeus/${id}`;
    return this.http.put(url,jeu).pipe(
      map(this.adapter.adapt)
    )
  }

  /**
   *
   * @param id
   * @returns
   */
  startGame(id:number): Observable<Jeu>{
    const url = `${environment.apiUrl}/api/jeus/${id}/start`;
    return this.http.put(url, {}).pipe(
      map(this.adapter.adapt)
    );
  }

  delete(id:number){
    const url = `${environment.apiUrl}/api/jeus/${id}`;
    return this.http.delete(url);
  }

  getListJeuxFuturs() {
    const url = `${environment.apiUrl}/custom/api/jeu/getJeuxUser`;
    return this.http.get(url).pipe(
      map((jeux: any) => jeux.map(jeu => this.adapter.adapt(jeu)))
    );
  }

  getPhases(idJeu: number, tour: number){
    const url = `${environment.apiUrl}/custom/api/jeu/get-phases/${idJeu}/${tour}`;

    return this.http.get(url).pipe(
      map((data: any) => data.map(x => this.actionJeuAdapter.adapt(x)))
    );

  }

  /**
   * Pour l'animateur, animer le jeu
   */
  animerJeu(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/animer-jeu/${idJeu}`;

    return this.http.get(url);
  }

  /**
   * Pour l'animateur, lance la phase de jeu
   */
  lancerPhase(idPhase: number, duree: number){
    const url = `${environment.apiUrl}/custom/api/jeu/lancer-phase/${idPhase}/${duree}`;

    return this.http.post(url, {});
  }

  /**
   * Animateur: passer au tour suivant
   */
  passerTourSuivant(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/passer-tour-suivant/${idJeu}`;

    return this.http.post(url, {});
  }

  /**
   * Animateur: terminer la phase
   */
  terminerPhase(idPhase: number){
    const url = `${environment.apiUrl}/custom/api/jeu/terminer-phase/${idPhase}`;

    return this.http.post(url, {});
  }

  /**
   * Jeu: set la duree de la phase
   */
  setDureePhase(idPhase: number, dureePhase: number){
    const url = `${environment.apiUrl}/custom/api/jeu/set-duree-phase/${idPhase}/${dureePhase}`;

    return this.http.post(url, {});
  }

  getMinutages(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/get-minutages/${idJeu}`;

    return this.http.get(url);
  }

  /**
   * Initialise ce qu'il faut pour le Jeu et ses Equipes
   */
  initEquipeConfig(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/init-equipe-config/${idJeu}`;

    return this.http.post(url, {});
  }

  getAnciensJeux(){
    const url = `${environment.apiUrl}/custom/api/jeu/get-anciens-jeux`;

    return this.http.get(url).pipe(
      map((data: any) => data.map(x => this.adapter.adapt(x)))
    );
  }

  cloner(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/cloner-jeu/${idJeu}`;

    return this.http.post(url, {}).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }

  setJeuForAnimation(idJeu: number, data: any){
    const url = `${environment.apiUrl}/custom/api/jeu/update-jeu-for-animation/${idJeu}`;

    return this.http.post(url, data);
  }

  terminerJeu(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/terminer-jeu/${idJeu}`;

    return this.http.post(url, {});
  }

  checkJoueursComplets(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/joueurs-complets/${idJeu}`;

    return this.http.get(url);
  }
}
