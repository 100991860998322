import { TypeProduitService } from './../../services/type-produit.service';
import { TypeProduit } from '@models/typeProduit';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Produit } from '@models/produit';
import { MediaObjectService } from '@services/media-object.service';
import { ProduitService } from 'src/app/services/produit.service';

@Component({
  selector: 'app-produit-modification',
  templateUrl: './produit-modification.component.html',
  styleUrls: ['./produit-modification.component.scss']
})
export class ProduitModificationComponent implements OnInit {
  private photoUploaded = false;
  public photoTempUrl;
  public condition1: boolean;
  public condition2: boolean;
  public produit: Produit;
  public types: TypeProduit[];
  produitForm: FormGroup = this.fb.group({
    "nom": [null, Validators.required],
    "description": [null, Validators.required],
    "cout": [null, Validators.required],
    "espoirGain": [null],
    "niveauRisque": [null],
    "costAsPercentIt": [null],
    "type": [null, Validators.required],
    "photoSource": [null],
    "photoFile": [null]
  });
  isSubmitting = false;

  returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private produitService: ProduitService,
    private mediaObjectService: MediaObjectService,
    private typeProduitService: TypeProduitService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

    this.typeProduitService.getAll().subscribe(
      (allTypeProduits) => {
        this.types = allTypeProduits;
      }
    );

    this.produitService.getOne(this.route.snapshot.params.id).subscribe(
      (produit: Produit) => {
        this.produitForm.patchValue({
          "nom": produit.nom,
          "description": produit.description,
          "cout": produit.cout,
          "espoirGain": produit.espoirGain,
          "niveauRisque": produit.niveauRisque,
          "costAsPercentIt": produit.costAsPercentIt,
          "type": produit.type['@id']
        });
        this.produit = produit;
        this.photoTempUrl = this.contentUrl()
        if(this.produit.type['@id']==this.types[0].iri){
          this.condition1=false;
          this.condition2=true;
        }
        else{
          this.condition2=false;
          this.condition1=true;
        }
      }
    );
  }

  onFileChange(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.photoUploaded = true;
      this.photoTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
      this.produitForm.patchValue({
        "photoSource": files[0]
      });
    }
  }

  onChangeType(event){
    let selected = event.target.value;
    selected = selected.split(" ", 2);
    if(selected[1]==this.types[0].iri){
      this.condition1=false;
      this.condition2=true;
    }
    else{
      this.condition2=false;
      this.condition1=true;
    }

  }

  onSubmit() {
    if (this.produitForm.valid) {
      this.isSubmitting = true;

      const id = this.produit.id;
      this.produit = this.produitForm.value as Produit;
      this.produit.id = id;
      if (this.photoUploaded) {
        var formData = new FormData();
        formData.append("file", this.produitForm.get("photoSource").value);
        this.mediaObjectService.createMediaObject(formData).subscribe(
          (res) => {
            this.produit.photo = res["@id"];
            this.produitService.update(this.produit).subscribe(
              (produit: Produit) => {
                this.navigateToNext();
              },
              (error) => null,
              () => {
                this.isSubmitting = false;
              }
            );
          }
        )
      }
      else {
        // Tsy maintsy ovaina ho null satria tsy mety updatena raha ajanona ho de type
        // media object ilay photo
        // this.produit.photo = null;
        this.produitService.update(this.produit).subscribe(
          (produit: Produit) => {
            this.navigateToNext();
          }
        );
      }
    }
  }

  navigateToNext(){
    if(this.returnUrl){
      this.router.navigate([this.returnUrl]);
    }else{
      this.router.navigate(['/produits']);
    }
  }

  annuler(){
    this.navigateToNext();
  }

  contentUrl() {
    return this.mediaObjectService.contentUrl(this.produit.photo);
  }
}
