import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Equipe } from '@models/equipe';
import { EquipeService } from '@services/equipe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeJoueur } from '@models/type-joueur';
import { Joueur } from '@models/joueur';
import { User } from '@models/user';
import { TypeJoueurService } from '@services/type-joueur.service';
import { UserService } from '@services/user.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { MediaObjectService } from '@services/media-object.service';

@Component({
  selector: 'app-equipe-edit',
  templateUrl: './equipe-edit.component.html',
  styleUrls: ['./equipe-edit.component.scss']
})
export class EquipeEditComponent implements OnInit {
  idEquipe: number;
  equipe$: Observable<Equipe>;
  equipe: Equipe;

  allTypeJoueurs : TypeJoueur[];
  joueurs: Joueur[] = [];
  allUsers: any[];

  currentJoueur: Joueur;
  currentJoueurInfo: any = {
    "index": null,
  };
  updateJoueur: boolean = false;

  createJoueurRef: NgbModalRef;
  equipeForm: FormGroup;
  loading: boolean = false;

  returnUrl = null;

  uploadedPhoto;
  photoTempUrl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private typeJoueursService: TypeJoueurService,
    private userService: UserService,
    private modalService: NgbModal,
    private equipeService: EquipeService,
    private sanitizer: DomSanitizer,
    private mediaObjectService: MediaObjectService
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

    this.idEquipe = this.route.snapshot.params.idEquipe;
    this.initForm();
    this.loadEquipe();
    this.loadOtherInformationEquipe();
  }

  /******** Fonctions de récuperations des données ********/

  getAllTypeJoueurs(): void {
    this.typeJoueursService.getAll().subscribe((typeJoueurs)=> {
      this.allTypeJoueurs = typeJoueurs;
    });
  }

  getAllUsers(): void {
    this.userService.getFreeUser().subscribe((users) => {
      this.allUsers = users.map((user) => {
        return {
          'iri' : user.iri,
          'isSelected' : false,
          'username' : user.username,
          'email' : user.email
        };
      });
    });
  }

  /******** Fonctions pour les formulaire ***********/

  private initForm(): void {
    this.equipeForm = this.fb.group({
      'nom': ['', Validators.required],
      'description': [''],
      'ecosysteme': [''],
      'budget': [null],
      'rapportForce': [null],
      'ratioClient': [null],
      'logo': [null],
      'logoId': null
    });
  }

  private updateForm(equipe: Equipe) {
    this.equipeForm.patchValue(equipe);

    // We do not update the logo if existing
    this.equipeForm.patchValue({
      'logo': null
    });

    this.equipeForm.patchValue({
      'ecosysteme': equipe.type.ecosysteme
    });

    console.log(this.equipeForm);
  }

  /**
   * Charge les autres informations sur l'Equipe (Ratio client, budget, rapport de force)
   */
  loadOtherInformationEquipe(){
    this.equipeService.loadOtherInformation(this.idEquipe).subscribe(informations => {
      this.equipeForm.patchValue(informations);
    });
  }

  loadEquipe() {
    this.equipe$ = this.equipeService.getOne(this.idEquipe);

    // Récuperation des données d'un equipe
    this.equipe$.subscribe((equipe) => {
      this.allTypeJoueurs = equipe.typeEquipes.typeJoueurs;
      // Création du tableau joueur contenant tous les joueurs d'un equipe
      // Même les joueurs qui n'ont pas joueur réel
      for(var i = 0; i < this.allTypeJoueurs.length; i++) {
        // Création d'un joueur vide
        this.currentJoueur = {
          'id': null,
          'type': this.allTypeJoueurs[i]['@id'],
          'isPrincipal': true,
          'iriUser': '',
          'user': null
        } as Joueur;
        // Recherche dans le tableau joueurs dans equipe s'il y a déja un
        // joueur avec un "joueur réel
        const joueurFound = equipe.joueurs.find((joueurs) => {
          return joueurs.type['@id'] == this.allTypeJoueurs[i]['@id'];
        });
        // Si on a trouvé, alors on change le joueur vide et sinon on ne fait rien
        if(joueurFound != undefined){
          this.currentJoueur = {
            'id': joueurFound.id,
            'type': this.allTypeJoueurs[i]['@id'],
            'isPrincipal': true,
            'iriUser': joueurFound.user ? joueurFound.user.iri : null,
            'user': joueurFound.user
          } as Joueur;
        }
        // Ajout du joueur dans le tableau contenant tous les joueurs
        this.joueurs.push({...this.currentJoueur});
      }
      // On met à jour les informations dans le formulaire d'edition et l'object equipe de notre classe
      this.equipe = equipe;

      console.log('equipe', this.equipe);
      this.updateForm(this.equipe);
    });
  }

  /************** Fonctions pour le modal **********/

  triggerModal(content){
    this.createJoueurRef = this.modalService.open(content);
    this.createJoueurRef.result.then((res) => {
      this.updateJoueur = false;
    }, (error) => {
      this.updateJoueur = false;
    },);
  }

  listUser(i, content){
    this.currentJoueurInfo.index = i;
    this.triggerModal(content);
  }

  /**
   * @param i index courant dans le tableau joueur
   * @param content la reference vers le modal
   */
  updateUser(i: number, content){
    this.updateJoueur = true;
    this.rendreDispo(i);
    // On récupere les informations du joueur sélectioné ainsi que sa position dans le tableau joueurs
    this.currentJoueur = this.joueurs[i];
    this.currentJoueurInfo.index = i;
    // Affiche le modal
    this.triggerModal(content);
  }

  /*************** Fonctions d'actions ****************/

  modifyUser(){

    var user;

    for(let i = 0; i < this.allUsers.length; i++){
      if(this.allUsers[i].iri == this.currentJoueur.iriUser){
        user = this.allUsers[i];
        this.allUsers[i].isSelected = true;
        break;
      }
    }
    this.currentJoueur.user = user;
    this.currentJoueur.type = this.allTypeJoueurs[this.currentJoueurInfo.index]['@id'];
    this.joueurs[this.currentJoueurInfo.index] = {...this.currentJoueur};
    this.resetCurrentJoueur(this.currentJoueurInfo.index);
    this.currentJoueurInfo = {
      "index" : null
    };
    this.createJoueurRef.close();
  }

  addUser(){
    var user;
    for(let i = 0; i < this.allUsers.length; i++){
      if(this.allUsers[i].iri == this.currentJoueur.iriUser){
        user = this.allUsers[i];
        this.allUsers[i].isSelected = true;
        break;
      }
    }
    var user = this.allUsers.find((user) => user.iri == this.currentJoueur.iriUser);
    this.currentJoueur.user = user;
    this.currentJoueur.type = this.allTypeJoueurs[this.currentJoueurInfo.index]['@id'];
    user.isSelected = true;
    this.joueurs[this.currentJoueurInfo.index] = {...this.currentJoueur};
    this.resetCurrentJoueur(this.currentJoueurInfo.index);
    this.currentJoueurInfo = {
      "index" : null
    };
    this.createJoueurRef.close();
  }

  removeUser(i){
    this.rendreDispo(i);
    this.resetCurrentJoueur(i);
    this.currentJoueur.type = this.allTypeJoueurs[i]['@id'];
    this.joueurs[i] = {...this.currentJoueur};
  }

  notSelectedUser(listUsers){
    return listUsers.filter((user) => user.isSelected == false)
  }

  /************** Fonctions interns **************/
  /**
   * À chaque appuie sur le boutton modifier, ce fonction va rendre l'utilisateur
   * associé au joueur sélectioné comme disponible pour être choisi comme joueur réel
   *
   * @param i index dans le tableau joueurs
   */
  private rendreDispo(i) {
    // Recherche de l'utilisateur associé au joueur sélectioné
    var user = this.allUsers.find((user) => user.iri == this.joueurs[i].user.iri);
    // Si trouvé, alors on le marque comme non sélectioné (rendre l'user disponible)
    if(user != undefined){
      user.isSelected = false;
    }
    // Sinon, c'est que l'utilisateur n'est pas dans la liste (car il n'etais plus disponible)
    // alors, on va crée l'utilisateur associé et l'ajouter dans la liste des utilisateurs dispo
    else{
      this.allUsers.push({
        'iri' : this.joueurs[i].user.iri,
        'isSelected' : false,
        'username' : this.joueurs[i].user.username,
        'email' : this.joueurs[i].user.email
      });
    }
  }

  /**
   * Resetter les informations dans currentJoueur
   */
   private resetCurrentJoueur(i){

    this.currentJoueur = {
      'id': null,
      'type': '',
      'isPrincipal': true,
      'iriUser': '',
      'user': null
    } as Joueur;
  }

  /******** Fonction de mise à jour des données *******/
  updateEquipe() {
    if (this.equipeForm.valid) {
      this.loading = true;
      const formValue = this.equipeForm.value;

      // // Construction de l'objet à poster
      // var joueursFiltered = this.joueurs.filter((joueur) => joueur.user != null);
      // const joueursPost = joueursFiltered.map((joueur) => {
      //   return {
      //     "id" : joueur.id,
      //     "isPrincipal" : joueur.isPrincipal,
      //     "user" : joueur.user.iri,
      //     "type" : joueur.type,
      //   };
      // });
      // var equipe = {
      //   "nom": formValue.nom,
      //   "description": formValue.description,
      //   "objectifPublic": formValue.objectifPublic,
      //   "moyensActions": formValue.moyensActions,
      //   "joueurs": joueursPost,
      //   "type": this.equipe.typeEquipes['@id'],
      // };


      // this.equipeService.update(equipe, this.idEquipe).subscribe((equipe) => {
      //   this.equipe = equipe;
      //   this.updateForm(this.equipe);
      //   this.loading = false;

      //   if(this.returnUrl){
      //     this.router.navigate([this.returnUrl]);
      //   }
      //   else{
      //     this.router.navigate([`/equipe/${this.idEquipe}/show`]);
      //   }
      // })

      // If there is no logo
      console.log('logo value', this.equipeForm.get('logo').value);

      if (this.equipeForm.get('logo').value == null) {
        this.sendUpdateEquipeRequest(this.equipeForm.value, this.idEquipe);
      } else {
        const formData = new FormData();
        console.log(this.equipeForm.get('logo').value);

        formData.append("file", this.equipeForm.get('logo').value);
        this.mediaObjectService.createMediaObject(formData).subscribe(
          (res: any) => {
            console.log(res);
            this.equipeForm.patchValue({
              'logoId': res['id']
            });
            this.sendUpdateEquipeRequest(this.equipeForm.value, this.idEquipe);
          }
        );
      }
    }
  }

  sendUpdateEquipeRequest(data, idEquipe){
    console.log('data', data);

    this.equipeService.update(data, idEquipe).subscribe(
      (data: any) => {
        this.navigateToNext();
      },
      (error) => null,
      () => {
        this.loading = false;
      }
    );
  }

  navigateToNext(){
    if(this.returnUrl){
      this.router.navigate([this.returnUrl]);
    }
    else{
      this.router.navigate([`/equipe/${this.idEquipe}/show`]);
    }
  }

  contentUrl(logo){
    if(this.uploadedPhoto){
      return this.photoTempUrl
    }else if(logo){
      return this.mediaObjectService.contentUrl(logo)
    }else{
      return this.sanitizer.bypassSecurityTrustUrl(`${environment.apiUrl}/media/undraw_profile_pic_ic5t.png`);
    }
  }

  onFileChange(event){
    const files = event.target.files;
    if (files.length > 0) {
      this.photoTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]))
      this.uploadedPhoto = true;

      this.equipeForm.patchValue({
        logo: event.target.files[0]
      });
    }
  }
}
