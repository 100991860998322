import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeEquipeRouting } from './type-equipe-routing.module';
import { TypeEquipeShowComponent } from './type-equipe-show/type-equipe-show.component';
import { TypeEquipeListComponent } from './type-equipe-list/type-equipe-list.component';
import { TypeEquipeCreateComponent } from './type-equipe-create/type-equipe-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TypeEquipeModificationComponent } from './type-equipe-modification/type-equipe-modification.component';
import { MultiSelectModule } from 'primeng/multiselect'
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [TypeEquipeShowComponent, TypeEquipeListComponent, TypeEquipeCreateComponent, TypeEquipeModificationComponent],
  imports: [
    CommonModule,
    TypeEquipeRouting,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MultiSelectModule,
    MatSelectModule,
  ]
})
export class TypeEquipeModule { }
