<h2>7. {{ 'annonces' | translate }}</h2>

<ng-container *ngIf="jeu">

  <div class="form-group">
    <label>Round</label>
    <mat-select [(ngModel)]="tour" name="tour">
      <mat-option *ngFor="let t of [].constructor(jeu.nbTours); let i = index;" [value]="i+1">{{ i+1 }}</mat-option>
    </mat-select>
  </div>

  <div class="card">
    <div class="card-body">
      <app-annonce-jeu-configuration [idJeu]="idJeu" [tour]="tour"></app-annonce-jeu-configuration>
    </div>
  </div>

  <button (click)="suivant()" mat-raised-button color="primary">
    {{ 'page_suivante' | translate }} (Breaking News)
  </button>

</ng-container>
