import { Component, OnInit } from '@angular/core';
import { Jeu } from '@models/jeu';
import { ActivatedRoute, Router } from '@angular/router';
import { JeuService } from '@services/jeu.service';

@Component({
  selector: 'app-page-edition-breaking-news',
  templateUrl: './page-edition-breaking-news.component.html',
  styleUrls: ['./page-edition-breaking-news.component.scss']
})
export class PageEditionBreakingNewsComponent implements OnInit {
  idJeu: number;
  jeu: Jeu;

  constructor(
    private route: ActivatedRoute,
    private jeuService: JeuService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.getJeu();
  }

  getJeu(){
    this.jeuService.getOne(this.idJeu).subscribe(jeu => {
      this.jeu = jeu;
    });
  }

  terminer(){
    this.router.navigate(['/jeux/list']);
  }
}
