import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Joueur, JoueurAdapter } from '@models/joueur';
import { User } from '@models/user';
import { throwError } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Lister } from './listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class JoueurService extends Lister<Joueur>{

  constructor(
    private httpClient : HttpClient,
    private joueurAdapter : JoueurAdapter,
    private authService : AuthenticationService,
  ) {
    super(httpClient, joueurAdapter);
    // /api/joueurs/{id}
    this.setListUri('api/joueurs');
  }


  update(joueur : any){
      const url = `${environment.apiUrl}/${this.listUri}/` + joueur.id;
      return this.httpClient.put(url, joueur).pipe(
        tap(
          (data)=>{
            return this.adapter.adapt(data);
          }
        )
      )
  }

  getOneByUser(user_id:number) {
  }

  getConnectedUser(){
    let currentUserInfo: any;
    currentUserInfo = this.authService.currentUserInfo;
    if(currentUserInfo != null){
      const currentJoueurId = currentUserInfo.joueur.split("/")[3];
      return this.getOne(currentJoueurId);
    }
    else {
      return throwError({"error_message": "Aucun joueur associe a cette user"});
    }
  }

  /**
   * Retourne le Joueur qui est associé à l'utilisateur dans le jeuActuel
   */
  getJoueurOfUser(userId: number){
    const url = `${environment.apiUrl}/custom/api/user/joueurOfUser/${userId}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        // console.log(data)
        return this.joueurAdapter.adapt(data)})
    );
  }

  /**
   * Assign userId to joueurId
   */
  assignJoueurReel(joueurId: number, userId: number){
    const url = `${environment.apiUrl}/custom/api/joueur/assignJoueurReel/${joueurId}/${userId}`;

    return this.http.post(url, {});
  }
}
