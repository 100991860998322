import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IListerService } from '@interfaces/lister.service.interface';
import { TypeEquipe, TypeEquipeAdapter } from '@models/type-equipe'
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Lister } from '@services/listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class TypeEquipeService extends Lister<TypeEquipe>{

  constructor(protected httpClient: HttpClient, protected adaptor: TypeEquipeAdapter) {
    super(httpClient, adaptor);
    this.setListUri('api/type_equipes');
  }

  getOne(id: number): Observable<TypeEquipe>{
    return this.httpClient.get(`${environment.apiUrl}/api/type_equipes/${id}`).pipe(

      map(data => this.adaptor.adapt(data)),

    )
  }

  getEquipeByUrl(url:string) : Observable<TypeEquipe>{

    return this.httpClient.get(`${environment.apiUrl}`+ url).pipe(
      map(this.adaptor.adapt)
    )
  }

  update(typeEquipe: TypeEquipe): Observable<TypeEquipe>{

    return this.httpClient.put(`${environment.apiUrl}/api/type_equipes/${typeEquipe.id}`, typeEquipe).pipe(

      map(data => this.adaptor.adapt(data))
    );
  }

  uploadFile(formData: FormData): Observable<any>{

    return this.httpClient.post<any>(
        `${environment.apiUrl}/api/media_objects`,
        formData,
        { headers: {'Content-Type': ''} }
      );
  }

  createTypeEquipe(formValue: any) {
    const type_equipe = formValue as TypeEquipe;
    return this.httpClient.post(`${environment.apiUrl}/api/type_equipes`, type_equipe).pipe(
      map((data: any) => this.adaptor.adapt(data))
    )
  }

  delete(id: number){
    return this.httpClient.delete(`${environment.apiUrl}/api/type_equipes/${id}`)
  }
}
