import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TypeJeu } from '@models/type-jeu';
import { Jeu } from '@models/jeu';

import { TypeJeuService } from '@services/type-jeu.service';
import { JeuService } from '@services/jeu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page-creation-jeu',
  templateUrl: './page-creation-jeu.component.html',
  styleUrls: ['./page-creation-jeu.component.scss']
})
export class PageCreationJeuComponent implements OnInit {
  isSubmitting: boolean = false;
  loadingGetTypeJeux: boolean = false;
  idJeu: number;
  jeu: Jeu;
  anciensJeux: Jeu[];
  loadingAnciensJeux: boolean = false;
  loadingCloner = false;

  // Formulaire pour le jeu
  jeuForm = new FormGroup({
    nom: new FormControl('',[Validators.required]),
    description: new FormControl(''),
    type: new FormControl(''),
    nbTours: new FormControl('',[Validators.required]),
    equivalentPeriode: new FormControl(''),
    langue: new FormControl(''),
    minutage: new FormGroup({
      introduction: new FormControl(''),
      annonces: new FormControl(''),
      decisions: new FormControl(''),
      pitchEntreprise: new FormControl(''),
      pitchClient: new FormControl(''),
      pitchEtat: new FormControl(''),
      votes: new FormControl(''),
      resultats: new FormControl('')
    })
  });

  allTypeJeux: TypeJeu[] = [];
  jeuACloner = new FormControl();

  constructor(
    private typeJeuService: TypeJeuService,
    private jeuService: JeuService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private translate : TranslateService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('fr');
  }

  ngOnInit(): void {
    this.getTypeJeux();
    this.idJeu = this.route.snapshot.params.idJeu;

    console.log('idJeu', this.idJeu);
    if(this.idJeu){
      this.updateForm();
    }else{
      this.getAnciensJeux();
    }
  }

  getAnciensJeux(){
    this.loadingAnciensJeux = true;
    this.jeuService.getAnciensJeux().subscribe(jeux => {
      this.anciensJeux = jeux;
      this.loadingAnciensJeux = false;
    });
  }

  updateForm(){
    this.jeuService.getOne(this.idJeu).subscribe(jeu => {

      this.jeu = jeu;
      this.jeuForm.patchValue({
        'nom': jeu.nom,
        'description': jeu.description,
        'nbTours': jeu.nbTours,
        'equivalentPeriode': jeu.equivalentPeriode,
        'langue': jeu.langue,
      });

      // Patch minutages
      let minutage = {};

      (jeu.minutage as Array<Object>).forEach(m => {
        minutage[m['phase']] = m['duree'];
      });

      console.log('minutages', minutage);
      this.jeuForm.controls.minutage.patchValue({
        introduction: minutage['introduction'],
        annonces: minutage['annonces'],
        decisions: minutage['decisions'],
        pitchEntreprise: minutage['pitchEntreprise'],
        pitchClient: minutage['pitchClient'],
        pitchEtat: minutage['pitchEtat'],
        votes: minutage['votes'],
        resultats: minutage['resultats']
      });
      console.log(this.jeuForm.value);
    });
  }

  onSubmit(){
    let jeu = this.jeuForm.value as Jeu;

    this.isSubmitting = true;

    if(this.idJeu){
      this.jeuService.editJeu(this.idJeu, jeu).subscribe(jeu => {
        this.isSubmitting = false;

        this.router.navigate(['/jeux/designer/edit-equipe/' + jeu.id]);
      });
    }else{

      this.jeuService.createJeu(jeu).subscribe(jeu => {
        this.isSubmitting = false;

        this.router.navigate(['/jeux/designer/edit-equipe/' + jeu.id]);
      });
    }
  }

  getTypeJeux(){
    this.loadingGetTypeJeux = true;
    this.typeJeuService.getAll().subscribe(typeJeux => {
      this.allTypeJeux = typeJeux;
      this.loadingGetTypeJeux = true;
    });
  }

  clonerJeu(){
    let idJeu = this.jeuACloner.value;

    this.loadingCloner = true;
    this.jeuService.cloner(idJeu).subscribe(jeu => {
      this.router.navigate(['/jeux/designer/creation/' + jeu.id]).then(() => {
        window.location.reload();
      });

      this.loadingCloner = false;
    });
  }
}
