import { Component, OnInit } from '@angular/core';
import { Jeu } from '@models/jeu';
import { ActivatedRoute, Router } from '@angular/router';
import { JeuService } from '@services/jeu.service';
import { Joueur } from '@models/joueur';

@Component({
  selector: 'app-page-edition-joueurs',
  templateUrl: './page-edition-joueurs.component.html',
  styleUrls: ['./page-edition-joueurs.component.scss']
})
export class PageEditionJoueursComponent implements OnInit {
  idJeu: number;
  jeu: Jeu;
  joueurs: Joueur[] = [];

  constructor(
    private route: ActivatedRoute,
    private jeuService: JeuService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.getJeu();
  }

  getJeu(){
    this.jeuService.getOne(this.idJeu).subscribe(jeu => {
      this.jeu = jeu;

      this.getJoueurs();
    });
  }

  getJoueurs(){
    let equipes = this.jeu.equipes;

    equipes.forEach((equipe)=>{
      // Update de Equipe du Joueur car on n'a pas d'Equipe pour la serialization depuis Jeu
      equipe.joueurs.forEach(joueur => {
        joueur = {...joueur, equipe: equipe};
        this.joueurs.push(joueur);
      });
    });
  }

  suivant(){
    this.router.navigate(['/jeux/designer/edit-produits-innovation/' + this.jeu.id]);
  }
}
