import { createAction, props } from '@ngrx/store';
import { Room } from '@models/room';
import { Message } from '@models/message';

export const addMessageAction = createAction(
  '[Message] ADD_MESSAGE',
  props<{ message: Message, room: Room}>()
);

export const initMessagesAction = createAction(
  '[Message] INIT_MESSAGES',
  props<{ messages: Message[], room: Room}>()
);
