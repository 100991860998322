import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimerComponent } from '../components/timer/timer.component';
import { ContentUrlPipe } from "../pipes/ContentUrlPipe";
import { CustomFormatPipe } from '../pipes/custom-format.pipe';
import {TranslateModule} from '@ngx-translate/core';
import { LineBreakPipe } from '../pipes/line-break.pipe';


@NgModule({
  declarations: [
    TimerComponent,
    ContentUrlPipe,
    LineBreakPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TimerComponent,
    ContentUrlPipe,
    LineBreakPipe,
    TranslateModule
  ]
})
export class SharedModule { }
