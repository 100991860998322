<h2>3. {{ 'edition_joueurs' | translate }}</h2>

<div class="w-separator-form">
<mat-list>
  <app-list-joueur-item *ngFor="let joueur of joueurs" [joueur]="joueur"></app-list-joueur-item>
</mat-list>

<div class="float-button">
<button class="mt-3" mat-raised-button color="primary" (click)="suivant()">
  {{ 'valider' | translate }}
</button>
</div>
</div>
