<div>
    <h2>Liste des jeux "templates"</h2>
    <mat-table class="lessons-table" matSort matSortActive="id,debut" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource">
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
            <mat-cell *matCellDef="let jeu">
                {{ jeu.id }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nom_jeu">
            <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
            <mat-cell *matCellDef="let jeu">{{ jeu.nom }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let jeu">
                <button mat-icon-button color="warning" [routerLink]="['/jeux/edit', jeu.id]">
                    <mat-icon>build</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row;columns : displayedColumns"></mat-row>

    </mat-table>
    <mat-paginator [length]="dataSource.total" [pageSize]="defaultPageSize" [pageSizeOptions]="[5,10,20]"></mat-paginator>
    <div class="row" *ngIf="isAdmin">
        <div class="col text-center">
            <a routerLink="/jeux/create" mat-raised-button color="primary">Créer un nouveau Jeu</a>
        </div>
    </div>

</div>
