import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { ParameterService } from '@services/parameter.service';
import { Jeu } from '@models/jeu';

@Component({
  selector: 'app-edition-parametres',
  templateUrl: './edition-parametres.component.html',
  styleUrls: ['./edition-parametres.component.scss']
})
export class EditionParametresComponent implements OnInit {
  @Input() jeu: Jeu;
  parameterForm = this.fb.group({
    'revenues': ['', [Validators.required]],
    'ebitda-margin-as-percent-sales': ['', [Validators.required]],
    'percent-operating-costs': ['', [Validators.required]],
    'cf-from-interest-taxes': ['', [Validators.required]],
    'legacy-capex-as-percent-revenues': ['', [Validators.required]]
  });
  loadingSubmit: boolean = false;

  cyberParameterForm = this.fb.group({
    'legacy-it-costs-as-percent-revenues': [''],
    'legacy-percent-it-costs-fixed': [''],
    'legacy-base-cyber-exp-as-percent-it-costs': [''],
    'new-ops-it-costs-as-percent-total-costs': [''],
    'new-ops-percent-it-costs-fixed': [''],
    'new-ops-base-cyber-exp-as-percent-it-costs': [''],
  });
  loadingSubmitCyber: boolean = false;


  // Online revenues legacy
  onlineLegacyForm = this.fb.group({
    'rounds': this.fb.array([

    ])
  });
  loadingOnlineRevenuesLegacy: boolean = false;

  get rounds(){
    return this.onlineLegacyForm.get('rounds') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private parameterService: ParameterService
  ) { }

  ngOnInit(): void {
    this.initOnlineLegacyForm();
    this.initFormValues();
  }

  /**
   * Envoie une requête pour initialiser les paramètres
   */
  initFormValues(){
    this.parameterService.getInitialFormValues(this.jeu.id).subscribe(parameters => {
      // Initial corporate parameters form
      let initialCorporateParameters = parameters['initialCorporateParameters'];

      this.parameterForm.patchValue({
        'revenues': initialCorporateParameters['revenues'],
        'ebitda-margin-as-percent-sales': initialCorporateParameters['ebitda-margin-as-percent-sales'],
        'percent-operating-costs': initialCorporateParameters['percent-operating-costs'],
        'cf-from-interest-taxes': initialCorporateParameters['cf-from-interest-taxes'],
        'legacy-capex-as-percent-revenues': initialCorporateParameters['legacy-capex-as-percent-revenues'],
      });


      // Cyber parameter form
      let cashflowExpensesCyber = parameters['cashflowExpensesCyber'];

      this.cyberParameterForm.patchValue({
        'legacy-it-costs-as-percent-revenues': cashflowExpensesCyber['legacy-it-costs-as-percent-revenues'],
        'legacy-percent-it-costs-fixed': cashflowExpensesCyber['legacy-percent-it-costs-fixed'],
        'legacy-base-cyber-exp-as-percent-it-costs': cashflowExpensesCyber['legacy-base-cyber-exp-as-percent-it-costs'],
        'new-ops-it-costs-as-percent-total-costs': cashflowExpensesCyber['new-ops-it-costs-as-percent-total-costs'],
        'new-ops-percent-it-costs-fixed': cashflowExpensesCyber['new-ops-percent-it-costs-fixed'],
        'new-ops-base-cyber-exp-as-percent-it-costs': cashflowExpensesCyber['new-ops-base-cyber-exp-as-percent-it-costs'],
      });

      // Online revenues legacy
      let onlineRevenuesLegacy = parameters['onlineRevenuesLegacy'];

      console.log(onlineRevenuesLegacy);

      for(var i=0; i<onlineRevenuesLegacy.length; i++){
        console.log('onlineRevenuesLegacy', onlineRevenuesLegacy[i]);
        this.rounds.at(i).patchValue(onlineRevenuesLegacy[i]);
        console.log(this.rounds);
      }

    });
  }

  initOnlineLegacyForm(){
    let controls = Array(this.jeu.nbTours + 1).fill(this.fb.control(''));
    console.log('controls', controls);

    for(var i=0; i<= this.jeu.nbTours; i++){
      this.rounds.push(this.fb.control(''));
    }
    console.log(this.onlineLegacyForm);
  }

  onSubmit(){
    let data = this.parameterForm.value;

    this.loadingSubmit = true;
    this.parameterService.updateInitialCorporateParameters(this.jeu.id, data).subscribe(() => {
      this.loadingSubmit = false;
    });
  }

  onSubmitCyber(){
    let data = this.cyberParameterForm.value;

    this.loadingSubmitCyber = true;
    this.parameterService.updateCyberParameters(this.jeu.id, data).subscribe(() => {
      this.loadingSubmitCyber = false;
    });
  }

  onSubmitOnlineLegacy(){
    let rounds = this.rounds.value;

    this.loadingOnlineRevenuesLegacy = true;
    this.parameterService.updateOnlineLegacyParameters(this.jeu.id, rounds).subscribe(() => {
      this.loadingOnlineRevenuesLegacy = false;
    });
  }
}
