<div class="journal" #journalContent>

  <div class="journal-item" *ngFor="let historique of historiques">
    <div class="journal-content">
      <div class="journal-author">
        <span *ngIf="historique.type == 'declaration-officielle'">{{ 'journal_declaration' | translate }} <span *ngIf="historique.messageData.communicationPublic? historique.messageData.communicationPublic : false">({{ 'journal_avec_espace_public' | translate }}) </span> </span>
        <span *ngIf="historique.type == 'new-hashtag'">{{ 'journal_nouvel_hashtag' | translate }}: {{ historique.titre }} </span>
        <ng-container *ngIf="historique.messageData && !(historique.messageData.ecosysteme == 'client')">{{ historique.messageData.createur }}</ng-container>
        <ng-container *ngIf="historique.messageData && (historique.messageData.ecosysteme == 'client')">{{ historique.messageData.typeJoueurCreateur }}</ng-container>
      </div>
      {{ historique.description }}
      <div class="journal-time">
        {{ historique.createdAt | date: 'HH:mm'}}
      </div>
    </div>
  </div>
</div>
