import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TypeProduit, TypeProduitAdapter } from '@models/typeProduit';
import { Lister } from './listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class TypeProduitService extends Lister<TypeProduit>{
  constructor(protected httpClient: HttpClient, protected adaptor: TypeProduitAdapter) {
    super(httpClient, adaptor);
    this.setListUri('api/type_produits');
  }
}
