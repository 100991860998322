import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '@models/user';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });

  returnUrl: string;
  loading: boolean = false;
  errorUnauthorized = false;
  errorNetwork = false;
  errorLogin = false;
  errorAccount = false

  loadingResetPassword = false;
  message = "";
  ngOnInit(): void {

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    this.errorUnauthorized = false;
    this.errorNetwork = false;
    this.errorLogin = false;
    this.errorAccount = false
    const user = this.loginForm.value;
    this.loading = true;
    this.authenticationService.login(user.username, user.password).subscribe(
      (user: Observable<User>) => {
        user.subscribe((user: User) => {
          this.router.navigate([this.returnUrl]);
        });
      },
      error => {
        this.errorUnauthorized = false;
        this.errorNetwork = false;
        this.errorLogin = false;

        if(error){
          if(error == "Invalid credentials.")
            this.errorLogin = true;
          else if(error == "Your account is not active")
            this.errorAccount = true;
        }
        if (error.status == '401') {
          this.errorUnauthorized = true;
        }
        else {
          this.errorNetwork = true;
        }
        this.loading = false;
      }
    );
  }

  logout() {
    this.userService.logout().subscribe(() => {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    });
  }

  goTo(url){
    this.router.navigate([url]);
  }

  resetPassword(){
    this.loadingResetPassword = true;

    let username = this.loginForm.get('username').value;
    this.message = "";
    this.userService.sendResetPasswordRequest(username).subscribe(() => {
      this.loadingResetPassword = false;
      this.message = "Un mail a été envoyé à l'adresse";
    }, error => {
      this.loadingResetPassword = false;
      this.message = "Erreur";
    });
  }
}
