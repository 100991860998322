import { Adapter } from '@models/adapter';
import { Injectable } from '@angular/core';

export interface TypeRessource{
  nom?: string,
  iri?: string
}

@Injectable({
  providedIn: 'root'
})
export class TypeRessourceAdapter implements Adapter<TypeRessource>{
  adapt(data: any): TypeRessource{
    const typeressource = {
      'nom': data['nom'],
      'iri': data['@id']
    }
    return typeressource as TypeRessource;
  }
}