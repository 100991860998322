import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  constructor(
    private http: HttpClient
  ) { }

  updateInitialCorporateParameters(idJeu: number, parameters: number[]){
    const url = `${environment.apiUrl}/custom/api/jeu/update-initial-corporate-parameters/${idJeu}`;

    return this.http.post(url, parameters);
  }

  updateCyberParameters(idJeu: number, parameters: number[]){
    const url = `${environment.apiUrl}/custom/api/jeu/update-cyber-parameters/${idJeu}`;

    return this.http.post(url, parameters);
  }

  updateOnlineLegacyParameters(idJeu: number, rounds: number[]){
    const url = `${environment.apiUrl}/custom/api/jeu/update-online-legacy-parameters/${idJeu}`;

    return this.http.post(url, {'rounds': rounds});
  }

  getInitialFormValues(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/get-form-parameters/${idJeu}`;

    return this.http.get(url);
  }

  getParameterJeu(idJeu: number){
    const url = `${environment.apiUrl}/custom/api/jeu/get-parameter-jeu/${idJeu}`;

    return this.http.get(url);
  }
}
