<div *ngIf="joueur">
    <div class="row">
        <div class="col-md-2">
        </div>
        <div class="col-md-8">
          <div class="form-group">
              <label for="">{{ 'titre_role' | translate }}:</label> {{joueur.type['nom']}} , {{ joueur.equipe.nom }}
          </div>
          <!--<div class="form-group">
              <label>Nom Equipe: </label> {{joueur.equipe.nom}}
          </div>-->
          <!--<div class="form-group">
              <mat-form-field appearance="fill">
                  <mat-label>Sélectionner un Joueur</mat-label>
                  <mat-select name="iri_user_for_joueur" class="dark">
                      <mat-option *ngFor="let user of allFreeUsers" [value]="user.iri">{{ user.username }}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>-->
          <div class="form-group">
            <label>{{ 'layout_description_role' | translate }}</label>
            <textarea spellcheck="false" class="form-control" [(ngModel)]="joueur.description"></textarea>
          </div>
          <div class="form-group">
              <label for="obj_priv">{{ 'layout_objectifs_prives' | translate }}:</label>
              <textarea spellcheck="false" class="form-control" [(ngModel)]="joueur.objectifPrive"></textarea>

          </div>
          <div class="form-group">
              <label for="obj_pub">{{ 'objectifs_publics' | translate }}:</label>
              <textarea spellcheck="false" class="form-control" [(ngModel)]="joueur.objectifPublic"></textarea>

          </div>

          <div class="form-group">
            <label>{{ 'dossier_personnel' | translate }}</label>

            <input type="file" class="form-control" (change)="onFileChange($event)" name="dossier_personnel"/>
            <span *ngIf="joueur && joueur.dossierPersonnel">{{ extractFilename(joueur.dossierPersonnel.contentUrl) }}</span>

          </div>
          <button (click)="update(joueur)"  mat-raised-button color="primary" [disabled]="isSubmitting">
              <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
              {{ 'enregistrer' | translate }}
          </button>
      </div>
    </div>
</div>
