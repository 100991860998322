import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Student, StudentAdapter } from '@models/student';
import { StudentService } from '@services/student.service';
import { SseService } from '@services/sse.service';
import { DataSource } from '@angular/cdk/table';
import { StudentsDataSource } from '@datasources/students-datasource';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {
  students: Student[];
  displayedColumns = ["seqNo", "description", "duration"];
  dataSource: StudentsDataSource;

  studentForm = new FormGroup({
    file: new FormControl(''),
    fileSource: new FormControl('')
  });

  constructor(private studentService: StudentService, private sseService: SseService, private studentAdapter: StudentAdapter, private http: HttpClient) { }

  ngOnInit(): void {

    // this.sseService
    //   .getServerSentEvent("http://localhost:3000/.well-known/mercure?topic=api/students")
    //   .subscribe(data => {
    //     data = JSON.parse(data);
    //     this.students.push(this.studentAdapter.adapt(data));
    //   });
    this.dataSource = new StudentsDataSource(this.studentService);
    this.dataSource.loadStudents();
  }

  onFileChange(event){

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.studentForm.patchValue({
        fileSource: file
      });
    }
  }

  onSubmit(){
    const formData = new FormData();

    formData.append('file', this.studentForm.get('fileSource').value);


    let p = new HttpParams()
      .set('processData', 'false');

    let header = new HttpHeaders({
      // 'content-type': 'multipart/form-data'
    });

    this.http.post(`${environment.apiUrl}/api/media_objects`, formData, { headers: header})

      .subscribe(res => {


      })
  }

  getStudents(){
    this.studentService.getStudents().subscribe(data => {
      this.students = data;
    });
  }

}
