import { ActivatedRoute } from '@angular/router';
import { UserService } from '@services/user.service';
import { User } from '@models/user';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MediaObjectService } from '@services/media-object.service';

@Component({
  selector: 'app-user-show',
  templateUrl: './user-show.component.html',
  styleUrls: ['./user-show.component.scss']
})
export class UserShowComponent implements OnInit {

  id : string;
  itemImageUrl;
  user$: Observable <User>;

  constructor(private userService: UserService, private route:ActivatedRoute, private mediaservice: MediaObjectService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.user$ = this.userService.getUser(this.id);
    this.user$.subscribe(
      (res) => {
        this.itemImageUrl = this.mediaservice.contentUrl(res.photo);
    });
  }


}
