import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { jeuSelectAction } from '@app/core/actions/jeu.actions';
import { Annonce } from '@models/annonce';
import { AnnonceService } from '@services/annonce.service';

@Component({
  selector: 'app-annonce-jeu-configuration',
  templateUrl: './annonce-jeu-configuration.component.html',
  styleUrls: ['./annonce-jeu-configuration.component.scss']
})
export class AnnonceJeuConfigurationComponent implements OnInit, OnChanges {

  @Input() idJeu : number;
  @Input() tour : number;

  annonces : any[] = [];
  loadingAnnonces : boolean = true;

  page: number = 0;

  constructor(
    private annonceService: AnnonceService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.getAllAnnonces();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('simple changes', changes);
    this.getAllAnnonces();
  }

  getAllAnnonces() {
    this.loadingAnnonces = true;
    this.annonceService.getAnnoncesJeu(this.idJeu, this.tour).subscribe(
      (results) => {
        console.log(results);
        this.annonces = results.map(
          (annonce) => {
            return {...annonce, jeu: annonce.jeu.id}
          }
        );

        // Pour le bouton à la fin
        let addButtonAnnonce = {
          id: null,
          type: 'add-button',
          titre: null,
          description: null,
          jeu: this.idJeu,
          tour: this.tour,
          ordre: this.annonces.length +1,
          launched: false,
        };
        this.annonces = [...this.annonces, addButtonAnnonce]; // cannot use push because p-carousel won't update

        this.loadingAnnonces = false;
      }
    )
  }


  createAnnonce() {
    let newAnnonce = {
      id: null,
      type: null,
      titre: null,
      description: null,
      jeu: this.idJeu,
      tour: this.tour,
      ordre: this.annonces.length +1,
      launched: false,
    };
    this.annonces = [...this.annonces];
    this.annonces[this.annonces.length - 1] = newAnnonce;

    // Pour le bouton à la fin
    let addButtonAnnonce = {
      id: null,
      type: 'add-button',
      titre: null,
      description: null,
      jeu: this.idJeu,
      tour: this.tour,
      ordre: this.annonces.length +1,
      launched: false,
    };
    this.annonces = [...this.annonces, addButtonAnnonce];

    // console.log(this.annonces);
    this.page = this.annonces.length-2;
  }

  onDeletedAnnonce(){
    this.getAllAnnonces();
  }
}
