import { Injectable } from '@angular/core'
import { Adapter } from './adapter'

export interface MediaObject {
    contentUrl: string
}

@Injectable({
    providedIn: 'root'
})
export class MediaObjectAdapter implements Adapter<MediaObject> {
    adapt(data: any): MediaObject {
        return {
            contentUrl: data["contentUrl"]
        } as MediaObject
    }
}
