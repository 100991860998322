import { Injectable, NgZone } from "@angular/core";
import { Observable } from "rxjs";
import {EventSourcePolyfill} from 'ng-event-source';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: "root"
})
export class SseService {
  constructor(private _zone: NgZone) {}

  getServerSentEvent(url: string): Observable<any> {
    return Observable.create(observer => {
      const eventSource = new EventSourcePolyfill(`${environment.mercureUrl}${url}`, {
		    headers: {
		        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6WyIqIl19fQ.PAjRxGbkvbeqIaoekOpGjtqaqIy7AMgprvNtETIzvxM'
		    }
		  });

      eventSource.onmessage = event => {
        this._zone.run(() => {
          observer.next(event.data)
        })
      }

      eventSource.onerror = error => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
    });
  }

  private getEventSource(url: string): EventSource {
    return new EventSource(url);
  }
}
