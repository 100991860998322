<div>
  <mat-table class="lessons-table" [dataSource]="dataSource">
      <ng-container matColumnDef="typeJoueur_id">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let typeJoueur">{{ typeJoueur.id }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="typeJoueur_nom">
          <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
          <mat-cell *matCellDef="let typeJoueur">{{ typeJoueur.nom }}</mat-cell>
      </ng-container>


      <ng-container matColumnDef="typeJoueur_action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell class="description-cell" *matCellDef="let typeJoueur">
              <button mat-icon-button color="warning" [routerLink]="['/type-joueur/edit', typeJoueur.id]">
                  <mat-icon>build</mat-icon>
              </button>
              <button mat-icon-button color="danger" (click)="triggerModal(modalData)">
                  <mat-icon>close</mat-icon>
              </button>
              <ng-template #modalData let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Delete </h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p><strong>Voulez-vous vraiment supprimer <span class="text-primary">"{{typeJoueur.nom}}"</span></strong></p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Close</button>
                    <button type="button" class="btn btn-danger" (click)="onDelete(typeJoueur.id); modal.close('Save click')">Delete</button>
                  </div>
                </ng-template>
          </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row;columns : displayedColumns"></mat-row>

  </mat-table>
  <mat-paginator [length]="dataSource.total" [pageSize]="defaultPageSize" [pageSizeOptions]="[5,10,20]"></mat-paginator>
  <div class="row">
      <div class="col text-center">
        <button type="button" routerLink="/type-joueur/create"  mat-raised-button color="primary">Créer un nouveau type de joueur</button>
      </div>
    </div>
  </div>
