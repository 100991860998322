import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IListerService } from '@interfaces/lister.service.interface';
import { BreakingNew, BreakingNewAdapter } from '@models/breakingNew'
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Lister } from '@services/listers/lister.service';

@Injectable({
  providedIn: 'root'
})
export class BreakingNewService extends Lister<BreakingNew>{

  constructor(protected httpClient: HttpClient, protected adaptor: BreakingNewAdapter) {
    super(httpClient, adaptor);
    this.setListUri('api/breaking_news');
  }

  getOne(id): Observable<BreakingNew>{
    return this.httpClient.get(`${environment.apiUrl}/api/breaking_news/${id}`).pipe(
      map(data => this.adaptor.adapt(data)),
    )
  }

  getByJeu(idJeu: number): Observable<BreakingNew[]>{
    return this.getAll({"jeu.id": idJeu});
  }

  /**
   *
   * @param idJeu id du jeu contenant le breaking new
   *
   * Retourne seulement les breakings news templates(ceux avec tourApparition = -1)
   */
  getTemplate(idJeu: number): Observable<BreakingNew[]>{
    return this.getAll({"jeu.id": idJeu, "tourApparition": -1});
  }

  getBreakingNewByUrl(url:string) : Observable<BreakingNew>{
    return this.httpClient.get(`${environment.apiUrl}`+ url).pipe(
      map(this.adaptor.adapt)
    )
  }

  update(breakingNew: any): Observable<BreakingNew>{
    return this.httpClient.put(`${environment.apiUrl}/api/breaking_news/start/${breakingNew.id}`, breakingNew).pipe(
      map(data => this.adaptor.adapt(data))
    );
  }

  uploadFormDataFile(formdata : FormData): Observable<any>{
    const url = `${environment.apiUrl}/api/media_objects`;
    return this.http.post<any>(url, formdata,{ headers: {'Content-Type': ''} });
  }

  createBreakingNew(formValue: any) {
    const breakingNew = formValue as BreakingNew;
    return this.httpClient.post(`${environment.apiUrl}/api/breaking_news`, breakingNew).pipe(
      map((data: any) => this.adaptor.adapt(data))
    )
  }

  create(breakingNew: any){
    const url = `${environment.apiUrl}/api/breaking_news/add`;
    return this.http.post(url, breakingNew).pipe(
      map(
        (data)=>{
          return this.adapter.adapt(data)
        }
      )
    )
  }

  addBreakingNew(breakingNew: any){
    const url = `${environment.apiUrl}/custom/api/breaking-news/add-breaking-new`;
    return this.http.post(url, breakingNew);
  }

  delete(id: number){
    return this.httpClient.delete(`${environment.apiUrl}/api/breaking_news/${id}`)
  }


}
