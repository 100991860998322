import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BreakingNew } from '@models/breakingNew';
import { Jeu } from '@models/jeu';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreakingNewService } from '@services/breaking-new.service';

@Component({
  selector: 'app-create-breaking-news',
  templateUrl: './create-breaking-news.component.html',
  styleUrls: ['./create-breaking-news.component.scss']
})
export class CreateBreakingNewsComponent implements OnInit {
  @Input() idJeu: number;
  
  allBreakingNews : BreakingNew[] = [];
  form: FormGroup;

  modalRef: NgbModalRef;
  loadingCreation: boolean;
  loadingBreakingNews: boolean;
  constructor(
    private breakingNewsService: BreakingNewService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getAllBreakingNews();
    this.initForm();
  }

  getAllBreakingNews(){
    this.loadingBreakingNews = true;
    this.breakingNewsService.getTemplate(this.idJeu).subscribe(
      (breakingNews) => {
        this.allBreakingNews = breakingNews.map((breakingNew) => {
          return {...breakingNew};
        });
        console.log(this.allBreakingNews);
        this.loadingBreakingNews = false;

      }
    )
  }

  delete(event){
    console.log(event);
    this.getAllBreakingNews();
  }

  initForm(){
    this.form = new FormGroup({
      nom: new FormControl("", Validators.required),
      description: new FormControl("", Validators.required)
    });
  }

  save(){
    this.loadingCreation = true;
    const breaking_new = {
      ...this.form.value,
      tourApparition: -1,
      dateLancement: new Date(),
      jeu: `/api/jeus/${this.idJeu}`,
      started: false
    } as BreakingNew;
    console.log(breaking_new);
    this.breakingNewsService.createBreakingNew(breaking_new).subscribe(
      (resultat) => {
        console.log(resultat);
        this.loadingCreation = false;
        this.allBreakingNews.push(resultat);
        this.modalRef.close();
      }
    );
  }

  triggerModal(content){
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then(
      (resultat) => {
        console.log("Modal ferme par le bouton close");
        this.modalRef.close();
      },
      (error) => {
        console.log("Modal ferme par echap");
        this.modalRef.close();
      }
    );
  }
}
