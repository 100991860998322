<p-tabView>

  <p-tabPanel header="{{ (isAnimation ? 'en_cours' : 'jeux_definis') | translate }}">
    <app-tableau-list-jeu [isAnimation]="isAnimation" [isFinished]="false"></app-tableau-list-jeu>
      <!--<div>
        <mat-table class="lessons-table" matSort matSortActive="id,debut" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
                <mat-cell *matCellDef="let jeu">
                    {{ jeu.id }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="finished">
              <mat-header-cell *matHeaderCellDef>status</mat-header-cell>
              <mat-cell *matCellDef="let jeu">{{ jeu.finished }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="date_debut">
              <mat-header-cell *matHeaderCellDef mat-sort-header="debut">Date debut</mat-header-cell>
              <mat-cell *matCellDef="let jeu">{{ jeu.debut | date:'d/M/yy, h:mm a' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="nom_jeu">
                <mat-header-cell *matHeaderCellDef>Nom</mat-header-cell>
                <mat-cell *matCellDef="let jeu">{{ jeu.nom }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="description_jeu">
              <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
              <mat-cell *matCellDef="let jeu">{{ jeu.description }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="nbJoueurs_jeu">
              <mat-header-cell *matHeaderCellDef>Nombre de joueurs</mat-header-cell>
              <mat-cell *matCellDef="let jeu">{{ jeu.nbJoueurs }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell class="description-cell" *matCellDef="let jeu">

                    <button mat-icon-button color="primary" [routerLink]="['/jeux/designer/creation', jeu.id]">
                      <mat-icon>build</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="onSelectJeu(jeu.id)">
                      <mat-icon>login</mat-icon>
                    </button>

                    <ng-template *ngIf="!isAnimateur">

                      <button mat-icon-button color="danger" (click)="triggerModal(modalData)">
                        <mat-icon>close</mat-icon>
                      </button>
                      <ng-template #modalData let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title" id="modal-basic-title">Delete </h4>
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <p><strong>Voulez-vous vraiment supprimer <span class="text-primary">"{{jeu.nom}}"</span></strong></p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Close</button>
                          <button type="button" class="btn btn-danger" (click)="onDelete(jeu.id,false); modal.close('Save click')">Supprimer</button>
                        </div>
                      </ng-template>

                    </ng-template>
                  </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row;columns : displayedColumns"></mat-row>

        </mat-table>
          <mat-paginator [length]="dataSource.total" [pageSize]="defaultPageSize" [pageSizeOptions]="[5,10,20]"></mat-paginator>

        </div> -->
        <div class="row" *ngIf="isAdmin">
          <div class="col text-center">
            <a [routerLink]="['/jeux/designer/creation']" mat-raised-button color="primary">{{ 'creer_nouveau_jeu' | translate }}</a>
          </div>
        </div>
  </p-tabPanel>


  <p-tabPanel header="{{ 'termines' | translate }}" *ngIf="isAnimation">
    <!-- <div>
      <mat-table class="lessons-table" matSort matSortActive="id,debut" matSortDirection="asc" matSortDisableClear [dataSource]="dataSourceFinishedState">
          <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
              <mat-cell *matCellDef="let jeu">
                <div  *ngIf="jeu.finished">
                  {{ jeu.id }}
                </div>
              </mat-cell>
          </ng-container>
          <ng-container matColumnDef="finished">
            <mat-header-cell *matHeaderCellDef>status</mat-header-cell>
            <mat-cell *matCellDef="let jeu">{{ jeu.finished }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="date_debut">
            <mat-header-cell *matHeaderCellDef mat-sort-header="debut">Date debut</mat-header-cell>
            <mat-cell *matCellDef="let jeu">{{ jeu.debut | date:'d/M/yy, h:mm a' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="nom_jeu">
              <mat-header-cell *matHeaderCellDef>{{ 'nom' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let jeu">{{ jeu.nom }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="description_jeu">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell *matCellDef="let jeu">{{ jeu.description }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="nbJoueurs_jeu">
            <mat-header-cell *matHeaderCellDef>Nombre de joueurs</mat-header-cell>
            <mat-cell *matCellDef="let jeu">{{ jeu.nbJoueurs }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</mat-header-cell>
              <mat-cell class="description-cell" *matCellDef="let jeu">

                  <button mat-icon-button color="primary" (click)="triggerModal(modalData)">
                    <mat-icon>close</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="replayGame(jeu.id)" [disabled]="loadingReplay == jeu.id">
                    <span class="spinner-border spinner-border-sm" *ngIf="loadingReplay == jeu.id"></span>
                    <mat-icon *ngIf="loadingReplay != jeu.id">play_arrow</mat-icon>
                  </button>
                  <ng-template #modalData let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Delete </h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <p><strong>{{ 'confirmation_supprimer' | translate }} <span class="text-primary">"{{jeu.nom}}"</span></strong></p>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Close</button>
                        <button type="button" class="btn btn-danger" (click)="onDelete(jeu.id, true); modal.close('Save click')">{{ 'supprimer' | translate }}</button>
                      </div>
                  </ng-template>
              </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row;columns : displayedColumns"></mat-row>

      </mat-table>
        <mat-paginator [length]="dataSourceFinishedState.total" [pageSize]="defaultPageSize" [pageSizeOptions]="[5,10,20]"></mat-paginator>
        <div class="row" *ngIf="false">
          <div class="col text-center">
            <a routerLink="/jeux/create" class="btn btn-warning">{{ 'creer_nouveau_jeu' | translate }}</a>
          </div>
        </div>

      </div> -->
      <app-tableau-list-jeu [isFinished]="true" [isAnimation]="true"></app-tableau-list-jeu>
  </p-tabPanel>

  <p-tabPanel header="Jeux définis" *ngIf="isTemplate">
    <app-tableau-list-jeu></app-tableau-list-jeu>
  </p-tabPanel>
</p-tabView>
<!--<app-page-jeu-exemple></app-page-jeu-exemple>-->
