<form [formGroup]="studentForm" (ngSubmit)="onSubmit()">
  <input
    formControlName="file"
    id="file"
    type="file"
    class="form-control"
    (change)="onFileChange($event)">

    <button type="submit">Submit</button>
</form>
