import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TypeJeuRoutingModule } from './type-jeu-routing.module';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { UpdateFormComponent } from './update-form/update-form.component';
import { MultiSelectModule } from 'primeng/multiselect';


@NgModule({
  declarations: [ListComponent, CreateComponent, UpdateFormComponent],
  imports: [
    CommonModule,
    TypeJeuRoutingModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MultiSelectModule
  ]
})
export class TypeJeuModule { }
