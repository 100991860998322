import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TypeJeuAdapter , TypeJeu} from "@models/type-jeu";
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { IListerService } from '@interfaces/lister.service.interface';
import { Lister } from '@services/listers/lister.service';
import { TypeEquipeAdapter } from '@models/type-equipe';
import { TypeEquipeService } from './type-equipe.service';

@Injectable({
  providedIn: 'root'
})

export class TypeJeuService extends Lister<TypeJeu> {
  constructor(http: HttpClient, adapter: TypeJeuAdapter) {
    super(http, adapter);
    this.setListUri('api/type_jeus');
  }

  getTypeJeu(id:number){
    const url = `${environment.apiUrl}/api/type_jeus/` + id;
    return this.http.get(url).pipe(
      map(
        (data)=>{
          return this.adapter.adapt(data)
        }
      )
    );
  }

  getTypeJeuByIri(iriTypeJeu: string){
    const url = `${environment.apiUrl}${iriTypeJeu}`;
    return this.http.get(url).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }


  deleteType(id: number) {
    const url = `${environment.apiUrl}/api/type_jeus/` + id;
    return this.http.delete<TypeJeu>(url);
  }

  create(typeJeu: TypeJeu){
    const url = `${environment.apiUrl}/api/type_jeus`;
    return this.http.post(url, typeJeu).pipe(
      map(
        (data)=>{
          return this.adapter.adapt(data)
        }
      )
    )
  }

  update(typeJeu: TypeJeu, id: number){
    const url = `${environment.apiUrl}/api/type_jeus/${id}`;
    return this.http.put(url, typeJeu).pipe(
      map(
        (data)=>{
          return this.adapter.adapt(data)
        }
      )
    )
  }

  uploadFormDataFile(formdata : FormData): Observable<any>{
    const url = `${environment.apiUrl}/api/media_objects`;
    return this.http.post<any>(url, formdata,{ headers: {'Content-Type': ''} });
  }
}
