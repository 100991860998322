import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { ActionJeuService } from '@services/action-jeu.service';

@Component({
  selector: 'app-action-jeu',
  templateUrl: './action-jeu.component.html',
  styleUrls: ['./action-jeu.component.scss']
})
export class ActionJeuComponent implements OnInit {

  @Input() actionJeu;
  startedTime;
  constructor(private actionJeuService: ActionJeuService) { }

  ngOnInit(): void {
    if (this.actionJeu.started) {
      this.startedTime = this.addMinute(this.actionJeu.dateLancement, this.actionJeu.duree);

    }
  }

  runActionJeu() {
    this.actionJeu.jeu = this.actionJeu.iriJeu;
    this.actionJeuService.runActionJeu(this.actionJeu).subscribe(
      res => {
        this.startedTime = this.addMinute(res.dateLancement, res.duree);
        this.actionJeu = res;
      }
    )
  }

  finishActionJeu(e) {
    if (!this.actionJeu.finished) {
      this.actionJeu.finished = true;

      this.actionJeuService.update(this.actionJeu.id, this.actionJeu).subscribe(
        (res) => {
          this.actionJeu = res;
        }
      )
    }
  }

  addMinute(time, min) {
    return new Date(time.getTime() + min * 60000);
  }

}
