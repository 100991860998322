import { TypeRessource, TypeRessourceAdapter } from './../models/type-ressource';
import { Injectable } from '@angular/core';
import { Lister } from './listers/lister.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TypeRessourceService extends Lister<TypeRessource>{
  constructor(protected httpClient: HttpClient, protected adaptor: TypeRessourceAdapter) {
    super(httpClient, adaptor);
    this.setListUri('api/type_ressources');
  }
}
