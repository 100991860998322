<h4 class="pb-4">Liste des animateurs</h4>
<table class="table" *ngIf="animateurList$ | async as animateurList">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Nom d'utilisateur</th>
        </tr>
    </thead >
    <tbody>
        <tr *ngFor="let animateur of animateurList">
            <th scope="row">{{animateur.id}}</th>
            <td>{{animateur.username}}</td>
        </tr>
    </tbody>
</table>
