import { RessourceService } from './../../services/ressource.service';
import { Ressource, RessourceAdapter } from './../../models/ressource';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomDatasource } from '@datasources/custom-datasource';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeRessource } from '@models/type-ressource';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MediaObjectService } from '@services/media-object.service';
import { merge } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-ressource-list',
  templateUrl: './ressource-list.component.html',
  styleUrls: ['./ressource-list.component.scss']
})
export class RessourceListComponent implements OnInit {
  dataSource : CustomDatasource<Ressource>;
  defaultPageSize = 10;
  displayedColumns = ["id","ressource_nom","ressource_type","action"];
  typeRessource: TypeRessource[];
  closeModal: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private ressourceService: RessourceService,
    private mediaObjectService: MediaObjectService,
    private adapter: RessourceAdapter,
    private modalService: NgbModal) { }
  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      debounceTime(500),
      tap(() => {
        this.load()
      })
    ).subscribe();
  }

  ngOnInit(): void {
    this.dataSource = new CustomDatasource<Ressource>(this.ressourceService, this.adapter);

    this.dataSource.load("", null, this.defaultPageSize);
  }

  load(){
    let sortCol = this.sort.active;
    let sortDirection = this.sort.direction;
    let orders = {[sortCol]: sortDirection};
    this.dataSource.load("", this.paginator.pageIndex, this.paginator.pageSize, orders);
  }

  total() {
    return this.dataSource.total;
  }

  onDelete(id: number){
    this.ressourceService.delete(id).subscribe(
      () => {
        this.load();
      }
    );
  }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  contentUrl(logo){
    return this.mediaObjectService.contentUrl(logo);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}


