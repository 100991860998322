export interface JeuState{
  id: number,
  tour: number,
  started: boolean,
  nouvelleAnalyseMarche?: boolean;
}

export const initialState: JeuState = {
  id: 0,
  tour: 0,
  started: false,
  nouvelleAnalyseMarche : false,
}
