<h2>{{ 'liste_equipes' | translate }}</h2>
<mat-list>
  <app-list-equipe-item [equipe]="equipe" *ngFor="let equipe of equipes" (removedEquipe)="onRemoveEquipe($event)"></app-list-equipe-item>
</mat-list>

<div class="text-center">
  <button mat-mini-fab color="primary" (click)="triggerModal(createEquipeModal)">
      <mat-icon>add</mat-icon>
  </button>
</div>


<ng-template #createEquipeModal let-modal>
  <div class="modal-content">

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ 'creation_equipe' | translate }}</h4>
      <button type="button" class="close" aria-label="Fermer" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label>{{ 'nom_equipe' | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="currentEquipe.nom" name="nom_current_equipe">
        </div>

        <div class="form-group">
          <label>{{ 'type_equipe' | translate }}: </label>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'type_equipe' | translate }}</mat-label>
            <mat-select [(ngModel)]="currentEquipe.type" name="iri_type_equipe_current_equipe" class="dark">
              <mat-option *ngFor="let typeEquipe of allTypeEquipes" [value]="typeEquipe.id">
                {{ typeEquipe.nom }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">{{ 'fermer' | translate }}</button>
      <button type="button" mat-raised-button color="primary" (click)="addEquipe()">{{ 'valider' | translate }}</button>
    </div>
  </div>
</ng-template>
