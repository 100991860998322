import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TypeJoueurListComponent } from './type-joueur-list/type-joueur-list.component';
import { TypeJoueurCreateComponent } from './type-joueur-create/type-joueur-create.component';
import { TypeJoueurEditComponent } from './type-joueur-edit/type-joueur-edit.component';

const routes: Routes = [
  { path: 'list', component: TypeJoueurListComponent },
  { path: 'create', component: TypeJoueurCreateComponent },
  { path: 'edit/:id', component: TypeJoueurEditComponent },
  { path: '', redirectTo: 'list', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TypeJoueurRoutingModule { }
