import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Equipe } from '@models/equipe';
import { Router } from '@angular/router';
import { EquipeService } from '@services/equipe.service';

@Component({
  selector: 'app-list-equipe-item',
  templateUrl: './list-equipe-item.component.html',
  styleUrls: ['./list-equipe-item.component.scss']
})
export class ListEquipeItemComponent implements OnInit {
  @Input() equipe: Equipe;
  @Output() removedEquipe = new EventEmitter<number>();

  loadingRemoveEquipe: boolean = false;

  constructor(
    private router: Router,
    private equipeService: EquipeService
  ) { }

  ngOnInit(): void {
  }

  editEquipe(){
    this.router.navigate(['/equipe/' + this.equipe.id + '/edit'], { queryParams: { returnUrl: this.router.url}});
  }

  deleteEquipe(){
    this.loadingRemoveEquipe = true;
    this.equipeService.removeEquipe(this.equipe.id).subscribe(() => {
      this.removedEquipe.emit(this.equipe.id);
      this.loadingRemoveEquipe = false;
    });
  }
}
