<div *ngIf="user$ | async as user">
  <div class="division-data">
    <div class="division-left">
      <div class="user-profil">
        <div class="user-photo">
          <img [src]="itemImageUrl" alt="logo" (click)="fileInput.click()">
          <input class="form-control" type="file" id="logo_type_equipe" name="logo" (change)="onselecFile($event)" style="opacity: 0;" #fileInput formControlName="photo">
        </div>
      </div>
    </div>

    <div class="division-right">
        <form [formGroup] = "editForm" (ngSubmit)="onSave()">
                <div class="form-group">
                  <input class="form-control" type="text" [(ngModel)]="user.username" formControlName="username" readonly>
                </div>
                <div class="form-group">
                  <label for="inptrole">{{ 'roles' | translate }}</label>
                  <select class="form-control" id="inptrole" (change)="changeRole($event)" [(ngModel)]="role_number" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let option of options" [value]="option.id" [selected]="role_number">{{ option.display }}</option>
                  </select>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputfirstName">{{ 'prenoms' | translate }}</label>
                    <input type="text" class="form-control" id="inputfirstName" [(ngModel)]="user.firstname" formControlName="firstname" placeholder="Prénoms">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputlastName">{{ 'nom' | translate }}</label>
                    <input type="text" class="form-control" id="inputlastName" [(ngModel)]="user.lastname" formControlName="lastname" placeholder="Nom">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputemail">{{ 'email' | translate }}</label>
                    <input type="email" class="form-control" id="inputemail" [(ngModel)]="user.email" formControlName="email" placeholder="Email" >
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputTelephone">{{ 'telephone' | translate }}</label>
                    <input type="text" class="form-control" id="inputTelephone" [(ngModel)]="user.phone" formControlName="phone" placeholder="Téléphone">
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputOrganisation">{{ 'organisation' | translate }}</label>
                  <input type="text" class="form-control" id="inputOrganisation" [(ngModel)]="user.organisation" formControlName="organisation" placeholder="Organisation">
                </div>
                <div class="form-group">
                  <label for="Textarea1">{{ 'about_me' | translate }}</label>
                  <textarea spellcheck="false" class="form-control" id="Textarea1" rows="3" [(ngModel)]="user.description" formControlName="description"></textarea>
                </div>
                <button type="submit" mat-raised-button color="primary" [disabled]="!editForm.valid || isSubmitting">
                  <span class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></span>
                  {{ 'modifier' | translate }}
                </button>
        </form>
    </div>
  </div>
