import { Component, OnInit } from '@angular/core';
import { CustomDatasource } from '@datasources/custom-datasource';
import { TypeJoueur, TypeJoueurAdapter } from '@models/type-joueur';
import { TypeJoueurService } from '@services/type-joueur.service';
import { TypeJoueurLister } from '@services/listers/type-joueur-lister';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeEquipe } from '@models/type-equipe';
import { TypeEquipeService } from '@services/type-equipe.service';

@Component({
  selector: 'app-type-joueur-list',
  templateUrl: './type-joueur-list.component.html',
  styleUrls: ['./type-joueur-list.component.scss']
})
export class TypeJoueurListComponent implements OnInit {
  dataSource : CustomDatasource<TypeJoueur>;

  defaultPageSize = 10;
  displayedColumns = ['typeJoueur_nom', 'typeJoueur_action'];
  // displayedColumns = ['typeJoueur_id', 'typeJoueur_nom', 'typeJoueur_action'];

  typeEquipes: TypeEquipe[];

  constructor(
    private modalService: NgbModal,
    private typeJoueurService: TypeJoueurService,
    private typeJoueurLister: TypeJoueurLister,
    private typeJoueurAdapter: TypeJoueurAdapter
  ) { }

  ngOnInit(): void {
    this.dataSource = new CustomDatasource<TypeJoueur>(this.typeJoueurLister, this.typeJoueurAdapter);
    this.dataSource.load();
  }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  onDelete(id: number){
    this.typeJoueurService.delete(id).subscribe(
      () => this.dataSource.load()
    );
  }
}
