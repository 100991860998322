import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProduitService } from '@services/produit.service';
import { Jeu } from '@models/jeu';
import { JeuService } from '@services/jeu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ParameterService } from '@services/parameter.service';
import { Produit } from '@models/produit';

@Component({
  selector: 'app-page-edition-produits-cyber',
  templateUrl: './page-edition-produits-cyber.component.html',
  styleUrls: ['./page-edition-produits-cyber.component.scss']
})
export class PageEditionProduitsCyberComponent implements OnInit {
  idJeu: number;
  jeu: Jeu;

  coutProduitCyber = new FormControl();
  loadingSubmitCout: boolean = false;
  loadingProduitsCyber: boolean = false;
  allProduitsCyber: Produit[] = [];

  constructor(
    private produitService: ProduitService,
    private jeuService: JeuService,
    private parameterService: ParameterService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.getJeu();
    this.getParameters();
    this.getProduitsCyber();
  }

  /**
   * Initialise les parametres pour le formulaire (cout produit cybers)
   */
  getParameters(){
    this.parameterService.getParameterJeu(this.idJeu).subscribe(parameters => {
      console.log('cost-product-cyber-as-percent-yo', parameters['cost-product-cyber-as-percent-yo']);
      this.coutProduitCyber.setValue(parameters['cost-product-cyber-as-percent-yo']);
    });
  }

  getJeu(){
    this.jeuService.getOne(this.idJeu).subscribe(jeu => {
      this.jeu = jeu;
    });
  }
  submitCoutItForm(){
    this.loadingSubmitCout = true;
    this.produitService.setCoutProduitCyber(this.jeu.id, this.coutProduitCyber.value).subscribe(() => {
      this.loadingSubmitCout = false;
    });
  }

  getProduitsCyber(){
    this.loadingProduitsCyber = true;
    this.produitService.getAll({"type.slug":"investissement-securite"}).subscribe(produits => {
      this.allProduitsCyber = produits;
      this.loadingProduitsCyber = false;
    });
  }

  suivant(){
    this.router.navigate(['/jeux/designer/edit-annonces/' + this.jeu.id]);
  }
}
