<div>

    <mat-table class="lessons-table" matSort matSortActive="id" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource">

      <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
      <mat-cell *matCellDef="let user">{{user.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header="username">Email / {{ 'nom_utilisateur' | translate }}</mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let user"><img [src]="contentUrl(user.photo)" alt="logo" width="64px">{{user.username}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let user">
        <button mat-icon-button color="warning" [routerLink]="['/users/show', user.id]">
          <mat-icon>build</mat-icon>
        </button>
        <button mat-icon-button color="danger" (click)="triggerModal(modalData)">
          <mat-icon>close</mat-icon>
        </button>

        <mat-slide-toggle *ngIf="isAdmin()" (change)="toggleActive($event, user.active, user.id)" [checked]="user.active" [disabled]="loading && currentId == user.id">{{ 'actif' | translate }}</mat-slide-toggle>
        <span class="text-danger" *ngIf="errorForbidden && currentId == user.id">{{ 'interdiction_modification_ressource' | translate }}</span>
        <ng-template #modalData let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{ 'supprimer' | translate }} </h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p><strong>{{ 'confirmation_supprimer' | translate }} <span class="text-primary">"{{user.username}}"</span></strong></p>
          </div>
          <div class="modal-footer">
            <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">{{ 'fermer' | translate }}</button>
            <button type="button" mat-raised-button color="primary" (click)="onDelete(user.id); modal.close('Save click')">Confirmer la suppression</button>
          </div>
        </ng-template>

      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.total" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]"></mat-paginator>

</div>
