<div class="container">
  <h4>{{ 'initial_corporate_largest_reference_company' | translate }}</h4>
  <form [formGroup]="parameterForm">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'revenues_in_million_euro' | translate }}</label>
          <input type="number" class="form-control" formControlName="revenues"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'ebitda_margin_percent_revenues' | translate }}</label>
          <input type="number" class="form-control" formControlName="ebitda-margin-as-percent-sales"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'fixed_costs_percent_operating_costs' | translate }}</label>
          <input type="number" class="form-control" formControlName="percent-operating-costs"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'cashflow_outflow_from_interest' | translate }}</label>
          <input type="number" class="form-control" formControlName="cf-from-interest-taxes"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'legacy_capex_percent_revenues' | translate }}</label>
          <input type="number" class="form-control" formControlName="legacy-capex-as-percent-revenues"/>
        </div>
      </div>
    </div>
    <button type="submit" mat-raised-button color="primary" [disabled]="!parameterForm.valid || loadingSubmit" (click)="onSubmit()">
      <span class="spinner-border spinner-border-sm" *ngIf="loadingSubmit"></span>
      {{ 'valider' | translate }}
    </button>
  </form>

  <h4>{{ 'parametres_cyber' | translate }}</h4>
  <form [formGroup]="cyberParameterForm">
    <h4>Legacy</h4>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'it_costs_percent_revenues' | translate }}</label>
          <input type="number" class="form-control" formControlName="legacy-it-costs-as-percent-revenues"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'fixed_it_costs' | translate }}</label>
          <input type="number" class="form-control" formControlName="legacy-percent-it-costs-fixed"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'initial_cyber_expenses' | translate }}</label>
          <input type="number" class="form-control" formControlName="legacy-base-cyber-exp-as-percent-it-costs"/>
        </div>
      </div>
    </div>

    <h4>{{ 'new_operations' | translate }}</h4>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'it_costs_percent_revenues' | translate }}</label>
          <input type="number" class="form-control" formControlName="new-ops-it-costs-as-percent-total-costs"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'fixed_it_costs' | translate }}</label>
          <input type="number" class="form-control" formControlName="new-ops-percent-it-costs-fixed"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'initial_cyber_expenses' | translate }}</label>
          <input type="number" class="form-control" formControlName="new-ops-base-cyber-exp-as-percent-it-costs"/>
        </div>
      </div>
    </div>
    <button type="submit" mat-raised-button color="primary" [disabled]="!cyberParameterForm.valid || loadingSubmitCyber" (click)="onSubmitCyber()">
      <span class="spinner-border spinner-border-sm" *ngIf="loadingSubmitCyber"></span>
      {{ 'valider' | translate }}
    </button>
  </form>

  <h4>{{ 'online_revenues_legacy_percent_total_revenues' | translate }}</h4>
  <form [formGroup]="onlineLegacyForm">
    <div class="row" formArrayName="rounds">
      <div class="col-6" *ngFor="let round of rounds.controls; let i = index">
        <div class="form-group">
          <label>Round {{ i }}</label>
          <input type="number" class="form-control" [formControlName]="i"/>
        </div>
      </div>
    </div>
    <button type="submit" mat-raised-button color="primary" [disabled]="!cyberParameterForm.valid || loadingOnlineRevenuesLegacy" (click)="onSubmitOnlineLegacy()">
      <span class="spinner-border spinner-border-sm" *ngIf="loadingOnlineRevenuesLegacy"></span>
      {{ 'valider' | translate }}
    </button>
  </form>
</div>
