<div *ngIf="jeu">
  <h2>{{ 'liste_joueurs' | translate }}</h2>
  <button mat-raised-button color="primary" (click)="envoyerMail()" [disabled]="loadingSendMail">
    <span class="spinner-border spinner-border-sm" *ngIf="loadingSendMail"></span>
    {{ 'envoyer_mail' | translate }}
  </button>
  <mat-list *ngIf="allJoueurs">
      <div *ngFor="let joueur of allJoueurs">
        <!--<mat-list-item>
          <a [routerLink]="goTo(joueur.id)" > <span *ngIf="joueur.user">Joueur : {{ joueur.user.username }}</span>  -- Type de joueur : {{ joueur.type.nom }}</a>


        </mat-list-item>-->

        <app-joueur-list-item [joueur]="joueur" [canEdit]="!jeu.started && !jeu.finished" [allFreeUsers]="allFreeUsers" [assignedUsers]="assignedUsers" (userAssigned)="onUserAssigned($event)" (userFreed)="onUserFreed($event)"></app-joueur-list-item>
      </div>
  </mat-list>
</div>
