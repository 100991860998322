import { Component, OnInit } from '@angular/core';
import { Jeu } from '@models/jeu';
import { ActivatedRoute, Router } from '@angular/router';
import { JeuService } from '@services/jeu.service';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from '@models/user';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-edition-animation',
  templateUrl: './edition-animation.component.html',
  styleUrls: ['./edition-animation.component.scss']
})
export class EditionAnimationComponent implements OnInit {
  idJeu: number;
  jeu: Jeu;
  editionForm = new FormGroup({
    'dateDebut': new FormControl(''),
    'animateur': new FormControl('')
  });
  allAnimateurs: User[] = [];

  loadingValider = false;
  loadingSubmitAnimation = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jeuService: JeuService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.getJeu();
    this.getAnimateur();
  }

  getJeu(){
    this.jeuService.getOne(this.idJeu).subscribe(jeu => {
      this.jeu = jeu;
    });
  }

  getAnimateur(){
    this.userService.getAnimateur().subscribe(animateurs => {
      this.allAnimateurs = animateurs;
    })
  }

  submitEditionAnimation(){
    let data = this.editionForm.value;

    this.loadingSubmitAnimation = true;
    this.jeuService.setJeuForAnimation(this.jeu.id, data).subscribe(() => {
      this.loadingSubmitAnimation = false;
      this.router.navigate(['/animations']);
    });
  }
}
